import { EMPTY, of as observableOf } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'

import * as TApi from '../../types/TApi'
import { DeliveryInfoStatus, IProductCart } from '../../types/TClient'

import messages from '../../localization/messages'
import * as Actions from '../actions'
import { EpicFunc, guardMergeMap, ofType } from './epicHelpers'
import { URL_DELIVERY_INFO, URL_PAYMENT_OPTIONS, URL_PRODUCTS_OUT_OF_STOCK } from '../../modules/network/urls'
import { EModalType } from '../reducers/modals'
import { intlMessage } from '../../App/LangContainer'
import { authRequestConfig } from '../../utils/requestUtils'
import {
  checkNoSlots,
  convertPaymentFromApi,
  convertProductCartToOutOfStock,
  convertSlot,
  isAddressError,
  isPolygonError,
  isTodayToDate,
  showDeliveryError,
} from '../../utils/cartUtils'
import { historyPush, LOCATION_DELIVERY } from '../../utils/locationUtils'
import { getAddressString } from '../../utils/userUtils'

const paymentOptionsEpic: EpicFunc = (a$, store) =>
  guardMergeMap(ofType<Actions.ApiPaymentOptions>(a$, Actions.API_PAYMENT_OPTIONS), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_PAYMENT_OPTIONS, {
          ...authRequestConfig(),
          params: {
            market: store.value.market.market?.id,
          }
        }).pipe(
          mergeMap((resp: { data: TApi.ApiPaymentOptionsResp }) => {
            if (resp.data && Array.isArray(resp.data.records)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.PAYMENT_OPTIONS, convertPaymentFromApi(resp.data.records)),
              )
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const deliveryInfoEpic: EpicFunc = (a$, store) =>
  guardMergeMap(ofType<Actions.ApiDeliveryInfo>(a$, Actions.API_DELIVERY_INFO), (b) =>
    b.pipe(
      mergeMap((c) => {
        const cart = store.value.cart
        const market = store.value.market
        const address = store.value.customer.addresses.default
        const items = c.data.orderItems || cart.products?.map((p) => ({ productId: p.id, quantity: p.quantity })) || []
        const addressString = address
          ? c.data.address || getAddressString(address, address.city || market.city?.name || '')
          : c.data.address || ''
        const isToday = c.data.isToday !== undefined ? c.data.isToday : cart.isToday
        const addressId = c.data.address ? c.data.addressId : address?.id

        if (!items.length) {
          return EMPTY
        }

        return Axios.post(URL_DELIVERY_INFO, {
          items,
          date: isTodayToDate(isToday),
          market: market.market?.id || '',
          address: addressString,
          lat: c.data.address ? c.data.lat : address?.lat,
          lon: c.data.address ? c.data.lon : address?.lon,
          address_id: addressId,
        }, {
          ...authRequestConfig(),
          params: {
            order_id: c.data.orderId,
          },
        }).pipe(
          mergeMap((resp: AxiosResponse<TApi.IDeliveryInfoResp>) => {
            const actions: Actions.Action[] = [
              Actions.action(Actions.DELIVERY_INFO, {
                type: resp.data.type,
                slots: resp.data.slots.map(convertSlot),
                minOrderPrice: resp.data.min_order_price,
                fromComponent: c.data.fromComponent,
              })
            ]

            if (addressId) {
              actions.push(Actions.action(Actions.UPDATE_ADDRESS_COORDS, { id: addressId, ...resp.data.point }))
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            const status = (err.response.data as any as TApi.IDeliveryInfoErrorResp)?.status as DeliveryInfoStatus
            const isNoSlots = checkNoSlots(status)

            if (isToday && isNoSlots) {
              const actions: Actions.Action[] = [
                Actions.action(Actions.TOGGLE_IS_TODAY, false),
                Actions.action(Actions.CHANGE_IS_TODAY_PERMISSION, false),
                Actions.actionEmpty(Actions.DELIVERY_INFO_ERROR),
                Actions.action(Actions.API_DELIVERY_INFO, {
                  addressId,
                  isToday: false,
                  orderItems: c.data.orderItems,
                  address: addressString,
                  lat: c.data.address ? c.data.lat : address?.lat,
                  lon: c.data.address ? c.data.lon : address?.lon,
                }),
              ]

              return observableOf<Actions.Action>(...actions)
            }

            if (status) {
              const actions: Actions.Action[] = []

              if (showDeliveryError(status)) {
                actions.push(
                  Actions.action(Actions.MODAL_PUSH, {
                    type: EModalType.MODAL_INFO,
                    size: 'mini',
                    style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
                    props: {
                      title: intlMessage(messages.Error),
                      text: showDeliveryError(status),
                      btnText: intlMessage(messages.Understand),
                    },
                  }),
                )
              }

              if (isPolygonError(status)) {
                actions.push(Actions.action(Actions.CHECK_CURRENT_ADDRESS, status))
              }

              if (isAddressError(status)) {
                actions.push(
                  Actions.action(Actions.CHECK_CURRENT_ADDRESS, status),
                  Actions.action(Actions.MODAL_PUSH, {
                    type: EModalType.MODAL_INFO,
                    size: 'mini',
                    style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
                    props: {
                      title: intlMessage(messages.NotDeliverHere),
                      text: intlMessage(messages.SorryNotDeliverAddress),
                      btnText: intlMessage(messages.ItsPity),
                    },
                  })
                )
              }

              actions.push(Actions.actionEmpty(Actions.DELIVERY_COST_ERROR))

              return observableOf<Actions.Action>(...actions)
            }

            return EMPTY
          }),
        )
      }),
    )
  )

const productsOutOfStockEpic: EpicFunc = (a$, store) =>
  guardMergeMap(ofType<Actions.ApiProductsOutOfStock>(a$, Actions.API_PRODUCTS_OUT_OF_STOCK), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCTS_OUT_OF_STOCK, {
          products: c.data.products,
        }, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ApiProductOutOfStockResp[] }) => {
            const products: IProductCart[] = store.value.cart.products || []

            if (resp.data && Array.isArray(resp.data) && resp.data.length > 0) {
              const productOutOfStock = products.filter(
                (product) => resp.data.find((item) => product.id === item.id))

              const actions: Actions.Action[] = [
                Actions.action(Actions.PRODUCTS_OUT_OF_STOCK, productOutOfStock.map(convertProductCartToOutOfStock)),
                Actions.action(Actions.MODAL_PUSH, {
                  type: EModalType.MODAL_OUT_OF_STOCK,
                  size: 'mini',
                  style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
                  props: {},
                })
              ]

              return observableOf<Actions.Action>(...actions)
            }

            if (products && products.length > 0) {
              historyPush(LOCATION_DELIVERY)
            }

            return observableOf<Actions.Action>(
              Actions.action(Actions.PRODUCTS_OUT_OF_STOCK, []),
            )
          }),
          catchError(() => {
            return observableOf<Actions.Action>(
              Actions.action(Actions.PRODUCTS_OUT_OF_STOCK, []),
            )
          }),
        ),
      ),
    ),
  )

export const cartEpics: EpicFunc[] = [
  paymentOptionsEpic,
  productsOutOfStockEpic,
  deliveryInfoEpic,
]
