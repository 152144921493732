import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const InfoIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M2.4 12.037a9.531 9.531 0 0 1 9.536-9.536 9.531 9.531 0 0 1 9.535 9.536 9.53 9.53 0 0 1-9.535 9.536A9.531 9.531 0 0 1 2.4 12.037Zm9.536-7.836A7.831 7.831 0 0 0 4.1 12.037a7.831 7.831 0 0 0 7.836 7.836 7.831 7.831 0 0 0 7.835-7.836 7.831 7.831 0 0 0-7.835-7.836Zm-.002 12.66a.965.965 0 0 1-.965-.965v-3.86a.965.965 0 1 1 1.93 0v3.86a.965.965 0 0 1-.965.965Zm.965-8.685a.965.965 0 1 0-1.93 0 .965.965 0 0 0 1.93 0Z'/>
    </svg>
  )
}
