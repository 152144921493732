import { EMPTY, of as observableOf } from 'rxjs'
import { catchError, debounceTime, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'
import * as TClient from '../../types/TClient'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, guardMergeMap, ofType } from './epicHelpers'
import {
  URL_MARKET_CATEGORIES,
  URL_PRODUCTS_LIST,
  URL_PRODUCTS_RECOMMENDATION,
  URL_TAGS,
} from '../../modules/network/urls'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { convertProductFromApi, convertMarketCategoriesFromApi } from '../../utils/productsUtils'
import { convertTagFromApi } from '../../utils/tagsUtils'

const marketCategoriesEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiMarketCategories>(a$, Actions.API_MARKET_CATEGORIES), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_MARKET_CATEGORIES, {
          ...authRequestConfig(),
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketCategoriesResp }) => {
            if (resp.data && Array.isArray(resp.data)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKET_CATEGORIES, {
                  categories: resp.data.map(convertMarketCategoriesFromApi),
                }),
              )
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const getProductsEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiMarketProducts>(a$, Actions.API_MARKET_PRODUCTS), (b) =>
    b.pipe(
      debounceTime(200),
      mergeMap((c) =>
        Axios.get(URL_PRODUCTS_LIST, {
          ...authRequestConfig(),
          params: { ...c.data },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketProductsResp }) => {
            if (resp.data && Array.isArray(resp.data.records)) {
              return observableOf<Actions.Action>(
                Actions.action(Actions.MARKET_PRODUCTS, {
                  products: resp.data.records.map(convertProductFromApi),
                  force: !c.data.offset,
                  search: c.data.fromSearch,
                  searchStr: c.data.search,
                  discount: c.data.discount,
                }),
              )
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const getTagsEpic: EpicFunc = (a$, store) =>
  guardMergeMap(ofType<Actions.ApiTags>(a$, Actions.API_TAGS), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_TAGS, {
          ...authRequestConfig(),
          params: {
            market_id: store.value.market.market?.id,
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ITagApi[] }) => {
            if (resp.data && Array.isArray(resp.data)) {
              return observableOf<Actions.Action>(Actions.action(Actions.TAGS, resp.data.map(convertTagFromApi)))
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const getProductsRecommendationEpic: EpicFunc = (a$, _store) =>
  guardMergeMap(ofType<Actions.ApiProductsRecommendation>(a$, Actions.API_PRODUCTS_RECOMMENDATION), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_PRODUCTS_RECOMMENDATION, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.TProductRecommendationResp }) => {
            if (resp.data && Array.isArray(resp.data)) {
              const products: TClient.IProductGroup[] = []

              for (const group of resp.data) {
                for (const product of group.prod_rel) {
                  if (!product.root) {
                    products.push({
                      id: product.id,
                      groupId: product.group,
                      priority: product.priority,
                      key: +group.key as TClient.EGroupType,
                      product: convertProductFromApi(product.product),
                    })
                  }
                }
              }

              return observableOf<Actions.Action>(Actions.action(Actions.PRODUCTS_RECOMMENDATION, products))
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

export const productsEpics: EpicFunc[] = [
  marketCategoriesEpic,
  getProductsEpic,
  getTagsEpic,
  getProductsRecommendationEpic,
]
