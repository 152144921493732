import moment from 'moment-timezone'

import { IOrderApi, IProductOrderApi, ITransactionApi } from '../types/TApi'
import {
  EOrderStatus,
  EPaymentStatus,
  EPaymentType,
  ICurrency,
  IOrder,
  IProductOrder,
  ITransaction,
} from '../types/TClient'

import { ORDER_COLORS } from '../style/colors'
import { convertCurrency, formatPrice, round } from './productsUtils'
import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'

export const ORDERS_LIMIT = 10
export const INTERVAL_SIZE_MINUTE = 90
export const HOUR_MINUTE = 60

export const NONE = 'None'

export const convertOrderFromApi = (order: IOrderApi): IOrder => {
  return {
    id: order.id,
    price: order.price,
    initialPrice: order.price_initial,
    paymentType: order.payment_type,
    paid: order.is_payed,
    createdAt: moment(order.created_at).toDate(),
    items: order.items.map(convertProductOrderFromApi),
    selfPickup: order.self_pickup,
    orderConfirmed: order.order_confirmed,
    statusId: order.status_id,
    statusName: order.status_name,
    weightKg: order.weight_kg,
    weightGr: order.weight_gr,
    ...(order.review && { review: order.review }),
    ...(order.message && { message: order.message }),
    ...(order.market_address && { marketAddress: order.market_address }),
    ...(order.market_open_hours_start && { marketOpeningHours: order.market_open_hours_start }),
    ...(order.market_open_hours_end && { marketClosingHours: order.market_open_hours_end }),
    ...(order.updated_at && { updatedAt: moment(order.updated_at).toDate() }),
    ...(order.completed_at && order.completed_at !== NONE && { completedAt: moment(order.completed_at).toDate() }),
    ...(order.delivery_price && { deliveryPrice: order.delivery_price }),
    ...(order.delivery_address && order.delivery_address !== NONE && { deliveryAddress: order.delivery_address }),
    ...(order.delivery_date && order.delivery_date !== NONE && { deliveryDate: order.delivery_date }),
    ...(order.delivery_time && order.delivery_time !== NONE && { deliveryTime: order.delivery_time }),
    ...(order.delivery_end_time && order.delivery_end_time !== NONE && { deliveryEndTime: order.delivery_end_time }),
    ...(order.transaction && { transaction: convertTransactionFromApi(order.transaction) }),
    ...(order.is_ext_paid !== undefined && { extPaid: order.is_ext_paid }),
    ...(order.ext_transaction && { extTransaction: convertTransactionFromApi(order.ext_transaction) }),
  }
}

export const convertProductOrderFromApi = (product: IProductOrderApi): IProductOrder => {
  return {
    id: product.id,
    productId: product.product_id,
    name: product.name,
    country: product.country,
    market: product.market,
    image: product.image_url,
    price: product.price,
    priceWeightGr: product.price_weight_gr,
    currency: convertCurrency(product.currency_code),
    pcsWeightGr: product.pcs_weight_gr,
    quantity: product.quantity,
    initialQuantity: product.quantity_initial,
    sellerName: product.seller_name,
    subcategory: product.subcategory,
    unit: product.unit,
    added: product.added,
    priceWithoutDiscount: 0,
  }
}

export const convertTransactionFromApi = (transaction: ITransactionApi): ITransaction => {
  return {
    id: transaction.id,
    amount: +transaction.Amount,
    details: transaction.Details,
    errorCode: transaction.ErrorCode,
    message: transaction.Message,
    orderId: transaction.OrderId,
    paymentId: transaction.PaymentId,
    paymentURL: transaction.PaymentURL,
    status: transaction.Status as EPaymentStatus,
    success: transaction.Success,
    terminalKey: transaction.TerminalKey,
    ...(!!transaction.CardId && { cardId: transaction.CardId }),
  }
}

export const sortOrders = (orders: IOrder[]) => {
  const sortedOrders = [...orders]

  return sortedOrders.sort((o1, o2) => o2.createdAt.valueOf() - o1.createdAt.valueOf())
}

export const filterOrders = (o: IOrder, status?: number | number[]): boolean => {
  return Array.isArray(status) ? !status.some((el) => o.statusId === el) : o.statusId !== status
}

export const getColorByStatus = (order: IOrder): string => {
  if (order.transaction && order.paymentType === EPaymentType.BY_CARD) {
    if (order.extTransaction && order.extPaid === false && order.statusId !== EOrderStatus.CANCELED) {
      return ORDER_COLORS.NOT_PAID
    }

    if (!order.paid && order.statusId !== EOrderStatus.CANCELED) {
      return ORDER_COLORS.NOT_PAID
    }
  }

  switch (order.statusId) {
    case EOrderStatus.NEW:
      return ORDER_COLORS.NEW
    case EOrderStatus.IN_ASSEMBLY:
      return ORDER_COLORS.IN_ASSEMBLY
    case EOrderStatus.COURIER:
      return ORDER_COLORS.COURIER
    case EOrderStatus.DELIVERED:
      return ORDER_COLORS.DELIVERED
    case EOrderStatus.CANCELED:
      return ORDER_COLORS.CANCELED
    default:
      return ORDER_COLORS.NEW
  }
}

export const getNameByStatus = (order: IOrder): string => {
  if (order.transaction && order.paymentType === EPaymentType.BY_CARD) {
    if (order.extTransaction && order.extPaid === false && order.statusId !== EOrderStatus.CANCELED) {
      return intlMessage(messages.NeedPayExtra)
    }

    if (
      (order.paid || order.transaction.status === EPaymentStatus.CONFIRMED) &&
      order.statusId === EOrderStatus.CANCELED
    ) {
      return intlMessage(messages.MoneyWillReturned)
    }

    if (!order.paid && order.statusId !== EOrderStatus.CANCELED) {
      return intlMessage(messages.WaitingPayment)
    }
  }

  switch (order.statusId) {
    case EOrderStatus.NEW:
      return intlMessage(messages.AcceptedWork)
    case EOrderStatus.IN_ASSEMBLY:
      return intlMessage(messages.Collecting)
    case EOrderStatus.COURIER:
      return intlMessage(messages.OnCourier)
    case EOrderStatus.DELIVERED:
      return intlMessage(messages.Delivered)
    case EOrderStatus.CANCELED:
      return intlMessage(messages.Canceled)
  }

  return ''
}

export const getDeliveryTime = (timeFirst: string): string => {
  const [startDeliveryHour, startDeliveryMinute] = timeFirst.split(':').slice(0, 2)
  const endDeliveryTime = +startDeliveryHour * HOUR_MINUTE + +startDeliveryMinute + INTERVAL_SIZE_MINUTE
  const endDeliveryHour = Math.floor(endDeliveryTime / HOUR_MINUTE)
  const endDeliveryMinute = endDeliveryTime % HOUR_MINUTE

  return ` ${intlMessage(messages.With).toLowerCase()} ${startDeliveryHour}:${startDeliveryMinute}` +
    ` ${intlMessage(messages.Before).toLowerCase()} ${endDeliveryHour}:${endDeliveryMinute}`
}

export const getTotalPrice = (order: IOrder, currency?: ICurrency) =>
  formatPrice(round(order.price + (order.deliveryPrice || 0)), currency)
