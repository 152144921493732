import React from 'react'
import classnames from 'classnames'
import { Loader } from 'semantic-ui-react'

import './index.scss'

type TProps = {
  onClick(): void,
  loading?: boolean,
  disabled?: boolean,
  title?: string,
  titleCmp?: React.ReactNode,
  outline?: boolean,
  warning?: boolean,
  color?: string,
  textColor?: string,
  customClass?: string,
}

export const Button: React.FC<TProps> = ({ customClass, onClick, loading, title, titleCmp, warning, outline, disabled, color, textColor }) => {
  return (
    <div
      className={classnames(
        'button-cmp',
        warning && 'button-cmp-warning',
        outline && 'button-cmp-outline',
        disabled && 'button-cmp-disabled',
        customClass,
      )}
      style={{ backgroundColor: color, color: textColor }}
      onClick={disabled || loading ? undefined : (e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {
        loading ? (
          <Loader className='button-cmp__loading' inline active />
        ) : (
          <>
            {title && title.toUpperCase()}
            {titleCmp}
          </>
        )
      }
    </div>
  )
}
