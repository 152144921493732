export enum COUNTRY_ID {
  AFGHANISTAN = 'AFG',
  ALAND_ISLANDS = 'ALA',
  ALBANIA = 'ALB',
  ALGERIA = 'DZA',
  AMERICAN_SAMOA = 'ASM',
  ANDORRA = 'AND',
  ANGOLA = 'AGO',
  ANGUILLA = 'AIA',
  ANTARCTICA = 'ATA',
  BENIN = 'BEN',
  CURACAO = 'CUW',
  SOUTH_SUDAN = 'SSD',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS = 'SGS',
  SAINT_MAARTEN = 'SXM',
  BONAIRE = 'BES',
  ANTIGUA_AND_BARBUDA = 'ATG',
  ARGENTINA = 'ARG',
  ARMENIA = 'ARM',
  ARUBA = 'ABW',
  AUSTRALIA = 'AUS',
  AUSTRIA = 'AUT',
  AZERBAIJAN = 'AZE',
  BAHAMAS = 'BHS',
  BAHRAIN = 'BHR',
  BANGLADESH = 'BGD',
  BARBADOS = 'BRB',
  BELARUS = 'BLR',
  BELGIUM = 'BEL',
  BELIZE = 'BLZ',
  BERMUDA = 'BMU',
  BHUTAN = 'BTN',
  BOLIVIA = 'BOL',
  BOSNIA_AND_HERZEGOVINA = 'BIH',
  BOTSWANA = 'BWA',
  BOUVET_ISLAND = 'BVT',
  BRAZIL = 'BRA',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'IOT',
  BRUNEI_DARUSSALAM = 'BRN',
  BULGARIA = 'BGR',
  BURKINA_FASO = 'BFA',
  BURUNDI = 'BDI',
  CAMBODIA = 'KHM',
  CAMEROON = 'CMR',
  CANADA = 'CAN',
  CAPE_VERDE = 'CPV',
  CAYMAN_ISLANDS = 'CYM',
  CENTRAL_AFRICAN_REPUBLIC = 'CAF',
  CHAD = 'TCD',
  CHILE = 'CHL',
  CHINA = 'CHN',
  CHRISTMAS_ISLAND = 'CXR',
  COCOS_KEEL_ING_ISLANDS = 'CCK',
  COLOMBIA = 'COL',
  COMOROS = 'COM',
  CONGO = 'COD',
  CONGO_DEMOCRATIC_REPUBLIC = 'COG',
  COOK_ISLANDS = 'COK',
  COSTA_RICA = 'CRI',
  COTE_DIVOIRE = 'CIV',
  CROATIA = 'HRV',
  CUBA = 'CUB',
  CYPRUS = 'CYP',
  CZECH_REPUBLIC = 'CZE',
  DENMARK = 'DNK',
  DJIBOUTI = 'DJI',
  DOMINICA = 'DMA',
  DOMINICAN_REPUBLIC = 'DOM',
  ECUADOR = 'ECU',
  EGYPT = 'EGY',
  EL_SALVADOR = 'SLV',
  EQUATORIAL_GUINEA = 'GNQ',
  ERITREA = 'ERI',
  ESTONIA = 'EST',
  ETHIOPIA = 'ETH',
  FALKLAND_ISLANDS = 'FLK',
  FAROE_ISLANDS = 'FRO',
  FIJI = 'FJI',
  FINLAND = 'FIN',
  FRANCE = 'FRA',
  FRENCH_GUIANA = 'GUF',
  FRENCH_POLYNESIA = 'PYF',
  FRENCH_SOUTHERN_TERRITORIES = 'ATF',
  GABON = 'GAB',
  GAMBIA = 'GMB',
  GEORGIA = 'GEO',
  GERMANY = 'DEU',
  GHANA = 'GHA',
  GIBRALTAR = 'GIB',
  GREECE = 'GRC',
  GREENLAND = 'GRL',
  GRENADA = 'GRD',
  GUADELOUPE = 'GLP',
  GUAM = 'GUM',
  GUATEMALA = 'GTM',
  GUERNSEY = 'GGY',
  GUINEA = 'GIN',
  GUINEA_BISSAU = 'GNB',
  GUYANA = 'GUY',
  HAITI = 'HTI',
  HEARD_ISLAND_MCDONALD_ISLANDS = 'HMD',
  HOLY_SEE_VATICAN_CITY_STATE = 'VAT',
  HONDURAS = 'HND',
  HONG_KONG = 'HKG',
  HUNGARY = 'HUN',
  ICELAND = 'ISL',
  INDIA = 'IND',
  INDONESIA = 'IDN',
  IRAN = 'IRN',
  IRAQ = 'IRQ',
  IRELAND = 'IRL',
  ISLEOFMAN = 'IMN',
  ISRAEL = 'IRS',
  ITALY = 'ITA',
  JAMAICA = 'JAM',
  JAPAN = 'JPN',
  JERSEY = 'JEY',
  JORDAN = 'JOR',
  KAZAKHSTAN = 'KAZ',
  KENYA = 'KEN',
  KIRIBATI = 'KIR',
  REPUBLIC_OF_KOREA = 'KOR',
  DEMOCRATIC_PEOPLES_REPUBLIC_OF_KOREA = 'PRK',
  KUWAIT = 'KWT',
  KYRGYZSTAN = 'KGZ',
  LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'LAO',
  LATVIA = 'LVA',
  LEBANON = 'LBN',
  LESOTHO = 'LSO',
  LIBERIA = 'LBR',
  LIBYAN_ARAB_JAMAHIRIYA = 'LBY',
  LIECHTENSTEIN = 'LIE',
  LITHUANIA = 'LTU',
  LUXEMBOURG = 'LUX',
  MACAO = 'MAC',
  MACEDONIA = 'MKD',
  MADAGASCAR = 'MDG',
  MALAWI = 'MWI',
  MALAYSIA = 'MYS',
  MALDIVES = 'MDV',
  MALI = 'MLI',
  MALTA = 'MLT',
  MARSHALL_ISLANDS = 'MHL',
  MARTINIQUE = 'MTQ',
  MAURITANIA = 'MRT',
  MAURITIUS = 'MUS',
  MAYOTTE = 'MYT',
  MEXICO = 'MEX',
  MICRONESIA = 'FSM',
  MOLDOVA = 'MDA',
  MONACO = 'MCO',
  MONGOLIA = 'MNG',
  MONTENEGRO = 'MNE',
  MONTSERRAT = 'MSR',
  MOROCCO = 'MAR',
  MOZAMBIQUE = 'MOZ',
  MYANMAR = 'MMR',
  NAMIBIA = 'NAM',
  NAURU = 'NRU',
  NEPAL = 'NPL',
  NETHERLANDS = 'NLD',
  NETHERLANDS_ANTILLES = 'ANT',
  NEW_CALEDONIA = 'NCL',
  NEW_ZEALAND = 'NZL',
  NICARAGUA = 'NIC',
  NIGER = 'NER',
  NIGERIA = 'NGA',
  NIUE = 'NIU',
  NORFOLK_ISLAND = 'NFK',
  NORTHERN_MARIANA_ISLANDS = 'MNP',
  NORWAY = 'NOR',
  OMAN = 'OMN',
  PAKISTAN = 'PAK',
  PALAU = 'PLW',
  PALESTINIAN_TERRITORY = 'PSE',
  PANAMA = 'PAN',
  PAPUA_NEW_GUINEA = 'PNG',
  PARAGUAY = 'PRY',
  PERU = 'PER',
  PHILIPPINES = 'PHL',
  PITCAIRN = 'PCN',
  POLAND = 'POL',
  PORTUGAL = 'PRT',
  PUERTO_RICO = 'PRI',
  QATAR = 'QAT',
  REUNION = 'REU',
  ROMANIA = 'ROU',
  RUSSIA = 'RUS',
  RWANDA = 'RWA',
  SAINT_BARTHELEMY = 'BLM',
  SAINTHELENA = 'SHN',
  SAINT_KITTS_AND_NEVIS = 'KNA',
  SAINT_LUCIA = 'LCA',
  SAINT_MARTIN = 'MAF',
  SAINT_PIERRE_AND_MIQUELON = 'SPM',
  SAINT_VINCENT_AND_GRENADINES = 'VCT',
  SAMOA = 'WSM',
  SAN_MARINO = 'SMR',
  SAO_TOME_AND_PRINCIPE = 'STP',
  SAUDI_ARABIA = 'SAU',
  SENEGAL = 'SEN',
  SERBIA = 'SRB',
  SEYCHELLES = 'SYC',
  SIERRA_LEONE = 'SLE',
  SINGAPORE = 'SGP',
  SLOVAKIA = 'SVK',
  SLOVENIA = 'SVN',
  SOLOMON_ISLANDS = 'SLB',
  SOMALIA = 'SOM',
  SOUTH_AFRICA = 'ZAF',
  SOUTH_GEORGIA_AND_SANDWICH_ISL = 'GGS',
  SPAIN = 'ESP',
  SRI_LANKA = 'LKA',
  SUDAN = 'SDN',
  SURINAME = 'SUR',
  SVALBARD_AND_JAN_MAYEN = 'SJM',
  SWAZILAND = 'SWZ',
  SWEDEN = 'SWE',
  SWITZERLAND = 'CHE',
  SYRIAN_ARAB_REPUBLIC = 'SYR',
  TAIWAN = 'TWN',
  TAJIKISTAN = 'TJK',
  TANZANIA = 'TZA',
  THAILAND = 'THA',
  TIMOR_LESTE = 'TLS',
  TOGO = 'TGO',
  TOKELAU = 'TKL',
  TONGA = 'TON',
  TRINIDAD_AND_TOBAGO = 'TTO',
  TUNISIA = 'TUN',
  TURKEY = 'TUR',
  TURKMENISTAN = 'TKM',
  TURKS_AND_CAICOS_ISLANDS = 'TCA',
  TUVALU = 'TUV',
  UGANDA = 'UGA',
  UKRAINE = 'UKR',
  UNITED_ARAB_EMIRATES = 'ARE',
  UNITED_KINGDOM = 'GBR',
  UNITED_STATES = 'USA',
  UNITED_STATES_OUTLYING_ISLANDS = 'UMI',
  URUGUAY = 'URY',
  UZBEKISTAN = 'UZB',
  VANUATU = 'VUT',
  VENEZUELA = 'VEN',
  VIETNAM = 'VNM',
  VIRGIN_ISLANDS_BRITISH = 'VGB',
  VIRGIN_ISLANDS_US = 'VIR',
  WALLIS_AND_FUTUNA = 'WLF',
  WESTERN_SAHARA = 'ESH',
  YEMEN = 'YEM',
  ZAMBIA = 'ZMB',
  ZIMBABW = 'ZWE',
}