import { IMarketApi } from '../types/TApi'
import { IMarket } from '../types/TClient'

import { convertCurrency } from './productsUtils'

export const convertMarketFromApi = (market: IMarketApi): IMarket => {
  return {
    id: market.id,
    name: market.name,
    city: market.city,
    city_id: market.city_id,
    address: market.address,
    self_pickup_start_time: market.self_pickup_start_time,
    self_pickup_end_time: market.self_pickup_end_time,
    open_hours_start: market.open_hours_start,
    open_hours_end: market.open_hours_end,
    delivery_end_time: market.delivery_end_time,
    delivery_start_time: market.delivery_start_time,
    short_name: market.short_name,
    delivery_info: market.delivery_info,
    image_url: market.image_url,
    price_segment: market.price_segment,
    currency: convertCurrency(market.currency_code),
  }
}
