import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const OKIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='-7.5 -3.3 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M5.09 0a4.466 4.466 0 1 0 0 8.931A4.466 4.466 0 0 0 5.09 0Zm0 6.312a1.846 1.846 0 1 1 0-3.692 1.846 1.846 0 0 1 0 3.692ZM6.67 12.485c1.787-.364 2.858-1.21 2.915-1.256a1.214 1.214 0 0 0-1.52-1.895c-.01.01-1.153.886-3.033.887-1.88-.001-3.047-.878-3.058-.887a1.214 1.214 0 0 0-1.52 1.895c.058.046 1.174.915 3.012 1.27L.904 15.176a1.214 1.214 0 1 0 1.748 1.686l2.38-2.535 2.62 2.551a1.214 1.214 0 1 0 1.716-1.718l-2.699-2.675Z'/>
    </svg>
  )
}
