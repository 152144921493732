import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const UniverseIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' d='M11 19.25a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5ZM3.3 8.25h15.4M3.3 13.75h15.4'/>
      <path stroke={color} strokeWidth='1.6' strokeLinecap='round' strokeLinejoin='round' d='M10.541 2.75a15.583 15.583 0 0 0 0 16.5M11.459 2.75a15.583 15.583 0 0 1 0 16.5'/>
    </svg>
  )
}
