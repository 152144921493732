import moment from 'moment'

import { IPaymentOptionApi } from '../types/TApi'
import {
  DeliveryInfoStatus,
  EDeliveryInterval,
  IPaymentOption,
  IProductCart,
  IProductOutOfStock,
  ISlot,
  ISlotType,
  ITimeInterval,
} from '../types/TClient'

import messages from '../localization/messages'
import { intlMessage } from '../App/LangContainer'
import { round } from './productsUtils'

export const convertPaymentFromApi = (data: IPaymentOptionApi[]): IPaymentOption[] => {
  return data.map((paymentOption: IPaymentOptionApi) => ({
    id: paymentOption.payment_id,
    name: paymentOption.payment_name,
  }))
}

export const getTotalAmount = (products: IProductCart[], deliveryCost?: number): number => {
  return round(products.reduce((sum, product) => sum + product.price * product.quantity, 0) + (deliveryCost || 0))
}

export const getTotalWithoutDiscount = (products: IProductCart[], deliveryCost?: number): number => {
  return round(products.reduce((sum, product) => {
    if (product.priceWithoutDiscount < product.price) {
      return sum + product.price * product.quantity
    }

    return sum + product.priceWithoutDiscount * product.quantity
  }, 0) + (deliveryCost || 0))
}

export const convertProductCartToOutOfStock = (product: IProductCart): IProductOutOfStock => ({
  id: product.id,
  name: product.name,
  country: product.country,
  market: product.marketId,
  image: product.image,
  price: product.price,
  quantity: product.quantity,
  pcsWeightGr: product.pcsWeightGr,
  priceWeightGr: product.priceWeightGr,
  sellerName: product.sellerName,
  subcategory: product.subcategoryId,
  marketCategoryId: product.marketCategoryId,
  marketSubcategoryId: product.marketSubcategoryId,
  marketSubcategoryName: product.marketSubcategoryName,
  priceWithoutDiscount: product.priceWithoutDiscount,
  unit: product.unit,
})

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const checkContainTime = (time: string, start: string, end: string) => {
  const timeStart = moment(start, TIME_FORMAT)
  const timeEnd = moment(end, TIME_FORMAT)

  return timeStart.isBefore(time, 'seconds') && timeEnd.isAfter(time, 'seconds')
}

export const getSelfPickupTime = (interval: ITimeInterval, timeOffset?: string, createdAt?: Date) => {
  const afterTime = moment(`${moment(createdAt).format('HH:mm:ss')} ${timeOffset}`, 'HH:mm:ss Z').add(1, 'hours')

  afterTime.minutes(0)

  if (afterTime.diff(moment(createdAt), 'hours', true) > 0.5) {
    afterTime.minutes(30)
  } else {
    afterTime.add(1, 'hours').minutes(0)
  }

  afterTime.add(1, 'hours')

  if (
    !checkContainTime(
      afterTime.format(TIME_FORMAT),
      moment(interval.startTime, 'HH:mm:ss').format(TIME_FORMAT),
      moment(interval.endTime, 'HH:mm:ss').format(TIME_FORMAT),
    ) ||
    afterTime.hours() < moment(createdAt).hours()
  ) {
    const [marketSelfPickupStartHour, marketSelfPickupStartMinute] = interval.startTime.slice(0, -3).split(':')
    const nextDay = moment().isAfter(moment(interval.startTime, 'HH:mm:ss'))

    afterTime
      .add(1, 'days')
      .hours(+marketSelfPickupStartHour)
      .minutes(+marketSelfPickupStartMinute)
    return [
      `${afterTime.hours()}:${afterTime.minutes() > 9 ? afterTime.minutes() : `0${afterTime.minutes()}`}`,
      nextDay,
    ]
  } else {
    afterTime.subtract(1, 'hours')
  }

  return [`${afterTime.hours()}:${afterTime.minutes() > 9 ? afterTime.minutes() : `0${afterTime.minutes()}`}`, false]
}

export const timeToSlotId = (start?: string, end?: string) => `${start}-${end}`

export const convertSlot = ({ time_start, time_end, id }: ISlot): ISlot => ({
  id,
  time_start: time_start.slice(0, 5),
  time_end: time_end.slice(0, 5),
})

export const isTodayToDate = (isToday: boolean) => (isToday ? moment() : moment().add(1, 'day')).format('YYYY-MM-DD')

export const getSlotType = (slotTypes: ISlotType[], type?: EDeliveryInterval) => {
  if (type === undefined) {
    return slotTypes.find(
      (s) =>
        s.delivery_interval === EDeliveryInterval.NEAREST_SLOT ||
        s.delivery_interval === EDeliveryInterval.THROUGH_ONE_SLOT ||
        s.delivery_interval === EDeliveryInterval.THROUGH_TWO_SLOTS,
    )
  }

  return slotTypes.find((s) => s.delivery_interval === type)
}

export const slotToString = (slot?: ISlot) => (slot ? `${slot.time_start.slice(0, 5)} — ${slot.time_end.slice(0, 5)}` : '')

export const getDefaultDeliveryInterval = (slotTypes: ISlotType[]) => {
  return slotTypes.find(
    (s) =>
      s.delivery_interval === EDeliveryInterval.DURING_THE_DAY ||
      s.delivery_interval === EDeliveryInterval.AS_SOON_AS_POSSIBLE,
  )
}

export const checkOpenMarket = (): { today: boolean, tomorrow: boolean } => {
  const time = moment().format(TIME_FORMAT)

  if (
    moment('2021-12-31', TIME_FORMAT).isSame(time, 'day') &&
    !checkContainTime(time, '2021-12-31 13:00', '2022-01-03 8:00')
  ) {
    return { today: true, tomorrow: false }
  }

  if (checkContainTime(time, '2021-12-31 13:00', '2022-01-03 8:00')) {
    return { today: false, tomorrow: false }
  }

  if (checkContainTime(time, '2022-01-03 8:00', '2022-01-04 8:00')) {
    return { today: false, tomorrow: true }
  }

  return { today: true, tomorrow: true }
}

export const isAddressError = (status: DeliveryInfoStatus) =>
  status !== DeliveryInfoStatus.NOT_ACTIVE_TODAY &&
  status !== DeliveryInfoStatus.CONSTANTLY_NON_ACTIVE &&
  status !== DeliveryInfoStatus.MARKET_AND_POLYGON_CLOSED

export const isPolygonError = (status?: DeliveryInfoStatus) =>
  status === DeliveryInfoStatus.NOT_ACTIVE_TODAY ||
  status === DeliveryInfoStatus.CONSTANTLY_NON_ACTIVE ||
  status === DeliveryInfoStatus.MARKET_AND_POLYGON_CLOSED

export const checkNoSlots = (status?: DeliveryInfoStatus) =>
  status === DeliveryInfoStatus.MARKET_CLOSED ||
  status === DeliveryInfoStatus.NO_SLOTS ||
  status === DeliveryInfoStatus.NOT_ACTIVE_TODAY

export const showDeliveryError = (status: DeliveryInfoStatus) => {
  switch (status) {
    case DeliveryInfoStatus.MARKET_NOT_MATCH:
      return intlMessage(messages.EnteredCityNotMatchCityMarket)
    case DeliveryInfoStatus.CALCULATE_PRICE:
      return intlMessage(messages.SorryUnableCalcDeliveryCost)
    case DeliveryInfoStatus.MIN_DELIVERY_PRICE:
      return intlMessage(messages.DeliveryCostLessMin)
  }
}
