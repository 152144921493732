import { Reducer } from 'redux'

import { ICity, IMarket } from '../../types/TClient'

import * as Actions from '../actions'

export type MutableStateMarket = {
  loading: boolean,
  cityIsSelected: boolean,
  selectCityModal: boolean,
  city?: ICity,
  market?: IMarket,
  cities?: ICity[],
  markets?: IMarket[],
}

export type StateMarket = Readonly<MutableStateMarket>

const defStateMarket: StateMarket = {
  loading: false,
  cityIsSelected: false,
  selectCityModal: false,
}

export const market: Reducer<StateMarket, Actions.Action> = (s = defStateMarket, a): StateMarket => {
  switch (a.type) {
    case Actions.API_CITIES: {
      return {
        ...s,
        loading: true,
      }
    }
    case Actions.CITIES: {
      return {
        ...s,
        loading: false,
        cities: a.data.cities,
      }
    }
    case Actions.API_MARKETS:
      return {
        ...s,
        loading: true,
      }
    case Actions.MARKETS: {
      const market = a.data.markets.find((item) => item.id === s.market?.id)

      return {
        ...s,
        ...(market && { market }),
        markets: a.data.markets,
        loading: false,
      }
    }
    case Actions.SELECT_CITY:
      return {
        ...s,
        city: a.data.city,
      }
    case Actions.SELECT_MARKET:
      return {
        ...s,
        market: a.data.market,
      }

    // case Actions.CITY_IS_SELECTED:
    //   return {
    //     ...s,
    //     cityIsSelected: true,
    //   }
    // case Actions.CITY_IS_SELECTED_CLEAN:
    //   return {
    //     ...s,
    //     cityIsSelected: false,
    //   }

    case Actions.RESET_MARKETS:
      return {
        ...s,
        markets: undefined,
      }
  }
  return s
}
