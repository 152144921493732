import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const SaveCardIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M1.571 8.005H22.03V7.04c0-.81-.658-1.468-1.468-1.468H3.04c-.81 0-1.468.658-1.468 1.468v.966ZM22.03 9.577H1.57v6.974c0 .81.658 1.467 1.468 1.467H20.56c.81 0 1.468-.658 1.468-1.467V9.577ZM3.039 4H20.56A3.042 3.042 0 0 1 23.6 7.039v9.512a3.042 3.042 0 0 1-3.039 3.039H3.04A3.042 3.042 0 0 1 0 16.55V7.04A3.042 3.042 0 0 1 3.039 4Z'/>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M4.29 12.512h4.502a.786.786 0 0 1 0 1.571H4.29a.786.786 0 1 1 0-1.571Z'/>
        <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M4.29 12.512h4.502a.786.786 0 1 1 0 1.571H4.29a.786.786 0 1 1 0-1.571Z'/>
        <path fill={color} d='m23.006 3.002-4.55-1.95a.664.664 0 0 0-.513 0l-4.55 1.95A.652.652 0 0 0 13 3.6v2.6c0 3.576 1.322 5.667 4.876 7.714a.652.652 0 0 0 .648 0C22.078 11.872 23.4 9.781 23.4 6.2V3.6a.65.65 0 0 0-.394-.598ZM22.1 6.2c0 3.001-.993 4.654-3.9 6.396-2.907-1.746-3.9-3.398-3.9-6.396V4.029l3.9-1.672 3.9 1.672v2.17Z'/>
        <path fill='#fff' fillRule='evenodd' clipRule='evenodd' d='M23.4 2.083c.608.26 1 .858 1 1.517v2.6c0 1.918-.356 3.538-1.26 4.968-.896 1.417-2.268 2.55-4.118 3.613l-.002.001a1.652 1.652 0 0 1-1.64 0l-.003-.001c-1.85-1.066-3.222-2.2-4.117-3.616C12.356 9.735 12 8.116 12 6.2V3.6c0-.659.391-1.255.996-1.516L13 2.083 17.558.129a1.664 1.664 0 0 1 1.283 0l.01.004 4.55 1.95Zm-5.524 11.831C14.322 11.867 13 9.776 13 6.2V3.6c0-.26.155-.496.393-.598l4.55-1.95a.664.664 0 0 1 .513 0l4.55 1.95a.65.65 0 0 1 .394.598v2.6c0 3.581-1.322 5.672-4.876 7.714a.652.652 0 0 1-.648 0Zm2.51-4.563c.485-.798.714-1.762.714-3.151V4.688l-2.9-1.243-2.9 1.243V6.2c0 1.387.23 2.35.715 3.148.428.704 1.105 1.362 2.186 2.069 1.08-.706 1.756-1.362 2.185-2.066ZM18.2 12.596c-2.907-1.746-3.9-3.398-3.9-6.396V4.029l3.9-1.672 3.9 1.672v2.17c0 3.003-.993 4.655-3.9 6.397Z'/>
        <path fill='#fff' d='m15 4 3.5-1 2.722 1.333L21 10l-3 2-2.5-2-.5-6Z'/>
        <path fill={color} stroke='#fff' d='M15.611 5.894a1.15 1.15 0 0 0-.318 1.594l1.3 1.95c.204.306.54.493.904.511l.012.001H17.55c.347 0 .678-.156.899-.432l2.6-3.25a1.15 1.15 0 0 0-.18-1.617l-.002-.001a1.154 1.154 0 0 0-1.612.179l-.002.002-1.617 2.022-.43-.641v-.001a1.152 1.152 0 0 0-1.594-.317Z'/>
      </g>
    </svg>
  )
}
