import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { InputOnChangeData } from 'semantic-ui-react'

import './index.scss'

import { ApiMarketProductsReq } from '../../types/TApi'
import { IMarket, IProduct } from '../../types/TClient'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'
import { State } from '../../store/reducer'
import { SearchField } from '../SearchField'
import { ProductItem } from '../ProductItem'
import { Button } from '../Button'
import { LOCATION_CATALOG, LOCATION_PRODUCTS } from '../../utils/locationUtils'
import { checkTablet } from '../../utils/deviceUtils'

type TOwnProps = {
  onClose: () => void,
}

type TConnectedProps = {
  products: IProduct[],
  searchString: string,
  market?: IMarket,
}

type TDispatchedProps = {
  getProducts: (data: ApiMarketProductsReq) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & RouteComponentProps & WrappedComponentProps

type TState = {
  searchString: string,
  showShort: boolean,
}

const SEARCH_MIN = 3
const SEARCH_MAX = 24
const SEARCH_TIMEOUT = 1000

const MARKET_PRODUCTS_PAGE_LIMIT = 10

class SearchProductsCmp extends React.Component<TProps, TState> {
  private timer: number | null = null

  constructor(props: TProps) {
    super(props)

    this.state = {
      searchString: props.searchString,
      showShort: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    const { searchString } = this.props

    if (searchString !== prevProps.searchString) {
      this.setState({ searchString })
    }
  }

  render() {
    const { searchString, showShort } = this.state

    return (
      <div className='search-products'>
        {this.renderSearch()}
        {searchString.length >= SEARCH_MIN && showShort && this.renderSearchProducts()}
      </div>
    )
  }

  renderSearch = () => {
    const { formatMessage } = this.props.intl
    const isTablet = checkTablet()

    return (
      <div className='search-products__container'>
        <div className='search-products__field'>
          <SearchField
            onChange={this.handleSearch}
            value={this.state.searchString}
            placeholder={formatMessage(messages.ProductSearch)}
          />
        </div>
        {
          isTablet &&
          <div className='search-products__shadow' onClick={this.props.onClose}/>
        }
      </div>
    )
  }

  renderSearchProducts = () => {
    const { products } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='search-products__content'>
        {products.slice(0, 4).map(this.renderProduct)}
        {
          products.length > 4 && (
            <>
              <div className='search-products__divider'/>
              <div className='search-products__more' onClick={this.toSearchResults}>
                {formatMessage(messages.ShowAllProducts).toUpperCase()}
              </div>
            </>
          )
        }
        {
          products.length === 0 && (
            <div className='search-products__empty'>
              <div className='search-products__empty-title'>{formatMessage(messages.NothingFound)}</div>
              <div className='search-products__empty-text'>
                {formatMessage(messages.TryAnotherRequest)}
              </div>
              <div className='search-products__empty-btn'>
                <Button onClick={this.toCatalog} title={formatMessage(messages.ToCatalog)}/>
              </div>
            </div>
          )
        }
      </div>
    )
  }

  renderProduct = (item: IProduct) => {
    return (
      <ProductItem key={item.id} product={item}/>
    )
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    const { searchString } = this.state
    const { value } = data
    const newSearchString = value.trimLeft()

    this.setState({ searchString: newSearchString, showShort: true })

    // this.props.setSearchString({ value: newSearchString, source: EGetProductsType.CATEGORIES })

    if (newSearchString && newSearchString.length >= SEARCH_MIN) {
      this.delaySearch(() => this.getProducts(0, newSearchString.slice(0, SEARCH_MAX).trim()))
    } else if (
      searchString &&
      searchString.length >= SEARCH_MIN &&
      (!newSearchString || newSearchString.length < SEARCH_MIN)
    ) {
      this.delaySearch(() => this.getProducts(0, ''))
    }
  }

  getProducts = (offset?: number, name?: string) => {
    const { market, products } = this.props
    const searchString = (name !== undefined ? name : this.state.searchString) || ''

    if (market && (products.length % MARKET_PRODUCTS_PAGE_LIMIT === 0 || !offset)) {
      this.props.getProducts({
        offset: offset || 0,
        limit: MARKET_PRODUCTS_PAGE_LIMIT,
        market: market.id,
        // getBy: EGetProductsType.CATEGORIES,
        by_categories: true,
        ...(searchString && searchString.length >= SEARCH_MIN && { search: searchString, searchString }),
        fromSearch: true,
      })
    }
  }

  toCatalog = () => {
    this.props.onClose()
    this.props.history.push(`${LOCATION_CATALOG}`)
  }

  toSearchResults = () => {
    this.props.onClose()
    this.props.history.push(`${LOCATION_PRODUCTS}?search=${this.state.searchString}`)
    this.setState({ showShort: false })
  }

  delaySearch = (searchBy: () => void) => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = Number(
      setTimeout(() => {
        searchBy()
      }, SEARCH_TIMEOUT),
    )
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    market: s.market.market,
    products: s.products.productsSearch || [],
    searchString: s.products.searchString || '',
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
})

export const SearchProducts = connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(SearchProductsCmp)))
