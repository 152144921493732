import React  from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'

import './index.scss'

import { IProduct, IProductCart, IProductOrder, IProductOutOfStock } from '../../types/TClient'

import { BlockIcon, MinusIcon, PlusIcon, TrashIcon } from '../Icons'
import { COLORS } from '../../style/colors'
import * as Actions from '../../store/actions'
import { State } from '../../store/reducer'
import { checkTablet } from '../../utils/deviceUtils'
import { historyPush, LOCATION_PRODUCTS } from '../../utils/locationUtils'
import { checkDiscount, formatPrice, formatWeight, round, UNIT_PCS, unitIntl } from '../../utils/productsUtils'
import { EModalType, IModalProduct } from '../../store/reducers/modals'
import { Analytics, FirebaseAnalyticsService } from '../../utils/analyticsUtils'
import { LocalStorage } from '../../modules/localStorage'
import { srcBySize } from '../../utils/imgUtils'

type TOwnProps = {
  product: IProduct | IProductOrder | IProductOutOfStock,
  editable?: boolean,
  fromCart?: boolean,
}

type TConnectedProps = {
  existInCart: boolean,
  productCart?: IProductCart,
}

type TDispatchedProps = {
  modalProduct: (data: IModalProduct) => Actions.Action,
  modalClear: () => Actions.Action,
  addToCart: (data: IProductCart) => Actions.Action,
  updateCart: (data: IProductCart) => Actions.Action,
  removeFromCart: (productId: string) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps

type TState = {
  edit: boolean,
}

const EDIT_TIMEOUT = 5 * 1000

class ProductItemCmp extends React.Component<TProps, TState> {
  timer: number | null = null

  constructor(props: TProps) {
    super(props)

    this.state = {
      edit: false,
    }
  }

  render() {
    const { product, productCart, editable, fromCart } = this.props
    const productOrder = product as IProductOrder
    const isDeleted = productOrder?.quantity === 0
    const isAdded = productOrder?.added
    const quantity = isDeleted ? productOrder?.initialQuantity : undefined
    const image = srcBySize(product.image)
    const quantityText = quantity || productCart?.quantity || productOrder.quantity || 1

    return (
      <div
        className={classnames(
          'product-item',
          editable === false && 'product-item-disabled',
        )}
        onClick={this.toProduct}
      >
        {(isDeleted || isAdded) && (
          <div className={classnames('product-item__status', isDeleted && 'product-item__status-deleted')}>
            <div className='product-item__status-icon'>
              {isAdded ? <PlusIcon color={COLORS.WHITE}/> : <BlockIcon color={COLORS.WHITE}/>}
            </div>
          </div>
        )}
        <div className={classnames('product-item__image', isDeleted && 'product-item__deleted')}>
          <img
            alt='product-image'
            className='product-item__image'
            src={image.small}
            onError={image.errorHandler}
          />
        </div>
        <div className={classnames('product-item__info-container', isDeleted && 'product-item__deleted')}>
          <div className='product-item__info'>
            <div>
              <div className='product-item__name'>{product.name}</div>
              <div className='product-item__weight'>
                {formatWeight(product.unit === UNIT_PCS ? product.pcsWeightGr : (product.priceWeightGr || 1000), product.unit)}
              </div>
            </div>
            <div className='product-item__price-container'>
              <div className='product-item__price'>
                {formatPrice(round(product.price * quantityText), productOrder.currency)}
              </div>
              {
                checkDiscount(product) && (
                  <div className='product-item__discount'>
                    {formatPrice(round(product.priceWithoutDiscount * quantityText), productOrder.currency)}
                  </div>
                )
              }
            </div>
          </div>
          {editable !== false ? this.renderQuantity() : (
            <div className='product-item__quantity__value'>
              {quantity || productCart?.quantity || productOrder.quantity} {unitIntl(product.unit).toLowerCase()}
            </div>
          )}
          {fromCart && (
            <div className='product-item__remove' onClick={this.removeFromCart}>
              <div className='product-item__remove-icon'>
                <TrashIcon color={COLORS.GRAY_ELEM}/>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  renderQuantity = () => {
    const { product, productCart, existInCart } = this.props

    if (existInCart && productCart) {
      return this.renderQuantityEdit()
    }

    return (
      <div className='product-item__quantity-edit'>
       {
          existInCart && productCart ? (
            <div className='product-item__quantity-edit__btn' onClick={this.editQuantity}>
              <div className='product-item__quantity-edit__value'>
                {round(productCart?.quantity)} {unitIntl(product.unit).toLowerCase()}
              </div>
            </div>
          ) : (
            <div className='product-item__quantity-edit__btn' onClick={this.addToCart}>
              <div className='product-item__quantity-edit__btn-icon'>
                <PlusIcon color={COLORS.WHITE}/>
              </div>
            </div>
          )
        }
      </div>
    )
  }

  renderQuantityEdit = () => {
    const { product, productCart } = this.props

    return (
      <div className='product-item__quantity-edit'>
        <div
          className={classnames('product-item__quantity-edit__btn', 'product-item__quantity-edit__btn-dec')}
          onClick={(e) => this.decQuantity(e, productCart)}
        >
          <div className='product-item__quantity-edit__btn-icon'>
            <MinusIcon color={COLORS.BLACK_THEME} />
          </div>
        </div>
        <div className='product-item__quantity-edit__value'>
          {round(productCart?.quantity)} {unitIntl(product.unit).toLowerCase()}
        </div>
        <div className='product-item__quantity-edit__btn' onClick={(e) => this.incQuantity(e, productCart)}>
          <div className='product-item__quantity-edit__btn-icon'>
            <PlusIcon color={COLORS.WHITE} />
          </div>
        </div>
      </div>
    )
  }

  editQuantity = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    this.setState({ edit: true }, this.autoDropEdit)
  }

  incQuantity = (e: React.MouseEvent<HTMLDivElement>, product?: IProductCart) => {
    e.stopPropagation()

    if (!product) {
      return
    }

    // FirebaseAnalyticsService.logEvent({
    //   name: Analytics.ChangeQuantityInc,
    //   params: {
    //     source: productSource,
    //     name: product.name,
    //     price: product.price,
    //   },
    // })

    const quantity = round((product?.quantity || 0) + product.step)

    this.props.updateCart({ ...product, quantity })
    this.autoDropEdit()
  }

  decQuantity = (e: React.MouseEvent<HTMLDivElement>, product?: IProductCart) => {
    e.stopPropagation()

    if (!product) {
      return
    }

    const newQuantity = round((product.quantity || 0) - product.step)

    if (newQuantity >= product.step) {
      // FirebaseAnalyticsService.logEvent({
      //   name: Analytics.ChangeQuantityDec,
      //   params: {
      //     source: productSource,
      //     name: product.name,
      //     price: product.price,
      //   },
      // })

      this.props.updateCart({ ...product, quantity: newQuantity })
      this.autoDropEdit()
    } else {
      this.removeProduct(product)
    }
  }

  addToCart = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const { product, existInCart } = this.props
    const marketProduct = product as IProduct

    FirebaseAnalyticsService.logEvent({
      name: Analytics.AddToCart,
      params: {
        name: product.name,
        price: product.price,
      },
    })
    FirebaseAnalyticsService.addToCart([{ ...marketProduct, quantity: 1 }])

    if (existInCart) {
      this.props.updateCart({ ...marketProduct, quantity: 1 })
    } else {
      this.props.addToCart({ ...marketProduct, quantity: 1 })
    }

    this.setState({ edit: true }, this.autoDropEdit)
  }

  removeFromCart = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const { productCart } = this.props

    if (productCart) {
      this.removeProduct(productCart)
    }
  }

  autoDropEdit = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = Number(setTimeout(() => this.setState({ edit: false }), EDIT_TIMEOUT))
  }

  removeProduct = (product: IProduct) => {
    FirebaseAnalyticsService.logEvent({
      name: Analytics.DeletePosition,
      params: {
        name: product.name,
        price: product.price,
      },
    })
    FirebaseAnalyticsService.removeFromCart([{ ...product, quantity: 1 }])

    this.props.removeFromCart(product.id)
    this.setState({ edit: false })
  }

  toProduct = () => {
    const { product } = this.props
    const isMobile = checkTablet()
    const productOrder = product as IProductOrder

    LocalStorage.setProductInfo(product)

    if (isMobile) {
      this.props.modalClear()

      setTimeout(() => {
        historyPush(`${LOCATION_PRODUCTS}/${productOrder?.productId || product.id}`)
      }, 150)
    } else {
      this.props.modalProduct({
        type: EModalType.MODAL_PRODUCT,
        style: { width: '800px', maxWidth: '90%', borderRadius: '16px' },
        props: {
          productId: productOrder?.productId || product.id,
        }
      })
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { products = [] } = s.cart
  const { product } = own
  const productCart = product ? products.find((item) => item.id === product.id) : undefined

  return {
    productCart,
    existInCart: !!productCart,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  modalProduct: (data: IModalProduct) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  modalClear: () => dispatch(Actions.actionEmpty(Actions.MODAL_CLEAR)),
  addToCart: (data: IProductCart) => dispatch(Actions.action(Actions.ADD_TO_CART, data)),
  updateCart: (data: IProductCart) => dispatch(Actions.action(Actions.UPDATE_CART, data)),
  removeFromCart: (productId: string) => dispatch(Actions.action(Actions.REMOVE_FROM_CART, productId)),
})

export const ProductItem = connect(mapStateToProps, mapDispatchToProps)(ProductItemCmp)
