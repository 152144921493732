import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const LocationIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M15 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'/>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M5 10c0 5.25 7 11 7 11s7-5.75 7-11c0-3.87-3.13-7-7-7s-7 3.13-7 7Zm2 0c0-2.765 2.235-5 5-5s5 2.235 5 5c0 1.86-1.316 4.13-3.035 6.186A28.245 28.245 0 0 1 12 18.297a28.245 28.245 0 0 1-1.965-2.11C8.315 14.13 7 11.86 7 10Z'/>
      <circle cx='12' cy='10' r='1' fill='#fff'/>
    </svg>
  )
}
