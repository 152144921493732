import { IReviewOrder } from './TApi'
import { COUNTRY_ID } from './countryId'

export type ValueOf<T> = T[keyof T];
export type TCountryWithPrefix<T extends string> = `${T}${COUNTRY_ID}`
export type TCountriesDictionary<T extends string> = Record<TCountryWithPrefix<T>, string>

export interface ISignUpData {
  phone: string,
  password: string,
  firstName?: string,
  lastName?: string,
}

export interface IUser {
  id?: string,
  phone?: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  avatarUrl?: string | null,
  callMe?: boolean,
  callAboutChangeProduct?: boolean,
  callAboutDeleteProduct?: boolean,
  callAboutChangeProductWeight?: boolean,
  callChangeProduct?: boolean,
  callDeleteProductByWeight?: boolean,
}

export interface IAddress {
  city: string,
  street: string,
  building: string,
  floor?: string,
  entrance?: string,
  apartment?: string,
  intercom?: string,
  id?: string,
  lat?: string,
  lon?: string,
}

export interface IUpdateAddressCoords {
  id: string,
  lat: string,
  lon: string,
}

export interface ICard {
  id: string,
  cardId: string,
  pan: string,
  expDate: string,
  createdAt: string,
}

export interface IAddressSuggestion {
  id: string,
  value: string,
  unrestricted_value: string,
  data: ISuggestionData,
}

export interface ISuggestionData {
  city: string,
  street: string,
  city_type: string,
  full_city_name: string,
  street_type: string,
  city_with_type: string,
  street_with_type: string,
  sublocality: string,
  block: string,
  block_type: string,
  house: string,
  house_type: string,
  settlement_with_type: string,
  region_with_type: string,
  geo_lat: string,
  geo_lon: string,
}

export interface ISuggestionSuccess {
  suggestions: IAddressSuggestion[],
  fromGeo?: boolean,
  query?: string,
  location?: { lat: number, lon: number, fromGeo?: boolean },
}

export interface IConfirmAuth {
  token: string,
  user: IUser,
}

export interface IOrder {
  id: string,
  price: number,
  initialPrice?: number,
  paymentType: EPaymentType,
  paid: boolean,
  createdAt: Date,
  items: IProductOrder[],
  selfPickup: boolean,
  orderConfirmed: boolean,
  statusId: number,
  statusName: string,
  marketAddress?: string,
  marketOpeningHours?: string,
  marketClosingHours?: string,
  updatedAt?: Date,
  completedAt?: Date,
  message?: string,
  weightKg?: number,
  weightGr?: number,
  deliveryPrice?: number,
  deliveryAddress?: string,
  deliveryDate?: string,
  deliveryTime?: string,
  deliveryEndTime?: string,
  transaction?: ITransaction,
  review?: IReviewOrder,
  extPaid?: boolean,
  extTransaction?: ITransaction,
}

export type TUpdateOrder = Partial<IOrder> & { id: string }

export enum EOrderStatus {
  NEW = 0,
  IN_ASSEMBLY = 1,
  COURIER = 2,
  DELIVERED = 3,
  CANCELED = 4,
}

export enum EPaymentStatus {
  NEW = 'NEW',
  AUTHORIZED = 'AUTHORIZED',
  CONFIRMED = 'CONFIRMED',
  REVERSED = 'REVERSED',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED',
}

export interface IPaymentOption {
  id: EPaymentType,
  name: string,
}

export enum EPaymentType {
  BY_CARD = 0,
  CASH = 1,
}

export enum EDeliveryType {
  DELIVERY = 0,
  PICK_UP = 1,
}

export interface IProductOrder {
  id: string,
  productId: string,
  name: string,
  country: string,
  market: string,
  image: string,
  price: number,
  priceWeightGr?: number,
  currency: ICurrency,
  quantity: number,
  initialQuantity?: number,
  pcsWeightGr: number,
  sellerName: string,
  subcategory: string,
  unit: string,
  added: boolean,
  priceWithoutDiscount: number,
  marketCategoryId?: string,
  marketCategoryName?: string,
  marketSubcategoryId?: string,
  marketSubcategoryName?: string,
}

export interface ITransaction {
  id: string,
  amount: number,
  details: string,
  errorCode: string,
  message: string,
  orderId: string,
  paymentId: string,
  paymentURL: string,
  status: EPaymentStatus,
  success: boolean,
  terminalKey: string,
  cardId?: string,
}

export interface ICity {
  id: string,
  name: string,
  city_utc_offset?: string,
}

export interface IMarket {
  id: string,
  name: string,
  city: string,
  city_id: string,
  address: string,
  self_pickup_start_time: string,
  self_pickup_end_time: string,
  open_hours_start: string,
  open_hours_end: string,
  delivery_end_time: string,
  delivery_start_time: string,
  short_name?: string,
  delivery_info?: string,
  image_url?: string,
  price_segment?: number,
  currency: ICurrency,
}

export interface ITimeInterval {
  startTime: string,
  endTime: string,
}

export interface IMarketSubcategory {
  id: string,
  categoryId: string,
  marketId: string,
  name: string,
  index: number,
  imageUrl?: string,
  hidden: boolean,
  numProducts?: number,
}

export interface IMarketCategory {
  id: string,
  marketId: string,
  name: string,
  index: number,
  imageUrl?: string,
  hidden: boolean,
  numProducts?: number,
  subcategories: IMarketSubcategory[],
}

export interface ISellerCategory {
  id: string,
  name: string,
}

export interface ISeller {
  id: string,
  userId: number,
  isSystem: boolean,
  isCommon: boolean,
  commission: number,
  isHide: boolean,
  name: string,
  nickname: string,
  organization: string,
  location: string,
  imageUrl: string,
  avatarUrl: string,
  categories: ISellerCategory[],
  aboutSellers?: string[],
  cityId: string,
  cityName: string,
  marketId: string,
  marketAddress: string,
  marketName: string,
  marketOpeningHours?: string,
  marketClosingHours?: string,
}

export interface ISellerSearch {
  id: string,
  isSystem: boolean,
  isCommon: boolean,
  isHide: boolean,
  name: string,
  nickname: string,
  location: string,
  marketId: string,
  marketName: string,
  organization: string,
}

export interface ISellerProduct {
  id: string,
  imageUrl: string,
  market: string,
  name: string,
  pcsWeightGr: number,
  price: string,
  seller: string,
  sellerIsCommon?: boolean,
  sellerIsSystem?: boolean,
  sellerName?: string,
  sellerCommission?: number,
  step: number,
  subcategory: string,
  unit: string,
  country: string,
  hide: boolean,
  tags: number[],
  description?: string,
  category?: string,
  aboutSeller?: string,
  stockCategory?: string,
  stockSubcategory?: string,
  vendorCode?: string,
  rating?: number,
}

export interface IProductTag {
  id: number,
  tag: string,
  color?: string,
}

export interface ICurrency {
  symbol: string,
  name: string,
  symbol_native: string,
  decimal_digits: number,
  rounding: number,
  code: string,
  name_plural: string,
  dirRight: boolean,
  spaceStr: boolean,
}

export interface IProduct {
  id: string,
  name: string,
  description: string,
  price: number,
  priceWeightGr?: number,
  unit: string,
  pcsWeightGr: number,
  step: number,
  country: string,
  image: string,
  hidden: boolean,
  categoryId: string,
  categoryName: string,
  subcategoryId: string,
  subcategoryName: string,
  marketCategoryId?: string,
  marketCategoryName?: string,
  marketSubcategoryId?: string,
  marketSubcategoryName?: string,
  marketId: string,
  currency: ICurrency,
  sellerId: string,
  sellerName: string,
  sellerImage: string,
  sellerFIO: string,
  sellerNickname: string,
  sellerOrganizationName: string,
  sellerAvatar: string,
  priceWithoutDiscount: number,
  sellerInn?: string,
  sellerAddress?: string,
  sellerEntity?: string,
  tags: IProductTag[],
  added?: boolean,
}

export interface IProductCart extends IProduct {
  quantity: number,
}

export interface IProductOutOfStock {
  id: string,
  name: string,
  country: string,
  market: string,
  image: string,
  price: number,
  priceWeightGr?: number,
  quantity: number,
  pcsWeightGr: number,
  sellerName: string,
  subcategory: string,
  priceWithoutDiscount: number,
  marketCategoryId?: string,
  marketCategoryName?: string,
  marketSubcategoryId?: string,
  marketSubcategoryName?: string,
  unit: string,
  added?: boolean,
}

export enum EGroupType {
  GROUP = 1,
  LINK = 2,
}

export interface IProductGroup {
  id: number,
  groupId: string,
  priority: number,
  key: EGroupType,
  product: IProduct,
}

export interface ISubcategory {
  id: string,
  name: string,
  priority: number,
}

export interface ICategory {
  id: string,
  name: string,
  priority: number,
  subcategories: ISubcategory[],
}

export interface ITag {
  id: number,
  tag: string,
  market: string,
  color: string,
  position: number,
  imageUrl: string,
  productsCount: number,
}

export interface IDeliveryInfo {
  minOrderPrice?: number,
  slots: ISlot[],
  type: ISlotType[],
  fromComponent?: boolean,
}

export type ISlot = {
  id: string,
  time_start: string,
  time_end: string,
}

export interface ISlotType {
  delivery_interval: EDeliveryInterval,
  delivery_price: number,
}

export enum EDeliveryInterval {
  AS_SOON_AS_POSSIBLE,
  DURING_THE_DAY,
  NEAREST_SLOT,
  THROUGH_ONE_SLOT,
  THROUGH_TWO_SLOTS,
}

export enum DeliveryInfoStatus {
  ADDRESS_NOT_VALID = '1',
  MARKET_NOT_MATCH = '2',
  CALCULATE_PRICE = '3',
  MIN_DELIVERY_PRICE = '4',
  MAX_DELIVERY_RADIUS = '5',
  MARKET_CLOSED = '6',
  NO_SLOTS = '7',
  NOT_ACTIVE_TODAY = '8',
  CONSTANTLY_NON_ACTIVE = '9',
  MARKET_AND_POLYGON_CLOSED = '10',
}

export enum EMessengerType {
  TELEGRAM = 'Telegram',
  VIBER = 'Viber',
  CHAT = 'Chat',
}
