import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const ChevronDownIcon: React.FC<TProps> = ({ color, width = '100%', height = '100%' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' d='M6 8L12 14L18 8'/>
    </svg>
  )
}
