import React from 'react'

export const RussiaFlagIcon: React.FC<any> = () => {
  return (
    <div style={{ overflow: 'hidden', borderRadius: 4, display: 'flex', alignItems: 'center' }}>
      <svg
        width={26}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="a"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={26}
          height={20}
        >
          <path fill="#fff" d="M0 0h26v20H0z" />
        </mask>
        <g mask="url(#a)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0v20h26V0H0Z"
            fill="#3D58DB"
          />
          <mask
            id="b"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={26}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0v20h26V0H0Z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#b)" fillRule="evenodd" clipRule="evenodd">
            <path d="M0 0v6.667h26V0H0Z" fill="#F7FCFF" />
            <path d="M0 13.333V20h26v-6.667H0Z" fill="#C51918" />
          </g>
        </g>
      </svg>
    </div>
  )
}
