import React from 'react'
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import loadable from '@loadable/component'

import './index.scss'

import { ApiMarketCategoriesReq, ApiMarketsReq, ApiOrdersReq } from '../../../types/TApi'
import { ICity, IMarket, IMarketCategory, IUser } from '../../../types/TClient'

import * as Actions from '../../../store/actions'
import { State } from '../../../store/reducer'
const Main = loadable(() => import('../Main'))
const Cart = loadable(() => import('../Cart'))
const Products = loadable(() => import('../Products'))
const Product = loadable(() => import('../Product'))
const Profile = loadable(() => import('../Profile'))
const Orders = loadable(() => import('../Orders'))
const Order = loadable(() => import('../Order'))
const Support = loadable(() => import('../Support'))
const Documents = loadable(() => import('../Documents'))
const Delivery = loadable(() => import('../Delivery'))
const Cards = loadable(() => import('../Cards'))
import {
  LOCATION_MAIN,
  LOCATION_CATALOG,
  LOCATION_PRODUCTS,
  LOCATION_CART,
  LOCATION_PROFILE,
  LOCATION_CARDS,
  LOCATION_SUPPORT,
  LOCATION_DOCUMENTS,
  LOCATION_ORDERS,
  LOCATION_DELIVERY,
  LOCATION_PROMO_PRODUCTS,
  LOCATION_TAG,
} from '../../../utils/locationUtils'
import { FirebaseAnalyticsService } from '../../../utils/analyticsUtils'
import { CookieAlert } from '../../../components/CookieAlert'
import { LocalStorage } from '../../../modules/localStorage'
import PromoPage from '../PromoPage'
import TagPage from '../TagPage'

type TConnectedProps = {
  lang: string,
  user: IUser | null,
  token?: string | null,
  categories?: IMarketCategory[],
  city?: ICity,
  market?: IMarket,
}

type TDispatchedProps = {
  getCities: () => Actions.Action,
  getMarkets: (data: ApiMarketsReq) => Actions.Action,
  getPaymentOptions: () => Actions.Action,
  getProfile: () => Actions.Action,
  getOrders: (data?: ApiOrdersReq) => Actions.Action,
  getMarketCategories: (data: ApiMarketCategoriesReq) => Actions.Action,
  logout: () => Actions.Action,
}

type TProps = TConnectedProps & TDispatchedProps & RouteComponentProps

export class HomeCmp extends React.Component<TProps> {
  componentDidMount(): void {
    const { user } = this.props

    this.initialize()

    FirebaseAnalyticsService.setUserId(user?.id || 'guest')
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    if (prevProps.lang !== this.props.lang) {
      this.setState({})
    }
  }

  render() {
    return (
      <div className='home'>
        {this.renderArea()}
        <CookieAlert />
      </div>
    )
  }

  renderArea = () => {
    return (
      <Switch>
        <Route exact path={LOCATION_MAIN} component={Main} />
        <Route exact path={LOCATION_CATALOG} component={Main} />
        <Route exact path={`${LOCATION_CATALOG}/:id`} component={Products} />
        <Route exact path={`${LOCATION_PRODUCTS}`} component={Products} />
        <Route exact path={`${LOCATION_PRODUCTS}/:id`} component={Product} />
        <Route exact path={LOCATION_PROFILE} component={Profile} />
        <Route exact path={LOCATION_CART} component={Cart} />
        <Route exact path={LOCATION_DELIVERY} component={Delivery} />
        <Route exact path={LOCATION_PROFILE} component={Profile} />
        <Route exact path={LOCATION_CARDS} component={Cards} />
        <Route exact path={LOCATION_ORDERS} component={Orders} />
        <Route exact path={`${LOCATION_ORDERS}/:id`} component={Order} />
        <Route exact path={`${LOCATION_TAG}/:tag`} component={TagPage} />
        <Route exact path={LOCATION_SUPPORT} component={Support} />
        <Route exact path={LOCATION_DOCUMENTS} component={Documents} />
        <Route exact path={LOCATION_PROMO_PRODUCTS} component={PromoPage} />
        <Route path='*' component={Main} />
      </Switch>
    )
  }

  initialize = () => {
    const { token, city, market, categories } = this.props
    const sid = LocalStorage.getSid()

    if (!city) {
      this.props.getCities()
    } else {
      this.props.getMarkets({ city: city.id })
      this.props.getPaymentOptions()
    }

    if (token && sid) {
      this.props.getProfile()
      this.props.getOrders()
    } else if (token && !sid) {
      this.props.logout()
    }

    if (market && !categories) {
      this.props.getMarketCategories({
        market: market.id,
        with_subcategories: 'true',
      })
    }
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  return {
    lang: s.lang,
    user: s.customer.profile,
    token: s.customer.token,
    market: s.market.market,
    city: s.market.city,
    categories: s.products.marketCategories,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getCities: () => dispatch(Actions.actionEmpty(Actions.API_CITIES)),
  getMarkets: (data: ApiMarketsReq) => dispatch(Actions.action(Actions.API_MARKETS, data)),
  getPaymentOptions: () => dispatch(Actions.actionEmpty(Actions.API_PAYMENT_OPTIONS)),
  getProfile: () => dispatch(Actions.actionEmpty(Actions.API_PROFILE)),
  getOrders: (data?: ApiOrdersReq) => dispatch(Actions.action(Actions.API_ORDERS_LIST, data)),
  getMarketCategories: (data: ApiMarketCategoriesReq) => dispatch(Actions.action(Actions.API_MARKET_CATEGORIES, data)),
  logout: () => dispatch(Actions.actionEmpty(Actions.LOGOUT)),
})

export const Home = connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeCmp))
