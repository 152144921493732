import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const QuestionIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M9.879 13.307a.879.879 0 1 1-1.758 0 .879.879 0 0 1 1.758 0ZM6.188 7.33A2.816 2.816 0 0 1 9 4.518a2.816 2.816 0 0 1 2.813 2.812c0 1.308-.898 2.41-2.11 2.724v1.143a.703.703 0 0 1-1.406 0V9.44c0-.388.315-.703.703-.703.775 0 1.406-.63 1.406-1.406 0-.775-.63-1.406-1.406-1.406-.775 0-1.406.63-1.406 1.406a.703.703 0 1 1-1.407 0Z'/>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M0 9c0-4.975 4.026-9 9-9 4.975 0 9 4.026 9 9 0 4.975-4.026 9-9 9-4.975 0-9-4.026-9-9Zm1.406 0A7.59 7.59 0 0 0 9 16.594 7.59 7.59 0 0 0 16.594 9 7.59 7.59 0 0 0 9 1.406 7.59 7.59 0 0 0 1.406 9Z'/>
    </svg>
  )
}
