export const TABLET_WIDTH = 768
export const MOBILE_WIDTH = 445
export const MINI_MOBILE_WIDTH = 330

export const checkTablet = () => {
  const width = window.innerWidth

  return width < TABLET_WIDTH
}

export const checkMobile = () => {
  const width = window.innerWidth

  return width < MOBILE_WIDTH
}

export const checkMiniMobile = () => {
  const width = window.innerWidth

  return width < MINI_MOBILE_WIDTH
}

export const copyToClipboard = (text: string) => {
  const tempCmp = document.createElement('input')

  tempCmp.setAttribute('value', text)
  document.body.appendChild(tempCmp)
  tempCmp.select()
  document.execCommand('copy')

  document.body.removeChild(tempCmp)
}
