import { AjaxError } from 'rxjs/ajax'

import { reload } from './locationUtils'
import { log } from './logUtils'
import { LocalStorage } from '../modules/localStorage'

export const authRequestConfig = () => {
  const sidToken = LocalStorage.getSid()

  return {
    headers: {
      ...(sidToken && { Authorization: 'Token ' + sidToken }),
    },
  }
}

export const checkNotAuth = (error: AjaxError) => {
  if (error && error.response && error.response.status === 401) {
    LocalStorage.removeSid()
    reload()
  } else {
    log(error)
  }
}
