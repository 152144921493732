import { Reducer } from 'redux'

import { IMarketCategory, IProduct, IProductGroup, ITag } from '../../types/TClient'

import * as Actions from '../actions'
import { saveProducts } from '../../utils/productsUtils'
import { sortTags } from '../../utils/tagsUtils'

export type MutableStateProducts = {
  loading: boolean,
  productsLoading: boolean,
  searchString: string,
  marketCategories?: IMarketCategory[],
  products?: IProduct[],
  productsSearch?: IProduct[],
  promoProducts?: IProduct[],
  upSellProducts?: IProductGroup[],
  tags: ITag[],
}

export type StateProducts = Readonly<MutableStateProducts>

const defStateProducts: StateProducts = {
  loading: false,
  productsLoading: false,
  searchString: '',
  tags: [],
}

export const products: Reducer<StateProducts, Actions.Action> = (s = defStateProducts, a): StateProducts => {
  switch (a.type) {
    case Actions.API_MARKET_CATEGORIES:
      return {
        ...s,
        loading: true,
      }
    case Actions.MARKET_CATEGORIES:
      return {
        ...s,
        loading: false,
        marketCategories: a.data.categories,
      }
    case Actions.API_MARKET_PRODUCTS:
      return {
        ...s,
        productsLoading: true,
      }
    case Actions.MARKET_PRODUCTS: {
      const { products, force, search, searchStr, discount } = a.data
      const productsData = discount
        ? { promoProducts: saveProducts(force, products, s.promoProducts) }
        : { products: saveProducts(force, products, s.products) }

      return {
        ...s,
        productsLoading: false,
        ...(!search && productsData),
        ...(search && { productsSearch: products }),
        ...(searchStr && { searchString: searchStr }),
        ...(!searchStr && { searchString: '' }),
      }
    }
    case Actions.TAGS: {
      return {
        ...s,
        tags: sortTags(a.data),
      }
    }
    case Actions.API_PRODUCTS_RECOMMENDATION: {
      return {
        ...s,
      }
    }
    case Actions.PRODUCTS_RECOMMENDATION: {
      return {
        ...s,
        upSellProducts: a.data.sort((a, b) => a.key === b.key ? a.priority - b.priority : b.key - a.key),
      }
    }
  }
  return s
}
