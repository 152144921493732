import { StorageUtils } from './storageUtils'
import { EStorageKeys } from './storageKeys'
import { TObjectData } from './types'

export class LocalStorage {
  static utils = StorageUtils

  static checkCookieAlert() {
    return this.utils.getBoolean(EStorageKeys.COOKIE_ALERT)
  }

  static toggleCookieAlert(value: boolean) {
    return this.utils.setBoolean(EStorageKeys.COOKIE_ALERT, value)
  }

  static getSid() {
    return this.utils.getString(EStorageKeys.SID_KEY)
  }

  static setSid(value: string) {
    return this.utils.setString(EStorageKeys.SID_KEY, value)
  }

  static removeSid() {
    return this.utils.removeValue(EStorageKeys.SID_KEY)
  }

  static getProductInfo<T extends TObjectData>() {
    return this.utils.getObject<T>(EStorageKeys.SELECT_PRODUCT)
  }

  static setProductInfo<T extends TObjectData>(product: T) {
    return this.utils.setObject<T>(EStorageKeys.SELECT_PRODUCT, product)
  }
}
