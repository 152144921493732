import 'semantic-ui-css/semantic.min.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Dispatch } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'moment/locale/ru'
import 'moment/locale/en-gb'

import './style/global.scss'

import * as Actions from './store/actions'
import App from './App/App'
import configureStore from './store'
import { checkLang } from './utils/langUtils'

const { store, persistor } = configureStore()

export const dispatch: Dispatch = store.dispatch

// const sessionId = storage.getValue(storage.SID_KEY)

// if (sessionId) {
//   dispatch(Actions.actionEmpty(Actions.API_USER_INFO))
// } else if (isAuthRequired(window.location.pathname)) {
//   historyPush(LOCATION_LOGIN)
// }

setTimeout(() => {
  const lang = store.getState().lang || navigator.language

  dispatch(Actions.action(Actions.SET_INTERFACE_LANG, { lang: checkLang(lang) }))
}, 1000)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
