import { createBrowserHistory } from 'history'

export const LOCATION_MAIN = '/'

export const LOCATION_CATALOG = '/catalog'
export const LOCATION_ORDERS = '/orders'
export const LOCATION_TAG = '/tag'
export const LOCATION_PRODUCTS = '/products'
export const LOCATION_PROMO_PRODUCTS = '/promo'
export const LOCATION_CART = '/cart'
export const LOCATION_DELIVERY = '/delivery'
export const LOCATION_PROFILE = '/profile'
export const LOCATION_CARDS = '/cards'
export const LOCATION_SUPPORT = '/support'
export const LOCATION_DOCUMENTS = '/documents'

const NO_AUTH_LOCATIONS: string[] = []

export const history = createBrowserHistory()

export const isAuthRequired = (location: string): boolean => {
  return !NO_AUTH_LOCATIONS.includes(location)
}

export const historyReplace = (url: string, state?: any, title?: string) => {
  window.history.pushState(state || null, title || '', url)
  window.location.replace(url)
}

export const historyPush = (url: string, state?: any) => {
  history.replace(url, state || null)
}

export const reload = () => {
  window.location.reload()
}

export const parseQuery = (path: string) => {
  const params = new URLSearchParams(path)

  return Object.fromEntries(params.entries())
}
