import React from 'react'
import { Input, InputOnChangeData } from 'semantic-ui-react'

import './index.scss'

import { CloseIcon, SearchIcon } from '../Icons'
import { COLORS } from '../../style/colors'

type TProps = {
  onChange(event: React.ChangeEvent<HTMLInputElement> | undefined, data: InputOnChangeData): void,
  value: string,
  maxLength?: number,
  placeholder?: string,
}

export const SearchField: React.FC<TProps> = ({ onChange, value, placeholder }) => {
  return (
    <div className='search-field'>
      <Input
        value={value}
        className='search-field__input'
        type='text'
        placeholder={placeholder}
        onChange={onChange}
      >
        <input />
        {value.length > 0 ? (
          <div className='search-field__icon' onClick={() => onChange(undefined, { value: '' })}>
            <CloseIcon width={20} height={20} color={COLORS.GRAY_DARK_ELEM} />
          </div>
        ) : (
          <div className='search-field__icon' onClick={() => onChange(undefined, { value: '' })}>
            <SearchIcon width={24} height={24} color={COLORS.GRAY_DARK_ELEM} />
          </div>
        )}
      </Input>
    </div>
  )
}
