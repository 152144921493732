import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const CartIcon: React.FC<TProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' d='M7.409 14.246 5.874 7H18.5a1 1 0 0 1 .97 1.243l-1.348 5.392a2 2 0 0 1-1.741 1.505l-6.816.682a2 2 0 0 1-2.156-1.576v0ZM5.874 7l-.65-3H3.5'/>
      <path stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' d='M8.996 19a.992.992 0 0 0-.996.999A1 1 0 1 0 8.996 19ZM16.996 19a.992.992 0 0 0-.996.999 1 1 0 1 0 .996-.999Z'/>
    </svg>
  )
}
