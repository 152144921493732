import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const SearchIcon: React.FC<TProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M14.376 12.477a6.002 6.002 0 0 0-9.133-7.72 6 6 0 0 0 7.72 9.134l5.715 5.716 1.414-1.415-5.716-5.715Zm-2.062-6.305a4 4 0 1 1-5.657 5.656 4 4 0 0 1 5.657-5.656Z'/>
    </svg>
  )
}
