import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'

import './index.scss'

import { IProduct, IProductCart } from '../../types/TClient'

import { MinusIcon, PlusIcon } from '../Icons'
import { COLORS } from '../../style/colors'
import * as Actions from '../../store/actions'
import { State } from '../../store/reducer'
import { EModalType, IModalProduct } from '../../store/reducers/modals'
import { checkMobile, checkTablet } from '../../utils/deviceUtils'
import { LOCATION_PRODUCTS } from '../../utils/locationUtils'
import { checkDiscount, formatPrice, formatWeight, round, UNIT_PCS, unitIntl } from '../../utils/productsUtils'
import { Analytics, FirebaseAnalyticsService } from '../../utils/analyticsUtils'
import { srcBySize } from '../../utils/imgUtils'

type TOwnProps = {
  product: IProduct,
  width?: number,
}

type TConnectedProps = {
  existInCart: boolean,
  productCart?: IProductCart,
}

type TDispatchedProps = {
  modalProduct: (data: IModalProduct) => Actions.Action,
  addToCart: (data: IProductCart) => Actions.Action,
  updateCart: (data: IProductCart) => Actions.Action,
  removeFromCart: (productId: string) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & RouteComponentProps

type TState = {
  edit: boolean,
}

const EDIT_TIMEOUT = 5 * 1000

class ProductCardCmp extends React.Component<TProps, TState> {
  timer: number | null = null

  constructor(props: TProps) {
    super(props)

    this.state = {
      edit: false,
    }
  }

  render() {
    const { product, width } = this.props
    const isMobile = checkMobile()
    const image = srcBySize(product.image)

    return (
      <div
        className='product-card'
        style={{ width: `${width}px`, maxWidth: `${width}px`, ...(!isMobile && { minWidth: `${width}px`}) }}
        onClick={this.toProduct}
      >
        <div className='product-card__image'>
          <img
            alt='product-image'
            className='product-card__image'
            src={image.medium}
            onError={image.errorHandler}
          />
        </div>
        <div className='product-card__info'>
          <div className='product-card__name'>{product.name}</div>
          <div className='product-card__weight'>
            {formatWeight(product.unit === UNIT_PCS ? product.pcsWeightGr : (product.priceWeightGr || 1000), product.unit)}
          </div>
          {this.renderQuantity()}
        </div>
      </div>
    )
  }

  renderQuantity = () => {
    const { product, productCart, existInCart } = this.props
    const { edit } = this.state
    const quantity = productCart?.quantity || 0

    if (existInCart && edit) {
      return this.renderQuantityEdit()
    }

    return (
      <div className='product-card__price'>
        <div>
          {checkDiscount(product) && (
            <div className='product-card__price-discount'>
              {formatPrice(product.priceWithoutDiscount * ((product.priceWeightGr || 1000) / 1000), product.currency)}
            </div>
          )}
          <div className='product-card__price-value'>
            {formatPrice(product.price * ((product.priceWeightGr || 1000) / 1000), product.currency)}
          </div>
        </div>
        {
          existInCart ? (
            <div className='product-card__quantity-edit__btn' onClick={this.editQuantity}>
              <div className='product-card__quantity-edit__value'>
                {round(quantity)} {unitIntl(product.unit).toLowerCase()}
              </div>
            </div>
          ) : (
            <div className='product-card__quantity-edit__btn' onClick={this.addToCart}>
              <div className='product-card__quantity-edit__btn-icon'>
                <PlusIcon color={COLORS.WHITE}/>
              </div>
            </div>
          )
        }
      </div>
    )
  }

  renderQuantityEdit = () => {
    const { product, productCart } = this.props
    const quantity = productCart?.quantity || 0

    return (
      <div className='product-card__quantity-edit'>
        <div className='product-card__quantity-edit__btn' onClick={this.decQuantity}>
          <div className='product-card__quantity-edit__btn-icon'>
            <MinusIcon color={COLORS.WHITE} />
          </div>
        </div>
        <div className='product-card__quantity-edit__value'>
          {round(quantity)} {unitIntl(product.unit).toLowerCase()}
        </div>
        <div className='product-card__quantity-edit__btn' onClick={this.incQuantity}>
          <div className='product-card__quantity-edit__btn-icon'>
            <PlusIcon color={COLORS.WHITE} />
          </div>
        </div>
      </div>
    )
  }

  editQuantity = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    this.setState({ edit: true }, this.autoDropEdit)
  }

  incQuantity = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const { product, productCart } = this.props

    // FirebaseAnalyticsService.logEvent({
    //   name: Analytics.ChangeQuantityInc,
    //   params: {
    //     source: productSource,
    //     name: product.name,
    //     price: product.price,
    //   },
    // })

    const quantity = round((productCart?.quantity || 0) + product.step)

    this.props.updateCart({ ...product, quantity })
    this.autoDropEdit()
  }

  decQuantity = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const { product, productCart } = this.props
    const newQuantity = round((productCart?.quantity || 0) - product.step)

    if (newQuantity >= product.step) {
      // FirebaseAnalyticsService.logEvent({
      //   name: Analytics.ChangeQuantityDec,
      //   params: {
      //     source: productSource,
      //     name: product.name,
      //     price: product.price,
      //   },
      // })

      this.props.updateCart({ ...product, quantity: newQuantity })
      this.autoDropEdit()
    } else {
      this.removeProduct(product)
    }
  }

  addToCart = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()

    const { product, existInCart } = this.props

    FirebaseAnalyticsService.logEvent({
      name: Analytics.AddToCart,
      params: {
        name: product.name,
        price: product.price,
      },
    })
    FirebaseAnalyticsService.addToCart([{ ...product, quantity: product.step }])

    if (existInCart) {
      this.props.updateCart({ ...product, quantity: product.step })
    } else {
      this.props.addToCart({ ...product, quantity: product.step })
    }

    this.setState({ edit: true }, this.autoDropEdit)
  }

  autoDropEdit = () => {
    if (this.timer) {
      clearTimeout(this.timer)
    }

    this.timer = Number(setTimeout(() => this.setState({ edit: false }), EDIT_TIMEOUT))
  }

  removeProduct = (product: IProduct) => {
    FirebaseAnalyticsService.logEvent({
      name: Analytics.DeletePosition,
      params: {
        name: product.name,
        price: product.price,
      },
    })
    FirebaseAnalyticsService.removeFromCart([{ ...product, quantity: product.step }])

    this.props.removeFromCart(product.id)
    this.setState({ edit: false })
  }

  toProduct = () => {
    const { product } = this.props
    const isMobile = checkTablet()

    FirebaseAnalyticsService.detail([product])

    if (isMobile) {
      this.props.history.push(`${LOCATION_PRODUCTS}/${product.id}`)
    } else {
      this.props.modalProduct({
        type: EModalType.MODAL_PRODUCT,
        style: { width: '800px', maxWidth: '90%', borderRadius: '16px' },
        props: {
          product,
          productId: product.id,
        }
      })
    }
  }
}

const mapStateToProps = (s: State, own: TOwnProps): TConnectedProps => {
  const { products = [] } = s.cart
  const { product } = own
  const productCart = product ? products.find((item) => item.id === product.id) : undefined

  return {
    productCart,
    existInCart: !!productCart,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  modalProduct: (data: IModalProduct) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  addToCart: (data: IProductCart) => dispatch(Actions.action(Actions.ADD_TO_CART, data)),
  updateCart: (data: IProductCart) => dispatch(Actions.action(Actions.UPDATE_CART, data)),
  removeFromCart: (productId: string) => dispatch(Actions.action(Actions.REMOVE_FROM_CART, productId)),
})

export const ProductCard = connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductCardCmp))
