import { MessageKeys } from '../messages'
import countries from './countries'

export const en: { [x in MessageKeys]: string } = {
  ...countries,
  DownloadApp: 'Download app',
  DeliveryFresh: 'We deliver fresh products from market',
  DeliveryFor: 'In 90 minutes and from $1.99 with a guarantee of freshness',
  Country: 'Country',
  AboutProduct: 'About the product',
  AddToCart: 'Add to cart',
  OutOfStock: 'Not available',
  Total: 'Payment total',
  Seller: 'Seller',
  Address: 'Address',
  INN: 'TIN',
  Pcs: 'pcs',
  Kg: 'kg',
  Gram: 'G',
  Litre: 'l',
  Millilitre: 'ml',
  Back: 'Back',
  All: 'All',
  ResultRequest: 'Results on request',
  Other: 'Other',
  Documents: 'Documents',
  Treaty: 'Treaty',
  Agreement: 'Agreement',
  Requisites: 'Requisites',
  UserAgreement: 'User Agreement',
  PrivacyPolicy: 'Privacy Policy',
  Name: 'Name',
  Understand: 'It\'s clear',
  RequiredAuth: 'You must be logged in to view',
  LoginByPhone: 'Sign in with your phone number',
  Login: 'Login',
  Cart: 'Cart',
  CartEmpty: 'Cart is empty',
  PlaceItemsCart: 'Place items in it to place an order',
  ToCatalog: 'Go to catalog',
  Comment: 'Comment',
  ProductWishes: 'Product wishes',
  TotalPayable: 'Subtotal',
  CartProductByWeightInfo: 'There is a weight product in the basket, which means that it is possible' +
      ' to change the amount of the order in both directions',
  CheckoutFor: 'Go to checkout',
  WeightProductPrice: 'Weight product and price',
  CartProductByWeightFullInfo: 'Since there is a weight product in the cart, it is possible to change the amount' +
      ' of the order in both directions. The exact amount will be formed after the assembly of the goods.\n' +
      'The total amount is reserved in your account, and the exact amount is debited after the order is collected.',
  EmptyTrash: 'Empty trash',
  Cancel: 'Cancel',
  SavedCards: 'Saved cards',
  CardDataSecure: 'Card data is secure',
  YourBankCards: 'Your bank cards will be here',
  SaveCardByOrder: 'You can save the card when paying for the order',
  DeleteCard: 'Delete card',
  Delete: 'Delete',
  PlacingOrder: 'Placing an order',
  Recipient: 'Customer',
  NameOrCompany: 'Name or company name',
  ReceiptMethod: 'Receipt method',
  DateAndTime: 'Date and time',
  PaymentMethod: 'Payment method',
  HowCardPaymentWorks: 'How card payment works',
  SpecifyAddress: 'Go to address',
  SpecifyAnotherAddress: 'Specify another address',
  PlaceOrder: 'Place order',
  UpMinimumOrder: 'Up to the minimum order',
  HowPaymentWorks: 'How does payment work?',
  MarketClosingSoon: 'The market is closing soon!',
  MarketClosingSoonInfo: 'Sorry, the market will close soon, you can place an order tomorrow,' +
      ' the sellers will just buy everything fresh!',
  ItsPity: 'It\'s a pity',
  Order: 'Order',
  From: 'From',
  Canceled: 'Canceled',
  MoneyWillReturned: 'The money will be returned to the card',
  PayOrderOnTime: 'Pay for the order so that we start collecting it and deliver it on time',
  Pay: 'Pay',
  PayExtra: 'PayExtra',
  Delivery: 'Delivery',
  Pickup: 'Pickup',
  Free: 'Free',
  RepeatOrder: 'Repeat order',
  ConnectWithUs: 'Connect with us',
  CancelOrder: 'Cancel order',
  OrderPrice: 'Order price',
  OrderCancellation: 'Order cancellation',
  ApproveOrderCancel: 'Are you sure you want to cancel the order? It will be impossible to restore the order.' +
      ' It\'s not too late to change your mind.',
  NotCancel: 'Don\'t cancel',
  MyOrders: 'My orders',
  Profile: 'Profile',
  Purchases: 'Purchases',
  Communications: 'Communications',
  Logout: 'Logout',
  WhatName: 'What is your name?',
  Support: 'Support',
  Exit: 'Exit',
  ConfirmLogout: 'Are you sure you want to leave?',
  AnswerYourQuestions: 'We will gladly answer your questions',
  OnlineChat: 'Online chat',
  AddingAddress: 'Adding an address',
  Add: 'Add',
  CityAndStreet: 'City and street',
  Street: 'Street',
  StreetShort: 'Str',
  House: 'House',
  HouseShort: 'House',
  Flat: 'Flat',
  FlatShort: 'Flat',
  Entrance: 'Entrance',
  EntranceShort: 'Ent.',
  Floor: 'Floor',
  Intercom: 'Intercom',
  IntercomShort: 'Int.',
  Code: 'Code',
  CityRequired: 'City is required for ordering',
  SpecifyHouse: 'Specify a house',
  HowLastOrder: 'How do you like the last order?',
  WhatLike: 'What did you like?',
  PostReview: 'Post a review',
  Select: 'Select',
  Cash: 'Cash',
  NewCard: 'NewCard',
  ReplacingGoods: 'When replacing goods, there may be additional write-offs',
  ReplacingGoodsInfo: 'If you decide to replace one product with another, we will recalculate the order amount.' +
      ' If the amount increases, we will write off the missing amount from your card',
  FreezeMoney: 'Freeze first, then write off',
  FreezeMoneyInfo: 'At the time of the order, we freeze the purchase amount on your card,' +
      ' and write it off after the order is collected.',
  OrderPaid: 'Order has been paid',
  PaymentFailed: 'Payment failed',
  CheckCardValid: 'Check the balance of the card or its details. Perhaps they are in error.',
  AddAddress: 'Add address',
  RemoveAddress: 'Remove address?',
  PickupAddress: 'Pickup address',
  MarketHours: 'Market hours',
  DailyFrom: 'Daily from',
  Before: 'Before',
  After: 'After',
  RateOrder: 'Rate order',
  CardOnline: 'Credit or debit card',
  ProductSearch: 'Product search',
  ShowAllProducts: 'Show all products',
  NothingFound: 'Nothing found',
  TryAnotherRequest: 'Try another request or look for a product in the Catalog',
  YourCity: 'Your city',
  ChooseAnother: 'Choose another',
  YesThatRight: 'Yes, that\'s right',
  CitySelection: 'City selection',
  NotOpenedInOtherCity: 'We have not opened in other cities yet, but we will start work very soon!',
  ItemsOrder: 'Items in the order',
  Goods: 'Goods',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
  AsSoonAsPossible: 'As soon as possible',
  DuringDay: 'During the day',
  DeliveryAsSoonAsPossible: 'Delivery as soon as possible',
  DeliveryAsSoonAsPossibleInfo: 'Express delivery as soon as possible.' +
      ' Usually such orders are delivered within 90 minutes from the time of order.',
  DeliveryDuringDay: 'Delivery within a day',
  DeliveryDuringDayInfo: 'Delivery without reference to a specific time.' +
      ' We will deliver the order during the day as the delivery service is busy',
  CanPickUp: 'You can pick up',
  With: 'From',
  LoginSignUp: 'Login / Sign up',
  UserOfferPolicyAgree1: 'By continuing you agree to',
  UserOfferPolicyAgree2: 'offer',
  UserOfferPolicyAgree3: 'and',
  UserOfferPolicyAgree4: 'personal data processing policy',
  NeedPhoneToOrder: 'Need a phone to place an order',
  CodeSent: 'Verification code sent to phone',
  CodeFromSMS: 'Code from SMS',
  InvalidCode: 'Invalid confirmation code',
  Resend: 'Resend',
  SubmitCode: 'Submit code',
  GoodsOutOfStock: 'Goods out of stock',
  ContinueWithout: 'Continue without them',
  Replace: 'Replace',
  NeedPayExtra: 'Need to pay extra',
  WaitingPayment: 'Waiting for payment',
  AcceptedWork: 'New / Placed',
  Collecting: 'Collecting',
  OnCourier: 'In delivery',
  Delivered: 'Delivered',
  NumCharactersNoMore: '{field} - number of characters must be no more {max}',
  ItemsDiscount: 'Discount',
  CookiesPolicy1: 'We use cookies on our website. By continuing to use the site, you agree to the use of cookies and',
  CookiesPolicy2: ' privacy policy.',
  Error: 'Error',
  NotDeliverHere: 'We don\'t deliver here yet',
  SorryNotDeliverAddress: 'Sorry, we don\'t deliver to your address yet. We are working on expanding' +
    'the delivery area and, perhaps, we will get to you very soon!',
  OrderCancelled: 'Order cancelled',
  ReturningMoneyInfo: 'The term for returning funds to the card can be up to 7 banking days. ' +
    'Usually funds are returned faster. If the funds are not returned within 7 banking days, please ' +
    'contact the bank that issued your card. Rarely, but it happens that banks do not notify about the return.',
  EnteredCityNotMatchCityMarket: 'The entered city does not match the city of the market',
  SorryUnableCalcDeliveryCost: 'Sorry, unable to calculate delivery cost',
  DeliveryCostLessMin: 'Delivery cost less than minimum',
  Promo: 'Promo',
  AllPromo: 'All promo',
  AddMoreItems: 'Add more items',
  OneLastCraving: 'One last craving?',
  NoThankYou: 'No, thank you',
  GoToPay: 'Go to pay',
}
