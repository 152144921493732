import { EStorageKeys } from './storageKeys'
import { TObjectData } from './types'

const TEST_STORAGE = 'TEST_STORAGE'

export class StorageUtils {
  private static storage: undefined | Storage = checkStorage() ? localStorage : undefined

  static setString(key: EStorageKeys, value: string) {
    try {
      this.storage?.setItem(key, String(value))
    } catch (e) {
      return
    }
  }

  static setBoolean(key: EStorageKeys, value: boolean) {
    try {
      this.storage?.setItem(key, String(value))
    } catch (e) {
      return
    }
  }

  static setNumber(key: EStorageKeys, value: number) {
    try {
      this.storage?.setItem(key, String(value))
    } catch (e) {
      return
    }
  }

  static setObject<T extends TObjectData>(key: EStorageKeys, value: T) {
    try {
      this.storage?.setItem(key, JSON.stringify(value))
    } catch (e) {
      return
    }
  }

  static getNumber(key: EStorageKeys): number | null {
    let value: string | null | undefined = null

    try {
      value = this.storage?.getItem(key)
    } catch (e) {
      return null
    }

    return value !== null && value !== undefined ? +value : null
  }

  static getString(key: EStorageKeys): string {
    let value = ''

    try {
      value = this.storage?.getItem(key) || ''
    } catch (e) {
      return ''
    }

    return value
  }

  static getBoolean(key: EStorageKeys): boolean {
    let value = false

    try {
      value = this.storage?.getItem(key) === 'true'
    } catch (e) {
      return false
    }

    return value
  }

  static getObject<T extends TObjectData>(key: EStorageKeys): T | null {
    let value = null

    try {
      const data = this.storage?.getItem(key)

      if (!data) {
        return null
      }

      value = JSON.parse(data)
    } catch (e) {
      return null
    }

    return value
  }

  static removeValue(key: EStorageKeys) {
    try {
      return this.storage?.removeItem(key)
    } catch (e) {
      return undefined
    }
  }

  static clear() {
    try {
      return this.storage?.clear()
    } catch (e) {
      return undefined
    }
  }
}

function checkStorage(): boolean {
  try {
    localStorage.setItem(TEST_STORAGE, TEST_STORAGE)
    localStorage.removeItem(TEST_STORAGE)
    return true
  } catch (e) {
    return false
  }
}
