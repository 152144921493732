import { Reducer } from 'redux'
import { differenceWith } from 'lodash'

import {
  EPaymentType,
  ISlot,
  ISlotType,
  EDeliveryInterval,
  DeliveryInfoStatus,
  IProductCart,
  IProductOutOfStock,
  IPaymentOption,
} from '../../types/TClient'

import * as Actions from '../actions'
import { getDefaultDeliveryInterval, getSlotType } from '../../utils/cartUtils'

export type MutableStateCart = {
  delivery: boolean,
  paymentType: EPaymentType,
  deliveryInterval?: EDeliveryInterval,
  deliveryLoading: boolean,
  checkProductsLoading: boolean,
  deliveryError: boolean,
  isToday: boolean,
  isTodayPermission: boolean,
  slots: ISlot[],
  slotTypes: ISlotType[],
  minOrderPrice?: number,
  currentCardId?: string,
  deliveryPolygonError: boolean,
  deliveryErrorStatus?: DeliveryInfoStatus,
  comment?: string,
  deliveryCost?: number,
  deliverySlot?: ISlot,
  products?: IProductCart[],
  productsOutOfStock?: IProductOutOfStock[],
  paymentOptions: IPaymentOption[],
}

export type StateCart = Readonly<MutableStateCart>

const defStateCart: StateCart = {
  paymentType: EPaymentType.BY_CARD,
  isToday: true,
  isTodayPermission: true,
  delivery: true,
  deliveryLoading: false,
  deliveryError: false,
  deliveryPolygonError: false,
  checkProductsLoading: false,
  slots: [],
  slotTypes: [],
  paymentOptions: [],
}

export const cart: Reducer<StateCart, Actions.Action> = (s = defStateCart, a): StateCart => {
  switch (a.type) {
    case Actions.PAYMENT_OPTIONS:
      return {
        ...s,
        paymentOptions: a.data,
      }
    case Actions.ADD_TO_CART:
      return {
        ...s,
        products: [...(s.products || []), a.data],
      }
    case Actions.UPDATE_CART:
      return {
        ...s,
        products: s.products
          ? s.products.map((product) => {
            return product.id === a.data.id ? { ...a.data } : product
          })
          : undefined,
      }
    case Actions.REMOVE_FROM_CART: {
      const newProducts = s.products ? s.products.filter((product) => product.id !== a.data) : undefined

      return {
        ...s,
        products: newProducts,
        productsOutOfStock:
          s.productsOutOfStock && newProducts
            ? s.productsOutOfStock.filter((item) => newProducts.find((product) => product.id === item.id))
            : undefined,
      }
    }
    case Actions.REMOVE_PRODUCTS_FROM_CART: {
      return {
        ...s,
        products: differenceWith(s.products || [], a.data, (p, id) => p.id === id),
        productsOutOfStock: differenceWith(s.productsOutOfStock || [], a.data, (p, id) => p.id === id),
      }
    }
    case Actions.RESET_CART:
      return {
        ...defStateCart,
        paymentOptions: s.paymentOptions,
        paymentType: s.paymentType,
        currentCardId: s.currentCardId,
      }
    case Actions.SET_COMMENT:
      return {
        ...s,
        comment: a.data,
      }
    case Actions.DROP_COMMENT:
      return {
        ...s,
        comment: undefined,
      }
    case Actions.SET_DELIVERY_TYPE:
      return {
        ...s,
        delivery: a.data,
        ...(!a.data && {
          deliveryCost: undefined,
          deliveryLoading: false,
        }),
      }
    case Actions.SET_PAYMENT_TYPE:
      return {
        ...s,
        paymentType: a.data.paymentType,
        currentCardId: a.data.cardId,
      }
    case Actions.DROP_DELIVERY_COST:
      return {
        ...s,
        deliveryCost: undefined,
      }
    case Actions.DELIVERY_COST_ERROR:
      return {
        ...s,
        deliveryError: true,
        deliveryLoading: false,
      }
    case Actions.RESET_DELIVERY_COST_ERROR:
      return {
        ...s,
        deliveryError: false,
        deliveryLoading: false,
      }
    case Actions.API_PRODUCTS_OUT_OF_STOCK:
      return {
        ...s,
        productsOutOfStock: [],
        checkProductsLoading: true,
      }
    case Actions.PRODUCTS_OUT_OF_STOCK:
      return {
        ...s,
        checkProductsLoading: false,
        productsOutOfStock: a.data,
      }
    case Actions.DELIVERY_INFO: {
      const { slots, fromComponent } = a.data
      const defaultSlot = slots.length ? slots[0] : undefined

      const deliverySlot = slots.find((slot) => slot.id === s.deliverySlot?.id) || defaultSlot
      const deliveryType = a.data.type.find((slotType) => slotType.delivery_interval === s.deliveryInterval)

      const byInterval =
        deliveryType?.delivery_interval === EDeliveryInterval.DURING_THE_DAY ||
        deliveryType?.delivery_interval === EDeliveryInterval.AS_SOON_AS_POSSIBLE ||
        !defaultSlot

      const interval =
        deliveryType?.delivery_interval !== undefined
          ? deliveryType?.delivery_interval
          : getDefaultDeliveryInterval(a.data.type)?.delivery_interval
      const deliveryInterval = byInterval ? interval : getSlotType(a.data.type)?.delivery_interval

      return {
        ...s,
        slots,
        deliveryInterval: fromComponent ? s.deliveryInterval : deliveryInterval,
        deliverySlot: fromComponent ? s.deliverySlot : byInterval ? undefined : deliverySlot,
        slotTypes: a.data.type,
        deliveryCost: !s.deliveryCost
          ? getSlotType(a.data.type, deliveryInterval)?.delivery_price
          : s.deliveryCost,
        minOrderPrice: a.data.minOrderPrice,
        deliveryError: false,
        deliveryLoading: false,
      }
    }
    case Actions.DELIVERY_INFO_ERROR:
      return {
        ...s,
        slotTypes: [],
      }
    case Actions.SET_DELIVERY_SLOT:
      return {
        ...s,
        deliverySlot: a.data,
      }
    case Actions.SELECT_DELIVERY_INTERVAL_TYPE: {
      return {
        ...s,
        deliveryInterval: a.data,
        deliveryCost: getSlotType(s.slotTypes, a.data)?.delivery_price,
      }
    }
    case Actions.TOGGLE_IS_TODAY:
      return {
        ...s,
        isToday: a.data,
      }
    case Actions.CHANGE_IS_TODAY_PERMISSION:
      return  {
        ...s,
        isTodayPermission: a.data,
      }
    case Actions.CHECK_CURRENT_ADDRESS:
      return {
        ...s,
        deliveryPolygonError: true,
        deliveryErrorStatus: a.data,
      }
    case Actions.RESET_CHECK_CURRENT_ADDRESS:
      return {
        ...s,
        deliveryPolygonError: false,
        deliveryErrorStatus: undefined,
      }
  }
  return s
}
