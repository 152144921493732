import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const CashIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} d='M12 16a3.667 3.667 0 1 0 0-7.333A3.667 3.667 0 0 0 12 16Z' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/>
      <path stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' d='M20.556 5H3.444A2.444 2.444 0 0 0 1 7.444v9.778a2.444 2.444 0 0 0 2.444 2.445h17.112A2.444 2.444 0 0 0 23 17.222V7.444A2.444 2.444 0 0 0 20.556 5ZM19.334 12.333h.013M4.666 12.333h.013'/>
    </svg>
  )
}
