import moment from 'moment'

import { IApiCard } from '../types/TApi'
import { ICard } from '../types/TClient'

export const convertCardFromApi = ({ card_id, pan, exp_date, id, created_at }: IApiCard): ICard => ({
  pan,
  id,
  cardId: card_id,
  expDate: exp_date,
  createdAt: created_at,
})

export const sortCardsByTime = (a: ICard, b: ICard) => moment(b.createdAt).diff(a.createdAt)
