import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const MarketIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g fill={color}>
        <path stroke={color} strokeWidth='.2' d='M13.914 23.05c.064.32.344.55.67.55h9.333a.684.684 0 0 0 .663-.518l2.333-9.333a.683.683 0 0 0-.497-.829.678.678 0 0 0-.829.498l-.746 2.982H13.417a.686.686 0 0 0-.67.817l1.167 5.833ZM24.5 17.766l-1.116 4.467h-8.24l-.894-4.466H24.5ZM16.232 25.833c0 .698.569 1.267 1.267 1.267s1.266-.569 1.266-1.267-.568-1.266-1.266-1.266c-.698 0-1.267.568-1.267 1.266ZM20.9 25.833c0 .698.569 1.267 1.267 1.267s1.266-.569 1.266-1.267-.568-1.266-1.266-1.266c-.698 0-1.267.568-1.267 1.266ZM.583 10.6h26.834a.684.684 0 0 0 0-1.367H.583a.684.684 0 0 0 0 1.367Z'/>
        <path fillRule='evenodd' clipRule='evenodd' d='M.973 9.82a.783.783 0 0 0 .777.88h24.5a.783.783 0 0 0 .777-.88l-1.04-8.312A1.952 1.952 0 0 0 24.054-.2H3.947c-.982 0-1.814.734-1.935 1.708L1.37 8.26 1.17 9.844a.581.581 0 0 0 .024.252l-.033.339-.189-.616ZM25.503.77c.15.222.251.48.286.762l1.04 8.311a.583.583 0 0 1-.579.656H1.75h24.5a.585.585 0 0 0 .579-.656l-1.04-8.31a1.739 1.739 0 0 0-.286-.763ZM2.41 9.333l.958-7.655a.583.583 0 0 1 .579-.511h20.106H3.947a.583.583 0 0 0-.579.51L2.41 9.334Zm22.023-7.63.929 7.43H2.637l.93-7.43a.383.383 0 0 1 .38-.336h20.106c.194 0 .357.144.38.336Z'/>
        <path stroke={color} strokeWidth='.2' d='M6.905 9.832a.683.683 0 0 0 1.356.17L9.428.668a.683.683 0 1 0-1.356-.17L6.905 9.833ZM13.316 9.917a.684.684 0 0 0 1.367 0V.583a.684.684 0 0 0-1.367 0v9.334Z'/>
        <path fillRule='evenodd' clipRule='evenodd' d='m18.473.68 1.167 9.334a.784.784 0 0 0 1.554-.194L20.027.486a.783.783 0 0 0-1.554.195Zm2.016 9.816.025.198h.003l-.028-.198Zm-.574-.282a.584.584 0 0 0 1.08-.37L19.83.512l1.166 9.334a.583.583 0 0 1-1.08.369Z'/>
        <path stroke={color} strokeWidth='.2' d='M5.25 25.767h5.834a.684.684 0 0 0 0-1.367H5.251c-.91 0-1.65-.74-1.65-1.65V10.6h21.483a.684.684 0 0 0 0-1.367H2.917a.684.684 0 0 0-.683.684V22.75a3.02 3.02 0 0 0 3.017 3.017Z'/>
        <path stroke={color} strokeWidth='.2' d='M7.584 21.1h2.333a.684.684 0 0 0 0-1.367H7.584a.484.484 0 0 1-.483-.483v-3.5c0-.266.216-.483.483-.483h3.5a.684.684 0 0 0 0-1.367h-3.5c-1.02 0-1.85.83-1.85 1.85v3.5c0 1.02.83 1.85 1.85 1.85Z'/>
      </g>
    </svg>
  )
}
