import { COUNTRY_ID } from '../types/countryId'

import messages, { Message, TCountryKeys } from '../localization/messages'
import { addPrefix } from './countriesUtils'
import { intlMessage } from '../App/LangContainer'

export const LANG_DEF = 'en'

export const checkLang = (lang: string = LANG_DEF) => {
  switch (lang) {
    case 'ru':
      return lang
    case 'en':
      return lang
    default:
      return LANG_DEF
  }
}

export const createCountryMessages = (countries: { [x in TCountryKeys]: string }): Record<TCountryKeys, Message> => {
  let result: Record<TCountryKeys, Message> | Record<string, unknown> = {}
  const countriesObj: { [key in string]: COUNTRY_ID } = { ...COUNTRY_ID }

  for (const key in countriesObj) {
    const value = countriesObj[key]

    result = {
      ...result,
      [addPrefix(value)]: {
        id: addPrefix(value),
        defaultMessage: countries[addPrefix(value)],
      },
    }
  }

  return result as Record<TCountryKeys, Message>
}

export const countryLocalization = (country: string): string => {
  const formattedCountry = addPrefix(country)
  const message = messages[formattedCountry]

  return message ? intlMessage(message) : country
}