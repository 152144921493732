import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const ChatIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M10.207 1.765A5.967 5.967 0 0 0 .241 4.298a5.988 5.988 0 0 0 .245 4.046.635.635 0 0 1 .054.382l-.526 2.53a.599.599 0 0 0 .362.675c.075.03.155.045.235.043h.12l2.556-.515a.75.75 0 0 1 .382.054 5.963 5.963 0 0 0 7.307-2.145 5.988 5.988 0 0 0-.739-7.59l-.03-.013Zm-6.61 4.826a.596.596 0 0 1-.422-1.02.597.597 0 1 1 .422 1.02Zm2.389 0a.596.596 0 0 1-.423-1.02.597.597 0 1 1 .423 1.02Zm2.388 0a.596.596 0 0 1-.422-1.02.597.597 0 1 1 .422 1.02Z'/>
    </svg>
  )
}
