import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const BlockIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} stroke={color} strokeWidth='.3' d='M4.008 4.019c-2.744 2.744-2.744 7.219-.001 9.977a7.035 7.035 0 0 0 4.981 2.07c1.808 0 3.617-.69 4.982-2.07 2.773-2.758 2.773-7.233.014-9.977-2.743-2.744-7.218-2.743-9.976 0Zm1.066 8.91a5.556 5.556 0 0 1-.497-7.288l7.785 7.785c-2.172 1.645-5.298 1.492-7.288-.497Zm8.356-.57L5.644 4.574a5.54 5.54 0 0 1 3.36-1.124c1.417 0 2.848.54 3.928 1.621 1.99 1.99 2.142 5.115.498 7.288Z'/>
    </svg>
  )
}
