import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const CourierIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g fillRule='evenodd' clipRule='evenodd' fill={color}>
        <path d='M2.904 4.143a4.004 4.004 0 0 0 4 4.248c2.086 0 3.813-1.6 3.992-3.657h1.093a.703.703 0 0 0 0-1.406h-1.078V2.063c0-1.163-.947-2.11-2.11-2.11H5.004c-1.162 0-2.109.947-2.109 2.11 0 .53.009 1.558.009 2.08ZM7.94 13.24a.701.701 0 1 0-.994.989l2.559 2.577v6.445a.703.703 0 0 0 1.406 0v-5.03c.412.415.638.694 1.229.875.296.091.598.124.891.124h5.906c1.162 0 2.11-.948 2.11-2.11 0-1.161-.948-2.109-2.11-2.109h-5.276l-3.78-3.87a4.038 4.038 0 0 0-1.34-.98c-2.647-1.195-5.646.767-5.646 3.655v9.445a.703.703 0 0 0 1.406 0v-9.445c0-1.88 1.959-3.144 3.664-2.375.485.219.586.403.95.717l3.915 4.01c.129.152.322.25.538.25h5.57c.612 0 .945.75.495 1.199a.7.7 0 0 1-.495.207H13.03c-.28 0-.584-.035-.779-.231L7.94 13.239Zm7.94-4.661v.27a.703.703 0 1 0 1.406 0v-.27h1.125v3.61h-3.656v-3.61h1.125Zm3.234-1.406c.389 0 .703.315.703.703v5.016a.703.703 0 0 1-.703.703h-5.062a.703.703 0 0 1-.703-.703V7.875c0-.388.314-.703.703-.703h5.062ZM9.481 4.734a2.602 2.602 0 0 1-5.156 0H9.48ZM5.004 1.36h3.797c.387 0 .704.317.704.704v1.265H4.3V2.063c0-.387.317-.704.703-.704Z'/>
        <path d='M15.88 8.578v.27a.703.703 0 1 0 1.407 0v-.27h1.125v3.61h-3.656v-3.61h1.125Zm3.235-1.406c.388 0 .703.315.703.703v5.016a.703.703 0 0 1-.703.703h-5.062a.703.703 0 0 1-.704-.703V7.875c0-.388.315-.703.704-.703h5.062Z'/>
      </g>
    </svg>
  )
}
