import { shuffle, uniqBy } from 'lodash'

import { ICategoryApi, IMarketCategoryApi, IMarketSubcategoryApi, IProductApi, IProductTagApi } from '../types/TApi'
import {
  ICategory,
  ICurrency,
  IMarketCategory,
  IMarketSubcategory,
  IProduct,
  IProductOrder,
  IProductOutOfStock,
  IProductTag,
} from '../types/TClient'

import { getIntl, intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'
import { CURRENCIES, DEF_CURRENCY } from '../localization/currencies'

export const UNIT_PCS = 'шт'
export const UNIT_KG = 'Кг'
export const UNIT_LITRE = 'Л'

export const convertProductFromApi = (product: IProductApi): IProduct => {
  return {
    id: product.id,
    name: product.name,
    description: product.description,
    price: product.price,
    priceWeightGr: product.price_weight_gr,
    unit: product.unit,
    pcsWeightGr: product.pcs_weight_gr,
    step: +product.step,
    country: product.country,
    image: product.image,
    hidden: product.hidden === 'False',
    categoryId: product.category_id,
    categoryName: product.category_name,
    subcategoryId: product.subcategory_id,
    subcategoryName: product.subcategory_name,
    marketCategoryId: product.market_category,
    marketCategoryName: product.market_category_name,
    marketSubcategoryId: product.market_sub_category,
    marketSubcategoryName: product.market_sub_category_name,
    currency: convertCurrency(product.market_currency_code),
    marketId: product.market_id,
    sellerId: product.seller_id,
    sellerName: product.seller_name,
    sellerImage: product.seller_image,
    sellerFIO: product.seller_fio,
    sellerNickname: product.seller_nickname,
    sellerOrganizationName: product.seller_organisation_name,
    sellerAvatar: product.seller_avatar,
    sellerInn: product.seller_inn,
    sellerAddress: product.seller_address,
    sellerEntity: product.seller_entity,
    tags: product.tags.map(convertProductTagFromApi),
    priceWithoutDiscount: apiPriceStringToClient(product.price_without_discount),
    added: product.added,
  }
}

export const convertProductOrderToProduct = (product: IProductOrder): IProduct => ({
  id: product.productId,
  name: product.name,
  description: '',
  price: product.price,
  unit: product.unit,
  pcsWeightGr: product.pcsWeightGr,
  step: 1,
  country: product.country,
  image: product.image,
  hidden: true,
  categoryId: '',
  categoryName: '',
  subcategoryId: product.subcategory,
  subcategoryName: '',
  marketCategoryId: product.marketCategoryId,
  marketSubcategoryId: product.marketSubcategoryId,
  marketSubcategoryName: product.marketSubcategoryName,
  currency: product.currency,
  marketId: '',
  sellerId: '',
  sellerName: product.sellerName,
  sellerImage: '',
  sellerFIO: '',
  sellerNickname: '',
  sellerOrganizationName: '',
  sellerAvatar: '',
  sellerEntity: '',
  sellerAddress: '',
  sellerInn: '',
  priceWithoutDiscount: 0,
  tags: [],
  added: false,
})

export const convertProductTagFromApi = (tag: IProductTagApi): IProductTag => ({
  id: tag.id,
  tag: tag.tag,
  color: tag.color,
})

export const convertMarketCategoriesFromApi = (category: IMarketCategoryApi): IMarketCategory => {
  return {
    id: category.id,
    marketId: category.market,
    name: category.name,
    index: category.priority,
    hidden: category.is_hide,
    subcategories: (category.market_sub_categories || []).map(convertMarketSubcategoryFromApi),
    imageUrl: category.image_url,
    numProducts: category.products_count,
  }
}

export const convertMarketSubcategoryFromApi = (subcategory: IMarketSubcategoryApi): IMarketSubcategory => {
  return {
    id: subcategory.id,
    marketId: subcategory.market,
    categoryId: subcategory.market_category,
    name: subcategory.name,
    index: subcategory.priority,
    hidden: subcategory.is_hide,
    imageUrl: subcategory.image_url,
    numProducts: subcategory.products_count,
  }
}

export const convertCategoriesFromApi = (category: ICategoryApi): ICategory => {
  return {
    id: category.id,
    name: category.name,
    priority: category.priority,
    subcategories: category.subcategories.map((subcategory) => ({
      id: subcategory.subcategory_id,
      name: subcategory.subcategory_name,
      priority: subcategory.subcategory_priority,
    })),
  }
}

export const round = (price?: number) => {
  if (price === undefined) {
    return 0
  }

  return +(+price).toFixed(2)
}

export const apiPriceStringToClient = (price = '0'): number => (isFinite(+price) ? +price : 0)

export const formatPrice = (price?: number | string, currency?: ICurrency) => {
  if (price === undefined) {
    return ''
  }

  const priceStr = new Intl.NumberFormat(getIntl()?.locale).format(round(+price))
  const currencyStr = currency?.symbol_native || ''
  const spaceStr = currency?.spaceStr || !currency ? ' ' : ''

  if (currency?.dirRight) {
    return `${priceStr} ${currencyStr}`
  }

  return `${currencyStr}${spaceStr}${priceStr}`
}

export const formatWeight = (weightGr: number, unit: string) => {
  return weightGr >= 1000
    ? `${+(weightGr / 1000).toFixed(3)} ${unit === UNIT_LITRE ? intlMessage(messages.Litre) : intlMessage(messages.Kg)}`
    : `${weightGr} ${unit === UNIT_LITRE ? intlMessage(messages.Millilitre) : intlMessage(messages.Gram)}`
}

export const unitIntl = (unit: string) => {
  switch (unit) {
    case UNIT_PCS:
      return intlMessage(messages.Pcs)
    case UNIT_KG:
      return intlMessage(messages.Kg)
    case UNIT_LITRE:
      return intlMessage(messages.Litre)
    default:
      return intlMessage(messages.Kg)
  }
}

export const checkDiscount = (product: IProduct | IProductOrder | IProductOutOfStock) =>
  product.priceWithoutDiscount > product.price

export const convertCurrency = (currencyCode?: string) => {
  if (!currencyCode) {
    return DEF_CURRENCY
  }

  const currency = CURRENCIES.find((item) => item.code === currencyCode)

  return currency || DEF_CURRENCY
}

export const shuffleArr = <T>(arr: T[] = [], limit?: number): T[] => {
  if (!limit) {
    return shuffle(arr)
  }

  return arr.length - 1 <= limit ? [...arr] : shuffle(arr).slice(0, limit)
}

export const saveProducts = (force: boolean | undefined, products: IProduct[], stateProducts: IProduct[] = []) =>
  force ? [...products] : uniqBy([...stateProducts, ...products], 'id')

export const trimName = (name = '', characters: number) =>
  name.length - 3 > characters ? [...name.split('').slice(0, characters), '...'].join('') : name
