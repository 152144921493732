import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
}

export const EmptyCardsIcon: React.FC<TProps> = ({ width = '100%', height = '100%' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 155 113' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill='#E8E8E8' d='M7 32h114v74H7z'/>
      <path fill='#E8E8E8' d='M113.323 25H17.677C7.93 25 0 32.996 0 42.824v52.352C0 105.004 7.93 113 17.677 113h95.646C123.07 113 131 105.004 131 95.176V42.824C131 32.996 123.07 25 113.323 25ZM17.677 35.85h95.646c3.814 0 6.917 3.128 6.917 6.974v4.219H10.76v-4.22c0-3.845 3.103-6.974 6.917-6.974Zm95.646 66.301H17.677c-3.814 0-6.917-3.13-6.917-6.975V57.892h109.48v37.284c0 3.846-3.103 6.975-6.917 6.975Z'/>
      <path fill='#fff' d='M55.562 74H22.44c-3.004 0-5.439 2.462-5.439 5.5s2.435 5.5 5.44 5.5h33.12c3.005 0 5.44-2.462 5.44-5.5s-2.435-5.5-5.44-5.5ZM106.602 55H22.398C19.416 55 17 57.462 17 60.5s2.416 5.5 5.398 5.5h84.204c2.981 0 5.398-2.462 5.398-5.5s-2.417-5.5-5.398-5.5Z'/>
      <path fill='#7B3B8F' d='M109.144.009c13.988-.397 23.967 13.056 21.589 24.632a33.559 33.559 0 0 1-6.435-1.912 16.98 16.98 0 0 1-9.92-10.34c-.933-2.577-1.737-5.234-2.798-7.717a46.572 46.572 0 0 0-2.332-4.337c.012-.093-.046-.175-.104-.326ZM133.088 28.977a18.565 18.565 0 0 1 6.995-15.003 17.998 17.998 0 0 1 14.234-3.987 25.464 25.464 0 0 0-3.358 5.328c-.851 1.714-1.69 3.427-2.588 5.117a15.027 15.027 0 0 1-10.969 7.904c-1.446.233-2.81.408-4.314.641Z'/>
    </svg>
  )
}
