import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const LockIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' d='M16.667 11.111h-8.89c-.981 0-1.777.796-1.777 1.778v5.333C6 19.204 6.796 20 7.778 20h8.889c.981 0 1.777-.796 1.777-1.778V12.89c0-.982-.796-1.778-1.777-1.778Z'/>
      <path stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' d='M12.222 16.444a.889.889 0 1 0 0-1.778.889.889 0 0 0 0 1.778Z'/>
      <path stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' d='M8.667 11.111V7.556a3.556 3.556 0 1 1 7.111 0v3.555'/>
    </svg>
  )
}
