import {
  EDeliveryInterval,
  EOrderStatus,
  ICard,
  IOrder,
  IProductOrder,
  ISlot,
} from './TClient'

export const SUCCESS_RESP = 'Success'

export enum EPaymentType {
  BY_CARD = 0,
  CASH = 1,
}

export enum EGetProductsType {
  SUBCATEGORY = 1,
  SELLER = 2,
  CATEGORIES = 3,
  TAG = 4,
  MARKET_CATEGORY = 5,
}

export interface IPaymentOptionApi {
  payment_id: EPaymentType,
  payment_name: string,
}

export interface IPaymentOptionsResp {
  records: IPaymentOptionApi[],
}

export interface IProductOrderApi {
  id: string,
  product_id: string,
  country: string,
  image_url: string,
  market: string,
  name: string,
  price: number,
  price_weight_gr?: number,
  quantity: number,
  quantity_initial?: number,
  seller_name: string,
  subcategory: string,
  market_category?: string,
  market_category_name?: string,
  market_sub_category?: string,
  market_sub_category_name?: string,
  currency_code?: string,
  unit: string,
  pcs_weight_gr: number,
  added: boolean,
}

export interface IGroupProductApi {
  group: string,
  id: number,
  market: string,
  priority: 0,
  product: IProductApi,
  root: boolean,
}
export interface IGroupApi {
  created_at: Date,
  disable: boolean,
  id: string,
  key: string,
  market: string,
  prod_rel: IGroupProductApi[],
}

export interface IProductRecommendationReq {
  market: string,
  product_ids: string[],
}
export type TProductRecommendationResp = IGroupApi[]

export interface ITransactionApi {
  id: string,
  Amount: string,
  Details: string,
  ErrorCode: string,
  Message: string,
  OrderId: string,
  PaymentId: string,
  PaymentURL: string,
  Status: string,
  Success: boolean,
  TerminalKey: string,
  CardId: string | null,
}

export interface IOrderApi {
  id: string,
  price: number,
  price_initial?: number,
  payment_type: number,
  is_payed: boolean,
  weight_kg: number,
  weight_gr: number,
  self_pickup: boolean,
  delivery_price: number,
  delivery_address: string,
  delivery_date: string,
  delivery_time: string,
  delivery_end_time?: string,
  market_address: string,
  market_id: string,
  market_name: string,
  market_open_hours_start: string,
  market_open_hours_end: string,
  created_at: Date,
  items: IProductOrderApi[],
  user_id: string,
  order_confirmed: boolean,
  status_id: number,
  status_name: string,
  completed_at: string,
  message?: string,
  updated_at?: string,
  transaction?: ITransactionApi,
  review?: IReviewOrder,
  is_ext_paid?: boolean,
  ext_transaction?: ITransactionApi,
}

export interface IReviewOrder {
  assessment: number,
  message: string,
}

export interface IReviewOrderReq {
  assessment: number,
  order: string,
  message?: string,
}

export interface ApiOrdersReq {
  id?: string,
  offset?: number,
  status?: EOrderStatus[],
}

export interface IProductInCart {
  productId: string,
  quantity: number,
}

export type ICreateOrderDeliveryReq = {
  marketId: string,
  address: string,
  payment_type: number,
  delivery_price: number,
  self_pickup: string,
  delivery_date: string,
  delivery_end_date?: string,
  delivery_time: string,
  delivery_end_time: string,
  items: IProductInCart[],
  message?: string,
  slot?: string,
  delivery_type?: EDeliveryInterval,
  lat?: string,
  lon?: string,
  card_id?: string,
  web?: boolean,
}

export type ICreateOrderPickupReq = {
  marketId: string,
  address: string,
  payment_type: number,
  self_pickup: string,
  items: IProductInCart[],
  message?: string,
  card_id?: string,
  web?: boolean,
}

export type ICreateOrderReq = ICreateOrderDeliveryReq | ICreateOrderPickupReq

export interface IRepeatOrder {
  orderProducts: IProductOrder[],
  quantityMap: Map<string, number>,
  orderId: string,
}

export interface IRepeatOrderResp {
  records: IProductApi[],
}

export interface ICancelOrderReq {
  order_id: string,
}

export interface IUpdateOrderReq {
  id: string,
  slot?: string,
  delivery_type?: EDeliveryInterval,
  delivery_price?: number,
  payment_type?: number,
  self_pickup?: boolean,
  market_address?: string,
  delivery_address?: string,
  delivery_time?: string,
  delivery_end_time?: string,
  delivery_end_date?: string,
  delivery_date?: string,
  message?: string,
  lat?: string,
  lon?: string,
  card_id?: string,
}

export type ICancelOrderResp = IOrderApi

export interface ICreateTransactionReq {
  order_id: string,
  card_id?: string,
  open?: boolean,
}

export enum EPayByCardStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export interface IPayByCardReq {
  transactionId: string,
  order: IOrder,
  card: ICard,
}

export interface IPayByCardResp {
  status: EPayByCardStatus,
}

export type ApiAuthByPhoneReq = {
  phone: string,
}
export type ApiAuthByPhoneResp = {
  Token?: string,
}
export type ApiConfirmAuthReq = {
  phone: string,
  veriff_code: string,
}
export type ApiConfirmAuthResp = {
  Token?: string,
}

export interface IConfirmCodeResp {
  Token: string,
  user: string,
}

export type ApiProfileResp = IProfileApi

export interface IProfileApi {
  client_id: string,
  first_name: string,
  last_name: string,
  email: string,
  avatar_url: string | null,
  addresses: IAddressApi[],
  cards: IApiCard[],
  call_me?: boolean,
  call_about_change_product?: boolean,
  call_about_delete_product?: boolean,
  call_about_change_product_weight?: boolean,
  call_change_product?: boolean,
  call_delete_product_by_weight?: boolean,
}

export interface IAddressApi {
  id: string,
  user: number,
  city: string,
  street: string,
  building: string,
  apartment?: string,
  door_phone?: string,
  entrance?: string,
  floor?: string,
  lat?: string,
  lon?: string,
}

export interface IAddAddressReq {
  city: string,
  street: string,
  building: string,
  apartment?: string,
  door_phone?: string,
  floor?: string,
  entrance?: string,
  lat?: string,
  lon?: string,
}

export interface IAddAddressResp {
  address_id: string,
}

export interface IUpdateAddressReq extends IAddAddressReq {
  address_id: string,
}

export interface IRemoveAddressReq {
  address_id: string,
}

export interface DadataAddress {
  area: string,
  area_fias_id: string,
  area_kladr_id: string,
  area_type: string,
  area_type_full: string,
  area_with_type: string,
  beltway_distance: null,
  beltway_hit: null,
  block: string,
  block_type: string,
  block_type_full: string,
  capital_marker: '0' | '1' | '2' | '3' | '4',
  city: string,
  city_area: string,
  city_district: string,
  city_district_fias_id: string,
  city_district_kladr_id: string,
  city_district_type: string,
  city_district_type_full: string,
  city_district_with_type: string,
  city_fias_id: string,
  city_kladr_id: string,
  city_type: string,
  city_type_full: string,
  city_with_type: string,
  country: string,
  fias_id: string,
  fias_level: string,
  flat: string,
  flat_area: null,
  flat_price: null,
  flat_type: string,
  flat_type_full: string,
  geo_lat: string,
  geo_lon: string,
  history_values: string,
  house: string,
  house_fias_id: string,
  house_kladr_id: string,
  house_type: string,
  house_type_full: string,
  kladr_id: string,
  okato: string,
  oktmo: string,
  postal_box: string,
  postal_code: string,
  qc: null,
  qc_complete: null,
  qc_geo: '0' | '1' | '2' | '3' | '4' | '5',
  qc_house: null,
  region: string,
  region_fias_id: string,
  region_kladr_id: string,
  region_type: string,
  region_type_full: string,
  region_with_type: string,
  settlement: string,
  settlement_fias_id: string,
  settlement_kladr_id: string,
  settlement_type: string,
  settlement_type_full: string,
  settlement_with_type: string,
  source: string,
  square_meter_price: null,
  street: string,
  street_fias_id: string,
  street_kladr_id: string,
  street_type: string,
  street_type_full: string,
  street_with_type: string,
  tax_office: string,
  tax_office_legal: string,
  fias_code: string,
  timezone: null,
  unparsed_parts: null,
}

export interface DadataSuggestion {
  value: string,
  unrestricted_value: string,
  data: DadataAddress,
}

export type DadataResp = {
  suggestions: DadataSuggestion[],
}

export enum EGMapsSuggestionType {
  ROUTE = 'route',
  DECODE = 'decode',
}

export interface IGMapsSuggestionTerm {
  offset: number,
  value: string,
}

export interface IGMapsSuggestion {
  description: string,
  matched_substrings: [
    {
      length: number,
      offset: number,
    }
  ],
  place_id: string,
  reference: string,
  structured_formatting: {
    main_text: string,
    main_text_matched_substrings: [
      {
        length: number,
        offset: number,
      }
    ],
    secondary_text: string,
  },
  terms: IGMapsSuggestionTerm[],
  types: EGMapsSuggestionType[],
}

export type GMapSuggestResp = IGMapsSuggestion[]

export interface IGMapPoint {
  lat: number,
  lng: number,
}

export enum EGMapGeocodeType {
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
  POLITICAL = 'political',
  SUB_LOCALITY = 'sublocality',
  SUB_LOCALITY_1 = 'sublocality_level_1',
  AREA_LEVEL_1 = 'administrative_area_level_1',
  AREA_LEVEL_2 = 'administrative_area_level_2',
  COUNTRY = 'country',
  POSTAL_CODE = 'postal_code',
}

export interface IGMapGeocode {
  address_components:  {
    long_name: string,
    short_name: string,
    types: EGMapGeocodeType[],
  }[],
  formatted_address: string,
  geometry: {
    bounds: {
      northeast: IGMapPoint,
      southwest: IGMapPoint,
    },
    location: IGMapPoint,
    location_type: string,
    viewport: {
      northeast: IGMapPoint,
      southwest: IGMapPoint,
    },
  },
  place_id: string,
  types: string[],
}

export type GMapGeocodeResp = IGMapGeocode[]

export interface IApiCard {
  id: string,
  card_id: string,
  pan: string,
  exp_date: string,
  created_at: string,
}

export interface ApiUpdateProfileReq {
  first_name?: string,
  last_name?: string,
  email?: string,
  avatar_url?: string,
  call_me?: boolean,
  call_about_change_product?: boolean,
  call_about_delete_product?: boolean,
  call_about_change_product_weight?: boolean,
  call_change_product?: boolean,
  call_delete_product_by_weight?: boolean,
}
export interface IUpdateProfileResp {
  detail: string,
}

export type ApiPaymentOptionsResp = {
  records: IPaymentOptionApi[],
}

export type ApiOrdersListResp = IOrderApi[]

export type ApiCity = {
  id: string,
  name: string,
}
export type ApiCitiesResp = {
  records: ApiCity[],
}

export interface IMarketApi {
  id: string,
  name: string,
  city: string,
  city_id: string,
  address: string,
  self_pickup_start_time: string,
  self_pickup_end_time: string,
  open_hours_start: string,
  open_hours_end: string,
  delivery_end_time: string,
  delivery_start_time: string,
  short_name?: string,
  delivery_info?: string,
  image_url?: string,
  price_segment?: number,
  currency_code?: string,
}
export type ApiMarketsReq = {
  market?: string,
  city?: string,
  seller?: string,
  include_all?: string,
  latitude?: number,
  longitude?: number,
}
export type ApiMarketsResp = {
  records: IMarketApi[],
}

export interface IMarketSubcategoryApi {
  id: string,
  market_category: string,
  market: string,
  name: string,
  priority: number,
  is_hide: boolean,
  image_url?: string,
  products_count?: number,
}

export interface IMarketCategoryApi {
  id: string,
  market: string,
  name: string,
  priority: number,
  is_hide: boolean,
  market_sub_categories: IMarketSubcategoryApi[],
  products_count?: number,
  image_url?: string,
}

export type ApiMarketCategoriesReq = {
  market?: string,
  with_subcategories?: string,
}
export type ApiMarketCategoriesResp = IMarketCategoryApi[]

export interface ISellerCategoryApi {
  id: string,
  name: string,
}

export interface ISellerApi {
  id: string,
  userId: number,
  isHide: boolean,
  isSystem: boolean,
  isCommon: boolean,
  commission: number,
  name: string,
  nickname: string,
  organization: string,
  location: string,
  imageUrl: string,
  avatarUrl: string,
  categories: ISellerCategoryApi[],
  aboutSellers?: string[],
  cityId: string,
  cityName: string,
  marketId: string,
  marketAddress: string,
  marketName: string,
  marketOpeningHours?: string,
  marketClosingHours?: string,
}

export interface ISellerSearchApi {
  id: string,
  isSystem: boolean,
  isCommon: boolean,
  isHide: boolean,
  name: string,
  nickname: string,
  location: string,
  marketId: string,
  marketName: string,
  organization: string,
}

export interface IProductTagApi {
  id: number,
  tag: string,
  color?: string,
}

export interface IProductApi {
  id: string,
  name: string,
  description: string,
  price: number,
  price_weight_gr?: number,
  unit: string,
  pcs_weight_gr: number,
  step: number,
  country: string,
  image: string,
  hidden: string,
  category_id: string,
  category_name: string,
  subcategory_id: string,
  subcategory_name: string,
  market_category?: string,
  market_category_name?: string,
  market_sub_category?: string,
  market_sub_category_name?: string,
  market_id: string,
  market_name: string,
  market_currency_code?: string,
  seller_id: string,
  seller_name: string,
  seller_image: string,
  seller_fio: string,
  seller_nickname: string,
  seller_organisation_name: string,
  seller_avatar: string,
  price_without_discount: string,
  seller_inn?: string,
  seller_address?: string,
  seller_entity?: string,
  tags: IProductTagApi[],
  added?: boolean,
}

export type ApiMarketProductsReq = {
  offset?: number,
  limit?: number,
  search?: string,
  product?: string,
  order?: string,
  seller?: string,
  market?: string,
  category?: string,
  market_category?: string,
  market_sub_category?: string,
  tags?: number,
  subcategory?: string,
  include_all?: string,
  with_empty?: string,
  hidden?: string,
  by_categories?: boolean,
  fromSearch?: boolean,
  discount?: boolean,
}
export type ApiMarketProductsResp = {
  records: IProductApi[],
}

export interface ApiProductOutOfStockReq {
  products: string[],
  toDelivery?: boolean,
}
export interface ApiProductOutOfStockResp {
  id: string,
}

export interface ISubcategoryApi {
  subcategory_id: string,
  subcategory_name: string,
  subcategory_priority: number,
}

export interface ICategoryApi {
  id: string,
  name: string,
  priority: number,
  subcategories: ISubcategoryApi[],
}

export type TPoint = {
  lat: string,
  lon: string,
}

export interface ISlotType {
  delivery_interval: EDeliveryInterval,
  delivery_price: number,
}

export interface IDeliveryInfoReq {
  isToday: boolean,
  orderId?: string,
  orderItems?: { productId: string, quantity: number }[],
  address?: string,
  addressId?: string,
  fromComponent?: boolean,
  lat?: string,
  lon?: string,
}
export type IDeliveryInfoResp = {
  min_order_price: number,
  slots: ISlot[],
  type: ISlotType[],
  point: TPoint,
}

export type IDeliveryInfoErrorResp = {
  status: string,
  detail: string,
}

export interface ITagApi {
  id: number,
  tag: string,
  market: string,
  position: number,
  color: string,
  products_count: number,
  image_url?: string,
}
