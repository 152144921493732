import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const TelegramIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 13 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M1.097 4.663a535.968 535.968 0 0 1 6.616-2.889C10.863.446 11.518.215 11.944.208c.094-.002.304.022.44.133a.487.487 0 0 1 .162.312c.015.09.034.293.019.453-.171 1.818-.91 6.23-1.286 8.267-.159.862-.472 1.151-.775 1.18-.659.06-1.159-.442-1.797-.866-.998-.663-1.563-1.076-2.532-1.724-1.12-.748-.394-1.16.245-1.831.167-.176 3.07-2.853 3.125-3.095.007-.03.014-.144-.052-.204-.067-.06-.165-.039-.235-.023-.1.023-1.695 1.092-4.785 3.206-.453.316-.863.47-1.23.461-.406-.009-1.185-.232-1.764-.423C.769 5.82.204 5.696.253 5.3c.026-.207.307-.42.844-.636Z'/>
    </svg>
  )
}
