import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { IMarket, IProduct, ITag } from '../../../types/TClient'
import { ApiMarketProductsReq } from '../../../types/TApi'

import { State } from '../../../store/reducer'
import { dispatch } from '../../../index'
import * as Actions from '../../../store/actions'
import { Action } from '../../../store/actions'
import { ProductsPageCmp } from '../../../components/ProductsPageCmp'
import { LOCATION_MAIN } from '../../../utils/locationUtils'

const MARKET_PRODUCTS_PAGE_LIMIT = 25

interface IConnectedProps {
  products: IProduct[],
  loading: boolean,
  tagId: number,
  market?: IMarket,
  tags: ITag[],
}

interface IDispatchedProps {
  getProducts: (data: ApiMarketProductsReq) => Action,
  resetProducts(): Action,
}

type RouterProps = {
  tag?: string,
}

type TProps = WrappedComponentProps & IConnectedProps & IDispatchedProps & RouteComponentProps<RouterProps>

class TagPage extends React.Component<TProps> {

  componentDidMount() {
    const { tagId, history, tags } = this.props
    this.props.resetProducts()

    if (!tagId || !tags.some((tag) => tag.id === tagId)) {
      history.push(LOCATION_MAIN)
    }
  }

  render() {
    const { products, loading, market } = this.props

    return (
      <ProductsPageCmp
        title={this.tagName}
        products={products}
        loading={loading}
        market={market}
        getProducts={this.getProducts}
        limit={MARKET_PRODUCTS_PAGE_LIMIT}
      />
    )
  }

  get tagName() {
    const { tags, tagId } = this.props

    return tags.find((tag) => tag.id === tagId)?.tag || ''
  }

  getProducts = (offset?: number, limit?: number) => {
    const { market, match } = this.props
    const tag = +(match.params.tag || 0)

    if (market && tag) {
      this.props.getProducts({
        limit: limit || MARKET_PRODUCTS_PAGE_LIMIT,
        offset: offset || 0,
        market: market.id,
        tags: tag
      })
    }
  }
}

const dispatchToProps: IDispatchedProps = {
  getProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
  resetProducts: (): Action => dispatch(Actions.action(Actions.MARKET_PRODUCTS, { products: [], force: true })),
}

const stateToProps = (s: State, own: TProps): IConnectedProps => ({
  products: s.products.products || [],
  market: s.market.market,
  loading: s.products.loading,
  tags: s.products.tags || [],
  tagId: +(own.match.params.tag || 0)
})

export default connect(stateToProps, dispatchToProps)(injectIntl(TagPage))
