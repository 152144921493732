import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import messages from '../../localization/messages'
import { BackIcon } from '../Icons'
import { COLORS } from '../../style/colors'

type TOwnProps = {
  title: string,
  back?: () => void,
  action?: React.ReactNode,
}

type TProps = TOwnProps & WrappedComponentProps

class SubHeader extends React.Component<TProps> {
  render() {
    const { title, action, back } = this.props
    const { formatMessage } = this.props.intl

    return (
      <div className='sub-header'>
        {
          back && (
            <div className='sub-header__action' onClick={back}>
              <div className='sub-header__action-icon'>
                <BackIcon color={COLORS.WHITE}/>
              </div>
              {formatMessage(messages.Back)}
            </div>
          )
        }
        <div className='sub-header__title'>
          {title.toUpperCase()}
        </div>
        {
          action && (
            <div className='sub-header__sub-action'>
              {action}
            </div>
          )
        }
      </div>
    )
  }
}

export default injectIntl(SubHeader)
