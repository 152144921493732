import { combineEpics } from 'redux-observable'

import { customerEpics } from './customerEpics'
import { marketEpics } from './marketEpics'
import { productsEpics } from './productsEpics'
import { cartEpics } from './cartEpics'
import { ordersEpics } from './ordersEpics'

const epics = combineEpics(
  ...customerEpics,
  ...marketEpics,
  ...productsEpics,
  ...cartEpics,
  ...ordersEpics,
)

export default epics
