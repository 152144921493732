import React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { IMarket, IProduct } from '../../../types/TClient'
import { ApiMarketProductsReq } from '../../../types/TApi'

import { State } from '../../../store/reducer'
import { dispatch } from '../../../index'
import messages from '../../../localization/messages'
import * as Actions from '../../../store/actions'
import { Action } from '../../../store/actions'
import { ProductsPageCmp } from '../../../components/ProductsPageCmp'

const MARKET_PRODUCTS_PAGE_LIMIT = 25

interface IConnectedProps {
  promoProducts: IProduct[],
  loading: boolean,
  market?: IMarket,
}

interface IDispatchedProps {
  getProducts: (data: ApiMarketProductsReq) => Action,
}

type RouterProps = {
  id?: string,
}

type TProps = WrappedComponentProps & IConnectedProps & IDispatchedProps & RouteComponentProps<RouterProps>

class PromoPage extends React.Component<TProps> {

  render() {
    const { formatMessage } = this.props.intl
    const { promoProducts, loading, market}  = this.props

    return (
      <ProductsPageCmp
        title={formatMessage(messages.Promo)}
        products={promoProducts}
        loading={loading}
        market={market}
        getProducts={this.getProducts}
        limit={MARKET_PRODUCTS_PAGE_LIMIT}
      />
    )
  }

  getProducts = (offset?: number, limit?: number) => {
    const { market,  } = this.props

    if (market) {
      this.props.getProducts({
        limit: limit || MARKET_PRODUCTS_PAGE_LIMIT,
        offset: offset || 0,
        market: market.id,
        discount: true,
      })
    }
  }
}

const dispatchToProps: IDispatchedProps = {
  getProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
}

const stateToProps = (s: State): IConnectedProps => ({
  promoProducts: s.products.promoProducts || [],
  market: s.market.market,
  loading: s.products.loading,
})

export default connect(stateToProps, dispatchToProps)(injectIntl(PromoPage))
