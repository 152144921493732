import { concat, EMPTY, of as observableOf } from 'rxjs'
import { catchError, debounceTime, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'
import { AxiosResponse } from 'axios'

import * as TApi from '../../types/TApi'
import * as TClient from '../../types/TClient'

import messages from '../../localization/messages'
import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import {
  URL_CREATE_TRANSACTION,
  URL_ORDER_CANCEL,
  URL_ORDER_CREATE,
  URL_ORDER_PAY,
  URL_ORDER_REVIEW,
  URL_ORDERS_LIST,
  URL_PRODUCTS_CHECK,
} from '../../modules/network/urls'
import { EModalType } from '../reducers/modals'
import { intlMessage } from '../../App/LangContainer'
import { authRequestConfig, checkNotAuth } from '../../utils/requestUtils'
import { convertOrderFromApi, convertTransactionFromApi, ORDERS_LIMIT } from '../../utils/ordersUtils'
import { convertProductCartToOutOfStock } from '../../utils/cartUtils'
import { convertProductFromApi } from '../../utils/productsUtils'
import { historyPush, LOCATION_CART, LOCATION_ORDERS } from '../../utils/locationUtils'
import { FirebaseAnalyticsService } from '../../utils/analyticsUtils'

const getOrdersEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiOrdersList>(a$, Actions.API_ORDERS_LIST), (b) =>
    b.pipe(
      debounceTime(200),
      mergeMap((c) =>
        Axios.get(URL_ORDERS_LIST + (c.data && c.data.id ? `${c.data.id}/` : ''), {
          ...authRequestConfig(),
          params: {
            limit: ORDERS_LIMIT,
            ...(c.data && c.data.status && { status: c.data.status.join(',') }),
            ...(c.data && c.data.offset !== undefined && { offset: c.data.offset }),
          },
        }).pipe(
          mergeMap((resp: { data: TApi.ApiOrdersListResp }) => {
            if (!resp.data) {
              return observableOf<Actions.Action>(Actions.actionEmpty(Actions.ORDERS_LIST_ERROR))
            }

            if (resp.data && Array.isArray(resp.data)) {
              const mapOrders = resp.data.map(convertOrderFromApi)

              if (c.data && c.data.offset !== undefined) {
                return observableOf<Actions.Action>(Actions.action(Actions.ORDERS, mapOrders))
              } else {
                return observableOf<Actions.Action>(Actions.action(Actions.ORDERS_LIST, {
                  orders: mapOrders,
                  status: c.data && c.data.status,
                }))
              }
            }

            return observableOf<Actions.Action>(Actions.action(Actions.ORDERS_LIST, {
              orders: convertOrderFromApi(resp.data),
              status: c.data && c.data.status,
            }))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.ORDERS_LIST_ERROR))
          }),
        ),
      ),
    ),
  )

const createOrderEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiCreateOrder>(a$, Actions.API_CREATE_ORDER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_ORDER_CREATE, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: AxiosResponse<TApi.IOrderApi>) => {
            const actions: Actions.Action[] = []

            if (resp.statusText) {
              const outOfStock = (resp.statusText as any).products

              if (Array.isArray(outOfStock)) {
                actions.push(Actions.action(Actions.CREATE_ORDER, null))

                if (outOfStock && typeof outOfStock[0] === 'string') {
                  const products: TClient.IProductCart[] = store.value.cart.products || []
                  const productOutOfStock = products.filter((product) => outOfStock.find((item) => product.id === item))

                  actions.push(Actions.action(Actions.PRODUCTS_OUT_OF_STOCK, productOutOfStock.map(convertProductCartToOutOfStock)))
                } else {
                  actions.push(Actions.action(Actions.PRODUCTS_OUT_OF_STOCK, outOfStock))
                }

                return observableOf<Actions.Action>(...actions)
              }
            }

            if (resp.data) {
              // NavigationService.reset(SCREENS_NAMES.ORDERS)
              const productsCart = store.value.cart.products

              historyPush(LOCATION_ORDERS)

              if (productsCart) {
                FirebaseAnalyticsService.purchase(resp.data.id, productsCart)
              }

              return concat(
                observableOf<Actions.Action>(Actions.action(Actions.CREATE_ORDER, convertOrderFromApi(resp.data))),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.RESET_CART)),
                observableOf<Actions.Action>(Actions.actionEmpty(Actions.RESET_CART)).pipe(
                  mergeMap(() => {
                    // NavigationService.navigate(SCREENS_NAMES.ORDER, { id: response.data.id })

                    return EMPTY
                  }),
                ),
              )
            }
            // else {
            //   AlertMessage.createOrderByAddress()
            // }

            return observableOf<Actions.Action>(Actions.action(Actions.CREATE_ORDER, null))
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.CREATE_ORDER, null))
          }),
        ),
      ),
    ),
  )

const repeatOrderEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiRepeatOrder>(a$, Actions.API_REPEAT_ORDER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_PRODUCTS_CHECK, {
          ...authRequestConfig(),
          params: {
            order: c.data.orderId,
          },
        }).pipe(
          mergeMap((resp: AxiosResponse<TApi.IRepeatOrderResp>) => {
            const activeProducts = resp.data.records.map(convertProductFromApi).filter((p) => p.hidden)
            const stateCartProducts = new Map<string, TClient.IProductCart>()
            const products: TClient.IProductCart[] = store.value.cart.products || []

            products.forEach((p) => stateCartProducts.set(p.id, p))

            const actions: Actions.Action[] = [Actions.action(Actions.REPEAT_ORDER, true)]

            if (!activeProducts.length) {
              return observableOf<Actions.Action>(Actions.action(Actions.REPEAT_ORDER, false))
            }

            activeProducts.forEach((product) => {
              const quantity = c.data.quantityMap.get(product.id)
              const productInCart = stateCartProducts.get(product.id)

              if (!quantity) {
                return
              }

              const actionPayload = {
                ...product,
                quantity: productInCart ? productInCart.quantity + quantity : quantity,
              }

              if (productInCart) {
                actions.push(Actions.action(Actions.UPDATE_CART, actionPayload))
              } else {
                actions.push(Actions.action(Actions.ADD_TO_CART, actionPayload))
              }
            })

            if (resp.data.records.length < c.data.orderProducts.length) {
              const outOfStock: TClient.IProductOrder[] = []

              c.data.orderProducts.forEach((product) => {
                const isActive = activeProducts.find((item) => item.id === product.productId)

                if (!isActive) {
                  outOfStock.push(product)
                }
              })

              actions.push(Actions.action(Actions.PRODUCTS_OUT_OF_STOCK, outOfStock))
            }

            historyPush(LOCATION_CART)

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.action(Actions.REPEAT_ORDER, false))
          }),
        ),
      ),
    ),
  )

const cancelOrderEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCancelOrder>(a$, Actions.API_CANCEL_ORDER), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_ORDER_CANCEL, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.ICancelOrderResp }) => {
            const actions: Actions.Action[] = []

            if (resp.data && resp.data.status_name) {
              actions.push(Actions.action(Actions.UPDATE_ORDER, {
                id: c.data.order_id,
                statusId: resp.data.status_id,
                statusName: resp.data.status_name,
                completedAt: new Date(),
              }))
              actions.push(Actions.action(Actions.MODAL_PUSH, {
                type: EModalType.MODAL_INFO,
                size: 'mini',
                style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
                props: {
                  title: intlMessage(messages.OrderCancelled),
                  text: intlMessage(messages.ReturningMoneyInfo),
                  btnText: intlMessage(messages.ItsPity),
                },
              }))
            }

            actions.push(Actions.actionEmpty(Actions.CANCEL_ORDER))

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return observableOf<Actions.Action>(Actions.actionEmpty(Actions.CANCEL_ORDER))
          }),
        ),
      ),
    ),
  )

const createTransactionEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiCreateTransaction>(a$, Actions.API_CREATE_TRANSACTION), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_CREATE_TRANSACTION, {
          order_id: c.data.order_id,
          card_id: c.data.card_id,
        }, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: { data: TApi.IOrderApi }) => {
            if (resp.data && resp.data.transaction) {
              const clientTransaction = convertTransactionFromApi(resp.data.transaction)

              if (c.data.open && clientTransaction.paymentURL) {
                // let a = document.createElement('a');
                // a.target = '_blank';
                // a.href = clientTransaction.paymentURL;
                // a.click();
                window.open(clientTransaction.paymentURL, '_blank')
              }

              return observableOf<Actions.Action>(Actions.action(Actions.UPDATE_ORDER, {
                id: resp.data.id,
                transaction: clientTransaction,
              }))
            }

            return EMPTY
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const orderReviewEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiOrderReview>(a$, Actions.API_ORDER_REVIEW), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_ORDER_REVIEW, c.data, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((_resp: AxiosResponse<TApi.IOrderApi>) => {
            const actions: Actions.Action[] = [
              Actions.action(Actions.API_ORDERS_LIST, { id: c.data.order })
            ]

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)
            return EMPTY
          }),
        ),
      ),
    ),
  )

const payByCardEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.ApiPayByCard>(a$, Actions.API_PAY_BY_CARD), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.post(URL_ORDER_PAY, {
          transaction_id: c.data.transactionId,
        }, {
          ...authRequestConfig(),
        }).pipe(
          mergeMap((resp: AxiosResponse<TApi.IPayByCardResp>) => {
            const data = resp.data
            const actions: Actions.Action[] = [
              Actions.action(Actions.API_ORDERS_LIST, { id: c.data.order.id })
            ]

            if (data.status === TApi.EPayByCardStatus.SUCCESS) {
              actions.push(Actions.action(Actions.PAY_BY_CARD, { orderId: c.data.order.id, success: true }))
              actions.push(Actions.action(Actions.MODAL_PUSH, {
                type: EModalType.MODAL_PAY,
                size: 'mini',
                style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
                props: {
                  order: c.data.order,
                  success: true,
                  card: c.data.card,
                },
              }))
            }

            return observableOf<Actions.Action>(...actions)
          }),
          catchError((err) => {
            checkNotAuth(err)

            const actions: Actions.Action[] = [
              Actions.action(Actions.API_ORDERS_LIST, { id: c.data.order.id })
            ]

            actions.push(Actions.action(Actions.PAY_BY_CARD, { orderId: c.data.order.id, success: false }))
            actions.push(Actions.action(Actions.MODAL_PUSH, {
              type: EModalType.MODAL_PAY,
              size: 'mini',
              style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
              props: {
                order: c.data.order,
                success: false,
                card: c.data.card,
              },
            }))

            return observableOf<Actions.Action>(...actions)
          }),
        ),
      ),
    ),
  )

export const ordersEpics: EpicFunc[] = [
  getOrdersEpic,
  createOrderEpic,
  repeatOrderEpic,
  cancelOrderEpic,
  createTransactionEpic,
  payByCardEpic,
  orderReviewEpic,
]
