import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const MinusIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M5 12c0-.483.392-.875.875-.875h12.25a.875.875 0 0 1 0 1.75H5.875A.875.875 0 0 1 5 12Z'/>
    </svg>
  )
}
