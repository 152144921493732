import React from 'react'

type TProps = {
  width?: number,
  height?: number,
  color?: string,
}

export const VKIcon: React.FC<TProps> = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M20.0802 7.46862H17.1953C16.9554 7.46862 16.7349 7.6081 16.6215 7.83163C16.6215 7.83163 15.4709 10.0724 15.1013 10.8271C14.107 12.8574 13.4632 12.22 13.4632 11.2777V8.02874C13.4632 7.46338 13.0298 7.00507 12.4951 7.00507H10.3261C9.72548 6.95785 9.14459 7.24288 8.79091 7.75834C8.79091 7.75834 9.89232 7.57002 9.89232 9.13932C9.89232 9.52846 9.91127 10.6479 9.92854 11.5868C9.93521 11.8703 9.77401 12.128 9.52477 12.2324C9.27552 12.3368 8.99114 12.2657 8.81246 12.0544C7.92464 10.7487 7.18923 9.3343 6.62221 7.84205C6.52277 7.6141 6.3069 7.46825 6.0695 7.46862C5.44387 7.46862 4.19191 7.46862 3.44841 7.46862C3.30206 7.46757 3.16456 7.54261 3.08059 7.66937C2.99663 7.79613 2.97691 7.95842 3.02785 8.1035C3.82374 10.412 7.25218 17.6667 11.1654 17.6667H12.8125C13.1718 17.6667 13.4632 17.3586 13.4632 16.9786V15.9269C13.4632 15.6573 13.6147 15.4134 13.8487 15.3063C14.0828 15.1993 14.3546 15.2495 14.5404 15.4341L16.5118 17.3928C16.6889 17.5687 16.9227 17.6667 17.1656 17.6667H19.7544C21.003 17.6667 21.003 16.7505 20.3223 16.0415C19.8432 15.5426 18.1144 13.6157 18.1144 13.6157C17.8085 13.2806 17.7793 12.7597 18.0457 12.3887C18.6045 11.6111 19.5185 10.3382 19.9061 9.79291C20.4359 9.04758 21.3949 7.46862 20.0802 7.46862Z'/>
    </svg>
  )
}
