import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const BrazilFlagIcon: React.FC<TProps> = () => {
  return (
    <div style={{ overflow: 'hidden', borderRadius: 4, display: 'flex', alignItems: 'center' }}>
      <svg
        width={26}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="a"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={26}
          height={20}
        >
          <path fill="#fff" d="M0 0h26v20H0z" />
        </mask>
        <g mask="url(#a)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0v20h26V0H0Z"
            fill="#093"
          />
          <mask
            id="b"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={26}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0v20h26V0H0Z"
              fill="#fff"
            />
          </mask>
          <g mask="url(#b)">
            <g filter="url(#c)" fillRule="evenodd" clipRule="evenodd">
              <path
                d="m12.94 3.087 9.915 7.086-10.05 6.636-9.71-6.773 9.845-6.95Z"
                fill="#FFD221"
              />
              <path
                d="m12.94 3.087 9.915 7.086-10.05 6.636-9.71-6.773 9.845-6.95Z"
                fill="url(#d)"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13 14.333c2.244 0 4.063-1.865 4.063-4.166C17.063 7.865 15.242 6 13 6c-2.244 0-4.063 1.865-4.063 4.167 0 2.3 1.82 4.166 4.063 4.166Z"
              fill="#2E42A5"
            />
            <mask
              id="e"
              style={{
                maskType: 'alpha',
              }}
              maskUnits="userSpaceOnUse"
              x={8}
              y={6}
              width={10}
              height={9}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 14.333c2.244 0 4.063-1.865 4.063-4.166C17.063 7.865 15.242 6 13 6c-2.244 0-4.063 1.865-4.063 4.167 0 2.3 1.82 4.166 4.063 4.166Z"
                fill="#fff"
              />
            </mask>
            <g mask="url(#e)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m11.684 12.142-.182.098.035-.208-.147-.146.203-.03.09-.19.091.19.203.03-.146.146.034.208-.181-.098ZM13.309 12.142l-.182.098.035-.208-.147-.146.203-.03.09-.19.091.19.203.03-.146.146.034.208-.181-.098ZM13.309 13.142l-.182.098.035-.208-.147-.146.203-.03.09-.19.091.19.203.03-.146.146.034.208-.181-.098ZM12.496 9.642l-.181.098.034-.208-.146-.146.203-.03.09-.19.091.19.203.03-.147.146.035.208-.182-.098ZM12.496 11.308l-.181.098.034-.207-.146-.147.203-.03.09-.189.091.189.203.03-.147.147.035.207-.182-.098ZM11.359 10.475l-.182.098.035-.207-.147-.147.203-.03.09-.189.091.189.203.03-.146.147.034.207-.181-.098ZM10.221 11.142l-.181.098.034-.208-.146-.146.202-.03.091-.19.09.19.204.03-.147.146.035.208-.182-.098ZM13.959 8.308l-.182.098.035-.207-.147-.147.203-.03.09-.189.091.189.203.03-.146.147.034.207-.181-.098Z"
                fill="#F7FCFF"
              />
              <path
                d="m8.064 9.164.122-1.662c3.898.302 6.975 1.617 9.192 3.955l-1.165 1.162c-1.921-2.027-4.625-3.182-8.15-3.455Z"
                fill="#F7FCFF"
              />
              <path
                d="m9.985 8.836.042-.415c2.513.267 4.669 1.254 6.46 2.96l-.276.305c-1.727-1.643-3.8-2.593-6.226-2.85Z"
                fill="#093"
              />
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="d"
            x1={26}
            y1={20}
            x2={26}
            y2={0}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC600" />
            <stop offset={1} stopColor="#FFDE42" />
          </linearGradient>
          <filter
            id="c"
            x={3.095}
            y={3.087}
            width={19.76}
            height={13.723}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feColorMatrix values="0 0 0 0 0.0313726 0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0.28 0" />
            <feBlend
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1474_31728"
            />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow_1474_31728"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}
