import { combineReducers, Reducer } from 'redux'
import moment from 'moment'

import * as Actions from './actions'
import { customer, StateCustomer } from './reducers/customer'
import { market, StateMarket } from './reducers/market'
import { orders, StateOrders } from './reducers/orders'
// import { sellers, StateSellers } from './reducers/sellers'
import { products, StateProducts } from './reducers/products'
import { cart, StateCart } from './reducers/cart'
import { modals, StateModals } from './reducers/modals'
import { checkLang } from '../utils/langUtils'

export type State = Readonly<{
  modals: StateModals,
  customer: StateCustomer,
  market: StateMarket,
  orders: StateOrders,
  // sellers: StateSellers,
  products: StateProducts,
  cart: StateCart,
  lang: string,
  appLoaded: boolean,
}>

export const appLoaded: Reducer<boolean, Actions.Action> = (s = false, a) => {
  switch (a.type) {
    case Actions.APP_LOADED:
      return true
  }
  return s
}

export const lang: Reducer<string, Actions.Action> = (s = checkLang(navigator.language), a) => {
  switch (a.type) {
    case Actions.SET_INTERFACE_LANG:
      moment.locale(a.data.lang)
      return a.data.lang
  }
  return s
}

export const rootReducer = combineReducers<State, Actions.Action>({
  modals,
  customer,
  market,
  orders,
  // sellers,
  products,
  cart,
  appLoaded,
  lang,
})
