import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const MailIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm18 1.869V18H4V7.869l8 5.333 8-5.333ZM19.197 6H4.803L12 10.798 19.197 6Z'/>
    </svg>
  )
}
