import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const EmptyCartIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M38.012 118.184c1.063-4.656 3.198-9 6.235-12.686 5.032-5.96 12.453-8.94 22.26-8.94 9.809 0 17.27 2.98 22.386 8.94a30.633 30.633 0 0 1 6.292 12.686h24.365a51.809 51.809 0 0 0-13.58-27.263c-9.755-10.26-22.91-15.39-39.462-15.39-16.553 0-29.632 5.13-39.236 15.39a51.727 51.727 0 0 0-13.58 27.263h24.32ZM24.316 14.317c26.346-.724 44.883 24.445 40.436 46.038a63.188 63.188 0 0 1-11.996-3.531 31.757 31.757 0 0 1-18.56-19.352c-1.765-4.799-3.327-9.688-5.251-14.418a87.934 87.934 0 0 0-4.255-8.114c-.057-.17-.204-.329-.374-.623ZM69.154 68.502A34.698 34.698 0 0 1 82.35 40.448c8.951-7.39 20.28-8.873 26.629-7.458a46.465 46.465 0 0 0-6.349 9.88c-1.607 3.202-3.17 6.405-4.833 9.562-4.447 8.284-11.498 12.98-20.563 14.791-2.614.521-5.262.838-8.08 1.28Z'/>
    </svg>
  )
}
