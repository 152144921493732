import { EMPTY as observableEmpty, Observable, of as observableOf } from 'rxjs'
import { ActionsObservable, Epic } from 'redux-observable'
import { catchError, exhaustMap, mergeMap, switchMap } from 'rxjs/operators'
import { AjaxError } from 'rxjs/ajax'

import { log, logAjaxError } from '../../utils/logUtils'
import * as Actions from '../actions'
import { State } from '../reducer'
import { LocalStorage } from '../../modules/localStorage'

type Action = Actions.Action

type Action$ = ActionsObservable<Action>

export type EpicFunc = Epic<Action, Action, State>

export const guardMergeMap = <T, R>(
  obs: Observable<T>,
  cb: (input: Observable<T>) => Observable<R>,
  cncrt?: number,
): Observable<R> => obs.pipe(mergeMap((val) => cb(observableOf(val)).pipe(catchError(catchErrorCustom)), cncrt))

export const guardExhaustMap = <T, R>(obs: Observable<T>, cb: (input: Observable<T>) => Observable<R>): Observable<R> =>
  obs.pipe(exhaustMap((val) => cb(observableOf(val)).pipe(catchError(catchErrorCustom))))

export const guardSwitchMap = <T, R>(obs: Observable<T>, cb: (input: Observable<T>) => Observable<R>): Observable<R> =>
  obs.pipe(switchMap((val) => cb(observableOf(val)).pipe(catchError(catchErrorCustom))))

const catchErrorCustom = (error: AjaxError | Error) => {
  if (error instanceof AjaxError) {
    if (error.status === 401) {
      LocalStorage.removeSid()
      window.location.reload(true)
    } else {
      if (process.env.NODE_ENV !== 'test') {
        logAjaxError(error)
      }
    }
  } else {
    log(error)
  }
  return observableEmpty
}
/**
 * reduce bol
 */
export const ofType = <T extends Action>(actions$: Action$, type: T['type']) =>
  actions$.ofType(type) as ActionsObservable<T>

export const voidFunc = () => {
  /* nothing */
}

export const filterStopEpic = (a: Action) => {
  if (a.type === Actions.STOP_EPIC) {
    throw new Error('STOP_EPIC')
  }
  return true
}
