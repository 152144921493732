import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const DownloadIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 36 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} strokeWidth='5' strokeLinecap='round' strokeLinejoin='round' d='M3 41h30M18.965 31.718V3M18.965 31.718l10.77-10.77M17.77 31.718 7 20.948'/>
    </svg>
  )
}
