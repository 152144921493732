import { SyntheticEvent } from 'react'

const DEF_SIZE = '/l/'

export const srcBySize = (src = '', search = DEF_SIZE) => ({
  src,

  get small() {
    return src?.replace(search, '/s/')
  },

  get medium() {
    return src?.replace(search, '/m/')
  },

  get large() {
    return search !== DEF_SIZE ? src?.replace(search, '/l/') : src
  },

  errorHandler({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) {
    if (currentTarget.src !== src) {
      currentTarget.onerror = null
      currentTarget.src = src || ''
    }
  },
})
