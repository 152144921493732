import { ITag } from '../types/TClient'
import { ITagApi } from '../types/TApi'

export const sortTags = (tags: ITag[]) => tags.sort((a, b) => a.position - b.position)

export const sortByName = (t1: ITag, t2: ITag) => t1.tag.localeCompare(t2.tag, 'ru', { sensitivity: 'base' })

export const convertTagFromApi = (tag: ITagApi): ITag => ({
  id: tag.id,
  tag: tag.tag,
  market: tag.market,
  position: tag.position,
  color: tag.color || '',
  productsCount: tag.products_count || 0,
  imageUrl: tag.image_url || '',
})
