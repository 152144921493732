import { EMessengerType, IProduct, IProductCart } from '../types/TClient'

import { ANALYTICS, YANDEX_METRIKA } from '../config'

export enum AnalyticsSource {
  PROFILE = 'PROFILE',
  PRODUCTS = 'PRODUCTS',
  TAG = 'TAG',
  MARKET_CATEGORY = 'MARKET_CATEGORY',
  PRODUCT_PRODUCTS_MODAL = 'PRODUCT_PRODUCTS_MODAL',
  PRODUCT_SELLER_MODAL = 'PRODUCT_SELLER_MODAL',
  PRODUCT_PRODUCTS = 'PRODUCT_PRODUCTS',
  PRODUCT_SELLER = 'PRODUCT_SELLER',
  SELLERS = 'SELLERS',
  SELLER = 'SELLER',
  CART = 'CART',
  ORDER = 'ORDER',
  ORDERS = 'ORDERS',
  CATEGORIES = 'CATEGORIES',
}

export enum Analytics {
  SkipOnboarding = 'skip_onboarding',
  SelectCity = 'select_city',
  SelectMarket = 'select_market',

  TapOnProducts = 'tap_products',
  TapOnOrders = 'tap_orders',
  TapOnCart = 'tap_cart',
  TapOnNotifications = 'tap_notifications',
  TapOnLogin = 'tap_login',
  TapOnProfile = 'tap_profile',
  TapOnAddAddress = 'tap_add_address',
  SetDefaultAddress = 'set_default_address',
  RemoveAddress = 'remove_address',
  TapOnSaveProfile = 'tap_save_profile',
  TapOnChangeCity = 'tap_change_city',
  TapOnSupport = 'tap_support',
  TapOnFAQ = 'tap_faq',
  TapOnDocuments = 'tap_documents',
  TapOnLogout = 'tap_Logout',

  Login = 'login',
  SignUp = 'sign_up',
  EnterPhone = 'enter_phone',
  ConfirmCode = 'confirm_code',
  ResendCode = 'resend_code',

  SelectCategory = 'select_category',
  SelectSubcategory = 'select_subcategory',
  SelectTag = 'select_subcategory',
  SelectMarketCategory = 'select_market_category',
  TapOnProductCard = 'tap_product_card',
  AddFromProductCard = 'add_from_product_card',
  ChangeQuantityInc = 'change_quantity_inc',
  ChangeQuantityDec = 'change_quantity_dec',
  AddToCart = 'add_to_cart',

  SelectSeller = 'select_seller',
  SelectCategoryTag = 'select_category_tag',

  TapOnOrderInfo = 'tap_order_info',

  CancelOrder = 'cancel_order',
  TapOnPayment = 'tap_payment',

  LoginFromCart = 'login_from_cart',
  TapOnChangeAddress = 'tap_change_address',
  ConfirmOrder = 'confirm_order',
  ResetCart = 'reset_cart',
  ChangeDeliveryType = 'change_delivery_type',
  ChangeDeliveryAddress = 'change_delivery_address',
  ChangePaymentType = 'change_payment_type',
  ChangeDeliveryDate = 'change_delivery_date',
  ChangeDeliveryTime = 'change_delivery_time',
  DeletePosition = 'delete_position',
  CreateOrder = 'create_order',
  ShareApp = 'share_app',
  RepeatOrder = 'repeat_order',
  MarketingPushOpen = 'marketing_push_open',

  TapOnToCatalog = 'tap_to_catalog',
  TapOnComment = 'tap_comment',
  TapOnOrderProducts = 'tap_order_products',
  TapOnOrderProduct = 'tap_order_product',
  TapOnCancelOrder = 'tap_cancel_order',
  TapOnContactUs = 'tap_contact_us',
  TapOnMessenger = 'tap_messenger',
  FeedbackClose = 'tap_feedback_close_icon',
  FeedbackOpenComment = 'tap_feedback_comment',
  FeedbackSend = 'send_feedback',
  FeedbackGoToWebForm = 'feedback_go_to_web_form',
  FeedbackStore = 'feedback_store',
}

type AnalyticsProductEvent =
  | typeof Analytics.AddToCart
  | typeof Analytics.TapOnProductCard
  | typeof Analytics.AddFromProductCard
  | typeof Analytics.ChangeQuantityInc
  | typeof Analytics.ChangeQuantityDec
  | typeof Analytics.DeletePosition

type TAnalyticsEvent =
  | {
      name: AnalyticsProductEvent,
      params: { name: string, price: number, source?: AnalyticsSource },
    }
  | {
      name: typeof Analytics.TapOnMessenger,
      params: { name: EMessengerType },
    }
  | {
      name:
        | typeof Analytics.TapOnAddAddress
        | typeof Analytics.TapOnPayment
        | typeof Analytics.TapOnToCatalog,
      params: { source: AnalyticsSource },
    }
  | {
      name:
        | typeof Analytics.SelectCity
        | typeof Analytics.SelectMarket
        | typeof Analytics.SelectCategory
        | typeof Analytics.SelectSubcategory
        | typeof Analytics.SelectTag
        | typeof Analytics.SelectMarketCategory
        | typeof Analytics.SelectSeller
        | typeof Analytics.SelectCategoryTag
        | typeof Analytics.ChangeDeliveryType
        | typeof Analytics.ChangePaymentType,
      params: { name: string, source?: AnalyticsSource },
    }
  | {
      name:
        | typeof Analytics.CancelOrder
        | typeof Analytics.LoginFromCart
        | typeof Analytics.TapOnChangeAddress
        | typeof Analytics.SetDefaultAddress
        | typeof Analytics.RemoveAddress
        | typeof Analytics.Login
        | typeof Analytics.SignUp
        | typeof Analytics.EnterPhone
        | typeof Analytics.ConfirmCode
        | typeof Analytics.ResendCode
        | typeof Analytics.ConfirmOrder
        | typeof Analytics.ChangeDeliveryAddress
        | typeof Analytics.ChangeDeliveryDate
        | typeof Analytics.ChangeDeliveryTime
        | typeof Analytics.CreateOrder
        | typeof Analytics.DeletePosition
        | typeof Analytics.TapOnCart
        | typeof Analytics.AddToCart
        | typeof Analytics.TapOnProducts
        | typeof Analytics.TapOnOrders
        | typeof Analytics.TapOnLogin
        | typeof Analytics.TapOnProfile
        | typeof Analytics.TapOnSupport
        | typeof Analytics.TapOnFAQ
        | typeof Analytics.TapOnDocuments
        | typeof Analytics.TapOnChangeCity
        | typeof Analytics.TapOnSaveProfile
        | typeof Analytics.TapOnLogout
        | typeof Analytics.ResetCart
        | typeof Analytics.TapOnOrderInfo
        | typeof Analytics.RepeatOrder
        | typeof Analytics.TapOnComment
        | typeof Analytics.TapOnOrderProducts
        | typeof Analytics.TapOnOrderProduct
        | typeof Analytics.TapOnCancelOrder
        | typeof Analytics.TapOnContactUs
        | typeof Analytics.FeedbackClose
        | typeof Analytics.FeedbackOpenComment
        | typeof Analytics.FeedbackSend
        | typeof Analytics.FeedbackGoToWebForm
        | typeof Analytics.FeedbackStore,
    }

type TAnalyticsCommonEvent = {
  name: string,
  params?: { [key: string]: any },
}

export class FirebaseAnalyticsService {
  public static async logEvent(event: TAnalyticsEvent): Promise<FirebaseAnalyticsService> {
    if (ANALYTICS) {
      reachGoal(event.name, (event as TAnalyticsCommonEvent).params);
      (window as any)['branch'].logEvent(event.name, (event as TAnalyticsCommonEvent).params, function (err: any, data: any) {
        // eslint-disable-next-line no-console
        console.log(err, data);
      });
    }

    return FirebaseAnalyticsService
  }

  public static setUserId(id: string): FirebaseAnalyticsService {
    if (ANALYTICS) {
      (window as any)['ym'](YANDEX_METRIKA, 'setUserId', id);
      (window as any)['branch'].setIdentity(id, function (err: any, data: any) {
        // eslint-disable-next-line no-console
        console.log(err, data);
      });
    }

    return FirebaseAnalyticsService
  }

  public static detail(products: IProduct[]) {
    this.ecommerce({
      currencyCode: products[0]?.currency?.code,
      detail: {
        products: products.map((item) => {
          return {
            id: item.id,
            name: item.name,
            price: item.price,
            category: item.marketCategoryId
              ? `${item.marketCategoryName}/${item.marketSubcategoryName || 'Other'}`
              : `${item.sellerName}/${item.categoryName}/${item.subcategoryName}`,
          }
        })
      }
    })
  }

  public static addToCart(products: IProductCart[]) {
    this.ecommerce({
      currencyCode: products[0]?.currency?.code,
      add: {
        products: products.map((item) => {
          return {
            id: item.id,
            name: item.name,
            price: item.price,
            category: item.marketCategoryId
              ? `${item.marketCategoryName}/${item.marketSubcategoryName || 'Other'}`
              : `${item.sellerName}/${item.categoryName}/${item.subcategoryName}`,
            quantity: item.quantity,
          }
        })
      }
    })
  }

  public static removeFromCart(products: IProductCart[]) {
    this.ecommerce({
      currencyCode: products[0]?.currency?.code,
      remove: {
        products: products.map((item) => {
          return {
            id: item.id,
            name: item.name,
            price: item.price,
            category: item.marketCategoryId
              ? `${item.marketCategoryName}/${item.marketSubcategoryName || 'Other'}`
              : `${item.sellerName}/${item.categoryName}/${item.subcategoryName}`,
            quantity: item.quantity,
          }
        })
      }
    })
  }

  public static purchase(id: string, products: IProductCart[]) {
    this.ecommerce({
      currencyCode: products[0]?.currency?.code,
      purchase: {
        actionField: {
          id,
        },
        products: products.map((item) => {
          return {
            id: item.id,
            name: item.name,
            price: item.price,
            category: item.marketCategoryId
              ? `${item.marketCategoryName}/${item.marketSubcategoryName || 'Other'}`
              : `${item.sellerName}/${item.categoryName}/${item.subcategoryName}`,
            quantity: item.quantity,
          }
        })
      }
    })
  }

  public static async ecommerce(ecommerce: any): Promise<FirebaseAnalyticsService> {
    if (ANALYTICS) {
      (window as any)['dataLayer'].push({ ecommerce })
    }

    return FirebaseAnalyticsService
  }
}

function reachGoal(target: string, params?: any): void {
  (window as any)['ym'](YANDEX_METRIKA, 'reachGoal', target, params);
}

