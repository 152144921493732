import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const ClockIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='m15.562 13.766-2.788-2.091v-4.26a.774.774 0 1 0-1.55 0v4.647c0 .244.115.474.31.62l3.099 2.324a.77.77 0 0 0 1.084-.156.773.773 0 0 0-.155-1.084Z'/>
      <path fill={color} d='M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Zm0 18.45c-4.66 0-8.45-3.79-8.45-8.45 0-4.66 3.79-8.45 8.45-8.45 4.66 0 8.45 3.79 8.45 8.45 0 4.66-3.79 8.45-8.45 8.45Z'/>
    </svg>
  )
}
