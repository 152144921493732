import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import './index.scss'

import messages from '../../localization/messages'
import { Button } from '../Button'
import { LocalStorage } from '../../modules/localStorage'
import { PRIVACY_POLICY_URI } from '../../config'

export const CookieAlert = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(!LocalStorage.checkCookieAlert())
  }, [])

  if (!show) {
    return null
  }

  return (
    <div className='cookie-alert'>
      <div className='cookie-alert__content'>
        <div className='cookie-alert__content__image__container'>
          <img className='cookie-alert__content__image' src='/images/american-cookie.png' alt='cookie' />
        </div>
        <div className='cookie-alert__content__text'>
          <FormattedMessage {...messages.CookiesPolicy1}/>{' '}
          <a className='cookie-alert__content__text-link' target='_blank' href={PRIVACY_POLICY_URI}>
            <FormattedMessage {...messages.CookiesPolicy2}/>
          </a>
        </div>
        <div style={{ marginLeft: 'auto' }}/>
        <Button onClick={hideAlert} title='OK' customClass='cookie-alert__content__button' />
      </div>
    </div>
  )

  function hideAlert() {
    setShow(false)
    LocalStorage.toggleCookieAlert(true)
  }
}
