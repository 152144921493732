import { Reducer } from 'redux'
import { uniqBy, unionBy } from 'lodash'

import { IPaymentOption, IOrder } from '../../types/TClient'

import * as Actions from '../actions'
import { filterOrders, sortOrders } from '../../utils/ordersUtils'

export type MutableStateOrders = {
  loading: boolean,
  createLoading: boolean,
  cancelLoading: boolean,
  updateLoading: boolean,
  repeatLoading: boolean,
  payByCardModal: boolean,
  payByCardSuccess: boolean,
  paidOrderId?: string,
  paymentOptions: IPaymentOption[],
  orders?: IOrder[],
  orderToUpdate?: Partial<IOrder>,
}

export type StateOrders = Readonly<MutableStateOrders>

const defStateOrders: StateOrders = {
  loading: false,
  createLoading: false,
  cancelLoading: false,
  updateLoading: false,
  repeatLoading: false,
  payByCardModal: false,
  payByCardSuccess: false,
  paymentOptions: [],
}

export const orders: Reducer<StateOrders, Actions.Action> = (s = defStateOrders, a): StateOrders => {
  switch (a.type) {
    case Actions.API_ORDERS_LIST:
      return {
        ...s,
        loading: true,
      }
    case Actions.ORDERS_LIST: {
      if (a.data.status !== undefined) {
        const orders: IOrder[] = Array.isArray(a.data.orders)
          ? unionBy<IOrder>(
            a.data.orders,
            (s.orders || []).filter((o) => filterOrders(o, a.data.status)),
            'id',
          )
          : unionBy<IOrder>([a.data.orders], s.orders, 'id')

        return {
          ...s,
          loading: false,
          orders: sortOrders(orders),
        }
      }

      return {
        ...s,
        orders: Array.isArray(a.data.orders)
          ? sortOrders(uniqBy([...a.data.orders], 'id'))
          : sortOrders(uniqBy([a.data.orders, ...(s.orders || [])], 'id')),
        loading: false,
      }
    }
    case Actions.ORDERS: {
      return {
        ...s,
        orders: sortOrders(uniqBy([...a.data, ...(s.orders || [])], 'id')),
        loading: false,
      }
    }
    case Actions.RESET_ORDERS:
      return {
        ...s,
        orders: [],
        cancelLoading: false,
        createLoading: false,
        repeatLoading: false,
        loading: false,
      }
    case Actions.ORDERS_LIST_ERROR: {
      return {
        ...s,
        loading: false,
      }
    }
    case Actions.API_CREATE_ORDER:
      return {
        ...s,
        createLoading: true,
      }
    case Actions.CREATE_ORDER: {
      const newOrders = s.orders || []

      if (a.data) {
        newOrders.unshift(a.data )
      }

      return {
        ...s,
        orders: sortOrders(uniqBy(newOrders, 'id')),
        createLoading: false,
      }
    }
    case Actions.API_CANCEL_ORDER:
      return {
        ...s,
        cancelLoading: true,
      }
    case Actions.CANCEL_ORDER:
      return {
        ...s,
        cancelLoading: false,
      }
    case Actions.UPDATE_ORDER:
      return {
        ...s,
        updateLoading: false,
        orders: s.orders?.map((order) => order.id === a.data.id ? { ...order, ...a.data } : order),
      }
    case Actions.API_REPEAT_ORDER:
      return {
        ...s,
        repeatLoading: true,
      }
    case Actions.REPEAT_ORDER:
      return {
        ...s,
        repeatLoading: false,
      }
    case Actions.API_PAY_BY_CARD:
      return {
        ...s,
        payByCardModal: false,
        payByCardSuccess: false,
        paidOrderId: undefined,
      }
    case Actions.PAY_BY_CARD_CLOSE_MODAL:
      return {
        ...s,
        payByCardModal: false,
        payByCardSuccess: false,
        paidOrderId: undefined,
      }
    case Actions.PAY_BY_CARD:
      return {
        ...s,
        payByCardModal: true,
        payByCardSuccess: a.data.success,
        paidOrderId: a.data.orderId,
      }
  }
  return s
}
