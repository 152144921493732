import { TCountriesDictionary } from '../types/TClient'

import { createCountryMessages } from '../utils/langUtils'
import countries from './en/countries'
import { COUNTRY_PREFIX } from '../utils/countriesUtils'

export type Message = {
  id: string,
  defaultMessage: string,
}

export type TCountryKeys = keyof TCountriesDictionary<typeof COUNTRY_PREFIX>

export type MessageKeys =
  | TCountryKeys
  | 'DownloadApp'
  | 'DeliveryFresh'
  | 'DeliveryFor'
  | 'Country'
  | 'AboutProduct'
  | 'AddToCart'
  | 'OutOfStock'
  | 'Total'
  | 'Seller'
  | 'Address'
  | 'INN'
  | 'Pcs'
  | 'Kg'
  | 'Gram'
  | 'Litre'
  | 'Millilitre'
  | 'Back'
  | 'All'
  | 'ResultRequest'
  | 'Other'
  | 'Documents'
  | 'Treaty'
  | 'Agreement'
  | 'Requisites'
  | 'UserAgreement'
  | 'PrivacyPolicy'
  | 'Name'
  | 'Understand'
  | 'RequiredAuth'
  | 'LoginByPhone'
  | 'Login'
  | 'Cart'
  | 'CartEmpty'
  | 'PlaceItemsCart'
  | 'ToCatalog'
  | 'Comment'
  | 'ProductWishes'
  | 'TotalPayable'
  | 'CartProductByWeightInfo'
  | 'CheckoutFor'
  | 'WeightProductPrice'
  | 'CartProductByWeightFullInfo'
  | 'EmptyTrash'
  | 'Cancel'
  | 'SavedCards'
  | 'CardDataSecure'
  | 'YourBankCards'
  | 'SaveCardByOrder'
  | 'DeleteCard'
  | 'Delete'
  | 'PlacingOrder'
  | 'Recipient'
  | 'NameOrCompany'
  | 'ReceiptMethod'
  | 'DateAndTime'
  | 'PaymentMethod'
  | 'HowCardPaymentWorks'
  | 'SpecifyAddress'
  | 'SpecifyAnotherAddress'
  | 'PlaceOrder'
  | 'UpMinimumOrder'
  | 'HowPaymentWorks'
  | 'MarketClosingSoon'
  | 'MarketClosingSoonInfo'
  | 'ItsPity'
  | 'Order'
  | 'From'
  | 'Canceled'
  | 'MoneyWillReturned'
  | 'PayOrderOnTime'
  | 'Pay'
  | 'PayExtra'
  | 'Delivery'
  | 'Pickup'
  | 'Free'
  | 'RepeatOrder'
  | 'ConnectWithUs'
  | 'CancelOrder'
  | 'OrderPrice'
  | 'OrderCancellation'
  | 'ApproveOrderCancel'
  | 'NotCancel'
  | 'MyOrders'
  | 'Profile'
  | 'Purchases'
  | 'Communications'
  | 'Logout'
  | 'WhatName'
  | 'Support'
  | 'Exit'
  | 'ConfirmLogout'
  | 'AnswerYourQuestions'
  | 'OnlineChat'
  | 'AddingAddress'
  | 'Add'
  | 'CityAndStreet'
  | 'Street'
  | 'StreetShort'
  | 'House'
  | 'HouseShort'
  | 'Flat'
  | 'FlatShort'
  | 'Entrance'
  | 'EntranceShort'
  | 'Floor'
  | 'Intercom'
  | 'IntercomShort'
  | 'Code'
  | 'CityRequired'
  | 'SpecifyHouse'
  | 'HowLastOrder'
  | 'WhatLike'
  | 'PostReview'
  | 'Select'
  | 'Cash'
  | 'NewCard'
  | 'ReplacingGoods'
  | 'ReplacingGoodsInfo'
  | 'FreezeMoney'
  | 'FreezeMoneyInfo'
  | 'OrderPaid'
  | 'PaymentFailed'
  | 'CheckCardValid'
  | 'AddAddress'
  | 'RemoveAddress'
  | 'PickupAddress'
  | 'MarketHours'
  | 'DailyFrom'
  | 'Before'
  | 'After'
  | 'RateOrder'
  | 'CardOnline'
  | 'ProductSearch'
  | 'ShowAllProducts'
  | 'NothingFound'
  | 'TryAnotherRequest'
  | 'YourCity'
  | 'ChooseAnother'
  | 'YesThatRight'
  | 'CitySelection'
  | 'NotOpenedInOtherCity'
  | 'ItemsOrder'
  | 'Goods'
  | 'Today'
  | 'Tomorrow'
  | 'AsSoonAsPossible'
  | 'DuringDay'
  | 'DeliveryAsSoonAsPossible'
  | 'DeliveryAsSoonAsPossibleInfo'
  | 'DeliveryDuringDay'
  | 'DeliveryDuringDayInfo'
  | 'CanPickUp'
  | 'With'
  | 'LoginSignUp'
  | 'UserOfferPolicyAgree1'
  | 'UserOfferPolicyAgree2'
  | 'UserOfferPolicyAgree3'
  | 'UserOfferPolicyAgree4'
  | 'NeedPhoneToOrder'
  | 'CodeSent'
  | 'CodeFromSMS'
  | 'InvalidCode'
  | 'Resend'
  | 'SubmitCode'
  | 'GoodsOutOfStock'
  | 'ContinueWithout'
  | 'Replace'
  | 'NeedPayExtra'
  | 'WaitingPayment'
  | 'AcceptedWork'
  | 'Collecting'
  | 'OnCourier'
  | 'Delivered'
  | 'NumCharactersNoMore'
  | 'ItemsDiscount'
  | 'CookiesPolicy1'
  | 'CookiesPolicy2'
  | 'Error'
  | 'NotDeliverHere'
  | 'SorryNotDeliverAddress'
  | 'OrderCancelled'
  | 'ReturningMoneyInfo'
  | 'EnteredCityNotMatchCityMarket'
  | 'SorryUnableCalcDeliveryCost'
  | 'DeliveryCostLessMin'
  | 'Promo'
  | 'AllPromo'
  | 'AddMoreItems'
  | 'OneLastCraving'
  | 'NoThankYou'
  | 'GoToPay'

const messages: Record<MessageKeys, Message> = {
  ...createCountryMessages(countries),
  DownloadApp: {
    id: 'DownloadApp',
    defaultMessage: 'Download app',
  },
  DeliveryFresh: {
    id: 'DeliveryFresh',
    defaultMessage: 'We deliver fresh products from market',
  },
  DeliveryFor: {
    id: 'DeliveryFor',
    defaultMessage: 'In 90 minutes and from 49 rubles with a guarantee of freshness',
  },
  Country: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  AboutProduct: {
    id: 'AboutProduct',
    defaultMessage: 'About the product',
  },
  AddToCart: {
    id: 'AddToCart',
    defaultMessage: 'Add to cart',
  },
  OutOfStock: {
    id: 'OutOfStock',
    defaultMessage: 'Not available',
  },
  Total: {
    id: 'Total',
    defaultMessage: 'Payment total',
  },
  Seller: {
    id: 'Seller',
    defaultMessage: 'Seller',
  },
  Address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  INN: {
    id: 'INN',
    defaultMessage: 'TIN',
  },
  Pcs: {
    id: 'Pcs',
    defaultMessage: 'pcs',
  },
  Kg: {
    id: 'Kg',
    defaultMessage: 'kg',
  },
  Gram: {
    id: 'Gram',
    defaultMessage: 'G',
  },
  Litre: {
    id: 'Litre',
    defaultMessage: 'l',
  },
  Millilitre: {
    id: 'Millilitre',
    defaultMessage: 'ml',
  },
  Back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  All: {
    id: 'All',
    defaultMessage: 'All',
  },
  ResultRequest: {
    id: 'ResultRequest',
    defaultMessage: 'Results on request',
  },
  Other: {
    id: 'Other',
    defaultMessage: 'Other',
  },
  Documents: {
    id: 'Documents',
    defaultMessage: 'Documents',
  },
  Treaty: {
    id: 'Treaty',
    defaultMessage: 'Treaty',
  },
  Agreement: {
    id: 'Agreement',
    defaultMessage: 'Agreement',
  },
  Requisites: {
    id: 'Requisites',
    defaultMessage: 'Requisites',
  },
  UserAgreement: {
    id: 'UserAgreement',
    defaultMessage: 'User Agreement',
  },
  PrivacyPolicy: {
    id: 'PrivacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  Name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  Understand: {
    id: 'Understand',
    defaultMessage: 'It\'s clear',
  },
  RequiredAuth: {
    id: 'RequiredAuth',
    defaultMessage: 'You must be logged in to view',
  },
  LoginByPhone: {
    id: 'LoginByPhone',
    defaultMessage: 'Sign in with your phone number',
  },
  Login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  Cart: {
    id: 'Cart',
    defaultMessage: 'Cart',
  },
  CartEmpty: {
    id: 'CartEmpty',
    defaultMessage: 'Cart is empty',
  },
  PlaceItemsCart: {
    id: 'PlaceItemsCart',
    defaultMessage: 'Place items in it to place an order',
  },
  ToCatalog: {
    id: 'ToCatalog',
    defaultMessage: 'Go to catalog',
  },
  Comment: {
    id: 'Comment',
    defaultMessage: 'Comment',
  },
  ProductWishes: {
    id: 'ProductWishes',
    defaultMessage: 'Product wishes',
  },
  TotalPayable: {
    id: 'TotalPayable',
    defaultMessage: 'Subtotal',
  },
  CartProductByWeightInfo: {
    id: 'CartProductByWeightInfo',
    defaultMessage:
      'There is a weight product in the basket, which means that it is possible' +
      ' to change the amount of the order in both directions',
  },
  CheckoutFor: {
    id: 'CheckoutFor',
    defaultMessage: 'Go to checkout',
  },
  WeightProductPrice: {
    id: 'WeightProductPrice',
    defaultMessage: 'Weight product and price',
  },
  CartProductByWeightFullInfo: {
    id: 'CartProductByWeightFullInfo',
    defaultMessage:
      'Since there is a weight product in the cart, it is possible to change the amount' +
      ' of the order in both directions. The exact amount will be formed after the assembly of the goods.\n' +
      'The total amount is reserved in your account, and the exact amount is debited after the order is collected.',
  },
  EmptyTrash: {
    id: 'EmptyTrash',
    defaultMessage: 'Empty trash',
  },
  Cancel: {
    id: 'Cancel',
    defaultMessage: 'Cancel',
  },
  SavedCards: {
    id: 'SavedCards',
    defaultMessage: 'Saved cards',
  },
  CardDataSecure: {
    id: 'CardDataSecure',
    defaultMessage: 'Card data is secure',
  },
  YourBankCards: {
    id: 'YourBankCards',
    defaultMessage: 'Your bank cards will be here',
  },
  SaveCardByOrder: {
    id: 'SaveCardByOrder',
    defaultMessage: 'You can save the card when paying for the order',
  },
  DeleteCard: {
    id: 'DeleteCard',
    defaultMessage: 'Delete card',
  },
  Delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  PlacingOrder: {
    id: 'PlacingOrder',
    defaultMessage: 'Placing an order',
  },
  Recipient: {
    id: 'Recipient',
    defaultMessage: 'Customer',
  },
  NameOrCompany: {
    id: 'NameOrCompany',
    defaultMessage: 'Name or company name',
  },
  ReceiptMethod: {
    id: 'ReceiptMethod',
    defaultMessage: 'Receipt method',
  },
  DateAndTime: {
    id: 'DateAndTime',
    defaultMessage: 'Date and time',
  },
  PaymentMethod: {
    id: 'PaymentMethod',
    defaultMessage: 'Payment method',
  },
  HowCardPaymentWorks: {
    id: 'HowCardPaymentWorks',
    defaultMessage: 'How card payment works',
  },
  SpecifyAddress: {
    id: 'SpecifyAddress',
    defaultMessage: 'Go to address',
  },
  SpecifyAnotherAddress: {
    id: 'SpecifyAnotherAddress',
    defaultMessage: 'Specify another address',
  },
  PlaceOrder: {
    id: 'PlaceOrder',
    defaultMessage: 'Place order',
  },
  UpMinimumOrder: {
    id: 'UpMinimumOrder',
    defaultMessage: 'Up to the minimum order',
  },
  HowPaymentWorks: {
    id: 'HowPaymentWorks',
    defaultMessage: 'How does payment work?',
  },
  MarketClosingSoon: {
    id: 'MarketClosingSoon',
    defaultMessage: 'The market is closing soon!',
  },
  MarketClosingSoonInfo: {
    id: 'MarketClosingSoonInfo',
    defaultMessage:
      'Sorry, the market will close soon, you can place an order tomorrow,' +
      ' the sellers will just buy everything fresh!',
  },
  ItsPity: {
    id: 'ItsPity',
    defaultMessage: 'It\'s a pity',
  },
  Order: {
    id: 'Order',
    defaultMessage: 'Order',
  },
  From: {
    id: 'From',
    defaultMessage: 'From',
  },
  Canceled: {
    id: 'Canceled',
    defaultMessage: 'Canceled',
  },
  MoneyWillReturned: {
    id: 'MoneyWillReturned',
    defaultMessage: 'The money will be returned to the card',
  },
  PayOrderOnTime: {
    id: 'PayOrderOnTime',
    defaultMessage: 'Pay for the order so that we start collecting it and deliver it on time',
  },
  Pay: {
    id: 'Pay',
    defaultMessage: 'Pay',
  },
  PayExtra: {
    id: 'PayExtra',
    defaultMessage: 'PayExtra',
  },
  Delivery: {
    id: 'Delivery',
    defaultMessage: 'Delivery',
  },
  Pickup: {
    id: 'Pickup',
    defaultMessage: 'Pickup',
  },
  Free: {
    id: 'Free',
    defaultMessage: 'Free',
  },
  RepeatOrder: {
    id: 'RepeatOrder',
    defaultMessage: 'Repeat order',
  },
  ConnectWithUs: {
    id: 'ConnectWithUs',
    defaultMessage: 'Connect with us',
  },
  CancelOrder: {
    id: 'CancelOrder',
    defaultMessage: 'Cancel order',
  },
  OrderPrice: {
    id: 'OrderPrice',
    defaultMessage: 'Order price',
  },
  OrderCancellation: {
    id: 'OrderCancellation',
    defaultMessage: 'Order cancellation',
  },
  ApproveOrderCancel: {
    id: 'ApproveOrderCancel',
    defaultMessage:
      'Are you sure you want to cancel the order? It will be impossible to restore the order.' +
      ' It\'s not too late to change your mind.',
  },
  NotCancel: {
    id: 'NotCancel',
    defaultMessage: 'Don\'t cancel',
  },
  MyOrders: {
    id: 'MyOrders',
    defaultMessage: 'My orders',
  },
  Profile: {
    id: 'Profile',
    defaultMessage: 'Profile',
  },
  Purchases: {
    id: 'Purchases',
    defaultMessage: 'Purchases',
  },
  Communications: {
    id: 'Communications',
    defaultMessage: 'Communications',
  },
  Logout: {
    id: 'Logout',
    defaultMessage: 'Logout',
  },
  WhatName: {
    id: 'WhatName',
    defaultMessage: 'What is your name?',
  },
  Support: {
    id: 'Support',
    defaultMessage: 'Support',
  },
  Exit: {
    id: 'Exit',
    defaultMessage: 'Exit',
  },
  ConfirmLogout: {
    id: 'ConfirmLogout',
    defaultMessage: 'Are you sure you want to leave?',
  },
  AnswerYourQuestions: {
    id: 'AnswerYourQuestions',
    defaultMessage: 'We will gladly answer your questions',
  },
  OnlineChat: {
    id: 'OnlineChat',
    defaultMessage: 'Online chat',
  },
  AddingAddress: {
    id: 'AddingAddress',
    defaultMessage: 'Adding an address',
  },
  Add: {
    id: 'Add',
    defaultMessage: 'Add',
  },
  CityAndStreet: {
    id: 'CityAndStreet',
    defaultMessage: 'City and street',
  },
  Street: {
    id: 'Street',
    defaultMessage: 'Street',
  },
  StreetShort: {
    id: 'StreetShort',
    defaultMessage: 'Str',
  },
  House: {
    id: 'House',
    defaultMessage: 'House',
  },
  HouseShort: {
    id: 'HouseShort',
    defaultMessage: 'House',
  },
  Flat: {
    id: 'Flat',
    defaultMessage: 'Flat',
  },
  FlatShort: {
    id: 'FlatShort',
    defaultMessage: 'Flat',
  },
  Entrance: {
    id: 'Entrance',
    defaultMessage: 'Entrance',
  },
  EntranceShort: {
    id: 'EntranceShort',
    defaultMessage: 'Ent.',
  },
  Floor: {
    id: 'Floor',
    defaultMessage: 'Floor',
  },
  Intercom: {
    id: 'Intercom',
    defaultMessage: 'Intercom',
  },
  IntercomShort: {
    id: 'IntercomShort',
    defaultMessage: 'Int.',
  },
  Code: {
    id: 'Code',
    defaultMessage: 'Code',
  },
  CityRequired: {
    id: 'CityRequired',
    defaultMessage: 'City is required for ordering',
  },
  SpecifyHouse: {
    id: 'SpecifyHouse',
    defaultMessage: 'Specify a house',
  },
  HowLastOrder: {
    id: 'HowLastOrder',
    defaultMessage: 'How do you like the last order?',
  },
  WhatLike: {
    id: 'WhatLike',
    defaultMessage: 'What did you like?',
  },
  PostReview: {
    id: 'PostReview',
    defaultMessage: 'Post a review',
  },
  Select: {
    id: 'Select',
    defaultMessage: 'Select',
  },
  Cash: {
    id: 'Cash',
    defaultMessage: 'Cash',
  },
  NewCard: {
    id: 'NewCard',
    defaultMessage: 'NewCard',
  },
  ReplacingGoods: {
    id: 'ReplacingGoods',
    defaultMessage: 'When replacing goods, there may be additional write-offs',
  },
  ReplacingGoodsInfo: {
    id: 'ReplacingGoodsInfo',
    defaultMessage:
      'If you decide to replace one product with another, we will recalculate the order amount.' +
      ' If the amount increases, we will write off the missing amount from your card',
  },
  FreezeMoney: {
    id: 'FreezeMoney',
    defaultMessage: 'Freeze first, then write off',
  },
  FreezeMoneyInfo: {
    id: 'FreezeMoneyInfo',
    defaultMessage:
      'At the time of the order, we freeze the purchase amount on your card,' +
      ' and write it off after the order is collected.',
  },
  OrderPaid: {
    id: 'OrderPaid',
    defaultMessage: 'Order has been paid',
  },
  PaymentFailed: {
    id: 'PaymentFailed',
    defaultMessage: 'Payment failed',
  },
  CheckCardValid: {
    id: 'CheckCardValid',
    defaultMessage: 'Check the balance of the card or its details. Perhaps they are in error.',
  },
  AddAddress: {
    id: 'AddAddress',
    defaultMessage: 'Add address',
  },
  RemoveAddress: {
    id: 'RemoveAddress',
    defaultMessage: 'Remove address?',
  },
  PickupAddress: {
    id: 'PickupAddress',
    defaultMessage: 'Pickup address',
  },
  MarketHours: {
    id: 'MarketHours',
    defaultMessage: 'Market hours',
  },
  DailyFrom: {
    id: 'DailyFrom',
    defaultMessage: 'Daily from',
  },
  Before: {
    id: 'Before',
    defaultMessage: 'Before',
  },
  After: {
    id: 'After',
    defaultMessage: 'After',
  },
  RateOrder: {
    id: 'RateOrder',
    defaultMessage: 'Rate order',
  },
  CardOnline: {
    id: 'CardOnline',
    defaultMessage: 'Credit or debit card',
  },
  ProductSearch: {
    id: 'ProductSearch',
    defaultMessage: 'Product search',
  },
  ShowAllProducts: {
    id: 'ShowAllProducts',
    defaultMessage: 'Show all products',
  },
  NothingFound: {
    id: 'NothingFound',
    defaultMessage: 'Nothing found',
  },
  TryAnotherRequest: {
    id: 'TryAnotherRequest',
    defaultMessage: 'Try another request or look for a product in the Catalog',
  },
  YourCity: {
    id: 'YourCity',
    defaultMessage: 'Your city',
  },
  ChooseAnother: {
    id: 'ChooseAnother',
    defaultMessage: 'Choose another',
  },
  YesThatRight: {
    id: 'YesThatRight',
    defaultMessage: 'Yes, that\'s right',
  },
  CitySelection: {
    id: 'CitySelection',
    defaultMessage: 'City selection',
  },
  NotOpenedInOtherCity: {
    id: 'NotOpenedInOtherCity',
    defaultMessage: 'We have not opened in other cities yet, but we will start work very soon!',
  },
  ItemsOrder: {
    id: 'ItemsOrder',
    defaultMessage: 'Items in the order',
  },
  Goods: {
    id: 'Goods',
    defaultMessage: 'Goods',
  },
  Today: {
    id: 'Today',
    defaultMessage: 'Today',
  },
  Tomorrow: {
    id: 'Tomorrow',
    defaultMessage: 'Tomorrow',
  },
  AsSoonAsPossible: {
    id: 'AsSoonAsPossible',
    defaultMessage: 'As soon as possible',
  },
  DuringDay: {
    id: 'DuringDay',
    defaultMessage: 'During the day',
  },
  DeliveryAsSoonAsPossible: {
    id: 'DeliveryAsSoonAsPossible',
    defaultMessage: 'Delivery as soon as possible',
  },
  DeliveryAsSoonAsPossibleInfo: {
    id: 'DeliveryAsSoonAsPossibleInfo',
    defaultMessage:
      'Express delivery as soon as possible.' +
      ' Usually such orders are delivered within 90 minutes from the time of order.',
  },
  DeliveryDuringDay: {
    id: 'DeliveryDuringDay',
    defaultMessage: 'Delivery within a day',
  },
  DeliveryDuringDayInfo: {
    id: 'DeliveryDuringDayInfo',
    defaultMessage:
      'Delivery without reference to a specific time.' +
      ' We will deliver the order during the day as the delivery service is busy',
  },
  CanPickUp: {
    id: 'CanPickUp',
    defaultMessage: 'You can pick up',
  },
  With: {
    id: 'With',
    defaultMessage: 'From',
  },
  LoginSignUp: {
    id: 'LoginSignUp',
    defaultMessage: 'Login / Sign up',
  },
  UserOfferPolicyAgree1: {
    id: 'UserOfferPolicyAgree1',
    defaultMessage: 'By continuing you agree to',
  },
  UserOfferPolicyAgree2: {
    id: 'UserOfferPolicyAgree2',
    defaultMessage: 'offer',
  },
  UserOfferPolicyAgree3: {
    id: 'UserOfferPolicyAgree3',
    defaultMessage: 'and',
  },
  UserOfferPolicyAgree4: {
    id: 'UserOfferPolicyAgree4',
    defaultMessage: 'personal data processing policy',
  },
  NeedPhoneToOrder: {
    id: 'NeedPhoneToOrder',
    defaultMessage: 'Need a phone to place an order',
  },
  CodeSent: {
    id: 'CodeSent',
    defaultMessage: 'Verification code sent to phone',
  },
  CodeFromSMS: {
    id: 'CodeFromSMS',
    defaultMessage: 'Code from SMS',
  },
  InvalidCode: {
    id: 'InvalidCode',
    defaultMessage: 'Invalid confirmation code',
  },
  Resend: {
    id: 'Resend',
    defaultMessage: 'Resend',
  },
  SubmitCode: {
    id: 'SubmitCode',
    defaultMessage: 'Submit code',
  },
  GoodsOutOfStock: {
    id: 'GoodsOutOfStock',
    defaultMessage: 'Goods out of stock',
  },
  ContinueWithout: {
    id: 'ContinueWithout',
    defaultMessage: 'Continue without them',
  },
  Replace: {
    id: 'Replace',
    defaultMessage: 'Replace',
  },
  NeedPayExtra: {
    id: 'NeedPayExtra',
    defaultMessage: 'Need to pay extra',
  },
  WaitingPayment: {
    id: 'WaitingPayment',
    defaultMessage: 'Waiting for payment',
  },
  AcceptedWork: {
    id: 'AcceptedWork',
    defaultMessage: 'New / Placed',
  },
  Collecting: {
    id: 'Collecting',
    defaultMessage: 'Collecting',
  },
  OnCourier: {
    id: 'OnCourier',
    defaultMessage: 'In delivery',
  },
  Delivered: {
    id: 'Delivered',
    defaultMessage: 'Delivered',
  },
  NumCharactersNoMore: {
    id: 'NumCharactersNoMore',
    defaultMessage: '{field} - number of characters must be no more {max}',
  },
  ItemsDiscount: {
    id: 'ItemsDiscount',
    defaultMessage: 'Discount',
  },
  CookiesPolicy1: {
    id: 'CookiesPolicy1',
    defaultMessage: 'We use cookies on our website. By continuing to use the site, you agree to the use of cookies and',
  },
  CookiesPolicy2: {
    id: 'CookiesPolicy2',
    defaultMessage: ' privacy policy.',
  },
  Error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  NotDeliverHere: {
    id: 'NotDeliverHere',
    defaultMessage: 'We don\'t deliver here yet',
  },
  SorryNotDeliverAddress: {
    id: 'SorryNotDeliverAddress',
    defaultMessage:
      'Sorry, we don\'t deliver to your address yet. We are working on expanding' +
      'the delivery area and, perhaps, we will get to you very soon!',
  },
  OrderCancelled: {
    id: 'OrderCancelled',
    defaultMessage: 'Order cancelled',
  },
  ReturningMoneyInfo: {
    id: 'ReturningMoneyInfo',
    defaultMessage:
      'The term for returning funds to the card can be up to 7 banking days. ' +
      'Usually funds are returned faster. If the funds are not returned within 7 banking days, please ' +
      'contact the bank that issued your card. Rarely, but it happens that banks do not notify about the return.',
  },
  EnteredCityNotMatchCityMarket: {
    id: 'EnteredCityNotMatchCityMarket',
    defaultMessage: 'The entered city does not match the city of the market',
  },
  SorryUnableCalcDeliveryCost: {
    id: 'SorryUnableCalcDeliveryCost',
    defaultMessage: 'Sorry, unable to calculate delivery cost',
  },
  DeliveryCostLessMin: {
    id: 'DeliveryCostLessMin',
    defaultMessage: 'Delivery cost less than minimum',
  },
  Promo: {
    id: 'Promo',
    defaultMessage: 'Promo',
  },
  AllPromo: {
    id: 'AllPromo',
    defaultMessage: 'All promo',
  },
  AddMoreItems: {
    id: 'AddMoreItems',
    defaultMessage: 'Add more items',
  },
  OneLastCraving: {
    id: 'OneLastCraving',
    defaultMessage: 'One last craving?',
  },
  NoThankYou: {
    id: 'NoThankYou',
    defaultMessage: 'No, thank you',
  },
  GoToPay: {
    id: 'GoToPay',
    defaultMessage: 'Go to pay',
  },
}

export default messages
