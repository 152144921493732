import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const PhoneRingIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path stroke={color} strokeLinecap='round' d='M7.013 4.157a1.753 1.753 0 0 1 1.31.521 1.788 1.788 0 0 1 .517 1.324M6.6 2.549a3.74 3.74 0 0 1 2.732 1.179c.353.372.627.814.806 1.297.178.483.258.997.235 1.512M6.07 1.002a5.667 5.667 0 0 1 2.324.44c.736.308 1.4.768 1.95 1.351s.972 1.275 1.24 2.033c.27.758.378 1.564.32 2.366'/>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M8.133 9.395c.393-.516.864-.595 1.414-.238l2.122 1.607c.314.397.177.952-.413 1.667-.589.714-1.14 1.012-1.65.893-2.082-.477-4.086-1.667-6.011-3.572C1.67 7.847.47 5.784 0 3.562c0-.794.491-1.468 1.473-2.024.511-.357.943-.258 1.297.298l1.473 2.083c.236.476.098.913-.412 1.31-2.299.892 3.005 6.666 4.302 4.166Z'/>
    </svg>
  )
}
