import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const AuthIcon: React.FC<TProps> = ({ width = '100%', height = '100%' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 106 106' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill='#E8E8E8' d='M48.002 94.909h47.153V10.933H48.002v5.228c0 3.01-2.357 5.546-5.5 5.546-3.144 0-5.502-2.377-5.502-5.546V8.873C37 3.96 41.087 0 45.959 0h51.082c4.872 0 8.802 3.961 8.959 8.873v88.254c0 4.912-3.929 8.873-8.802 8.873H46.116c-4.872 0-8.802-3.961-8.802-8.873v-7.605c0-3.01 2.515-5.546 5.502-5.546a5.489 5.489 0 0 1 5.5 5.546c-.157 1.584-.314 3.485-.314 5.387Z'/>
      <path fill='#7B3B8F' d='m65.358 47.586-1.109-1.099-10.136-10.045c-2.217-2.041-2.375-5.494-.158-7.692.95-.942 2.059-1.57 3.326-1.727 1.742-.156 3.642.471 4.75 1.884l10.929 10.83 9.185 9.105c2.217 2.04 2.534 5.494.475 7.691l-.475.471c-6.651 6.593-13.303 13.343-20.113 20.092-1.425 1.727-3.801 2.355-5.86 1.57-2.059-.628-3.642-2.512-3.642-4.71-.159-1.726.475-3.452 1.9-4.551l9.977-9.89c.317-.313.634-.47.95-.784l-.158-.314H5.493c-2.534 0-4.91-1.727-5.385-4.238-.475-2.355.633-4.866 3.01-5.965.95-.314 1.9-.628 2.85-.628h59.39Z'/>
    </svg>
  )
}
