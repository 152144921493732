import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import './index.scss'

import messages from '../../localization/messages'
import * as Actions from '../../store/actions'
import { EModalType, IModalInfo } from '../../store/reducers/modals'
import { OKIcon, VKIcon } from '../Icons'
import { COLORS } from '../../style/colors'
import { EMAIL_CONTACT, OFFER_AGREEMENT_URI, PHONE_CONTACT, PRIVACY_POLICY_URI } from '../../config'
import { checkTablet } from '../../utils/deviceUtils'

type TDispatchedProps = {
  modalInfo: (data: IModalInfo) => Actions.Action,
}

type TProps = RouteComponentProps & TDispatchedProps & WrappedComponentProps

const VK_LINK = 'https://vk.com/tvoyrynok.online'
const OK_LINK = 'https://ok.ru/group/61613933002834'

class FooterCmp extends React.Component<TProps> {
  render() {
    const isMobile = checkTablet()

    return (
      <div className='footer-container'>
        <div className='footer'>
          <div className='footer__messengers'>
            <div className='footer__messengers-item' onClick={() => this.toSocial(VK_LINK)}>
              <div className='footer__messengers-item-icon'>
               <VKIcon color={COLORS.WHITE}/>
              </div>
            </div>
            <div className='footer__messengers-item' onClick={() => this.toSocial(OK_LINK)}>
              <div className='footer__messengers-item-icon'>
                <OKIcon color={COLORS.WHITE}/>
              </div>
            </div>
          </div>
          {!isMobile && this.renderLinks()}
          <div className='footer__info'>
            <a href={`tel:${PHONE_CONTACT}`}  className='footer__info-phone'>{PHONE_CONTACT}</a>
            <a href={`mailto:${EMAIL_CONTACT}`} className='footer__info-email'>{EMAIL_CONTACT}</a>
          </div>
          {isMobile && this.renderLinks()}
        </div>
      </div>
    )
  }

  renderLinks = () => {
    const { formatMessage } = this.props.intl
    const isMobile = checkTablet()

    return (
      <div style={{ width: isMobile ? '100%' : ''}}>
        <div className='footer__links'>
          <div className='footer__link' onClick={this.requisites}>
            {formatMessage(messages.Requisites)}
          </div>
          <a target='_blank' href={OFFER_AGREEMENT_URI} className='footer__link'>
            {formatMessage(messages.UserAgreement)}
          </a>
          <a target='_blank' href={PRIVACY_POLICY_URI} className='footer__link'>
            {formatMessage(messages.PrivacyPolicy)}
          </a>
        </div>
      </div>
    )
  }

  requisites = () => {
    const { formatMessage } = this.props.intl

    this.props.modalInfo({
      type: EModalType.MODAL_INFO,
      size: 'mini',
      style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
      props: {
        title: formatMessage(messages.Requisites),
        text: `${formatMessage(messages.Name)}: ООО «ТВОЙ РЫНОК ОНЛАЙН»\n` +
          'ИНН: 5032327110\n' +
          'ОГРН: 1215000032422\n' +
          'Расчётный счет: 40702 810 4 1000 0800294\n' +
          'Корреспондентский счет: 30101 810 1 4525 0000974\n' +
          'БИК банка: 044525974\n' +
          'Банк: АО «Тинькофф Банк»\n' +
          'ИНН банка: 7710140679',
        btnText: formatMessage(messages.Understand),
      },
    })
  }

  toSocial = (link: string) => {
    window.open(link, '_blank')
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  modalInfo: (data: IModalInfo) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
})

export const FooterPanel = connect(null, mapDispatchToProps)(withRouter(injectIntl(FooterCmp)))
