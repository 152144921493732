import { API_URL, APP_API_URL } from '../../config'

const API_PREFIX = '/api/v1'
const API_PREFIX_V2 = '/api/v2'

export const URL_OK = API_URL + API_PREFIX + '/ok'

export const URL_AUTH_BY_PHONE = API_URL + API_PREFIX_V2 + '/auth/client/'
export const URL_CONFIRM_AUTH = API_URL + API_PREFIX_V2 + '/auth/client/confirm/'
export const URL_RESEND_AUTH_CODE = API_URL + API_PREFIX_V2 + '/auth/sent/code/'
export const URL_PROFILE = API_URL + API_PREFIX + '/client/profile/'
export const URL_UPDATE_PROFILE = API_URL + API_PREFIX + '/client/profile/'
export const URL_ADDRESS = API_URL + API_PREFIX + '/client/profile/address/'
export const URL_REMOVE_CARD = (id: string) => API_URL + API_PREFIX + `/client/card/${id}/delete`
export const URL_LOGOUT = API_URL + API_PREFIX + '/auth/logout/client/'

export const URL_CREATE_TRANSACTION = API_URL + API_PREFIX + '/order/payment/new_transaction/'

export const URL_ORDERS_LIST = API_URL + API_PREFIX_V2 + '/order/orders/'
export const URL_ORDER_CREATE = API_URL + API_PREFIX_V2 + '/order/new/'
export const URL_ORDER_CANCEL = API_URL + API_PREFIX + '/order/cancel/'
export const URL_ORDER_REVIEW = API_URL + API_PREFIX + '/order/review/'
export const URL_ORDER_PAY = API_URL + API_PREFIX + '/order/pay/'
export const URL_PRODUCTS_CHECK = API_URL + API_PREFIX + '/db/products/'

export const URL_DELIVERY_INFO = API_URL + API_PREFIX + '/order/delivery/info/'

export const URL_CITIES = API_URL + API_PREFIX + '/db/cities/'
export const URL_MARKETS = API_URL + API_PREFIX + '/db/markets/'

export const URL_MARKET_CATEGORIES = API_URL + API_PREFIX + '/market_category/'

export const URL_PAYMENT_OPTIONS = API_URL + API_PREFIX + '/db/orders/paymentoptions/'
export const URL_PRODUCTS_OUT_OF_STOCK = API_URL + API_PREFIX + '/order/products/'

export const URL_SELLERS_LIST = API_URL + API_PREFIX + '/db/sellers/'

export const URL_PRODUCT_CATEGORIES = API_URL + API_PREFIX + '/products/categories'
export const URL_PRODUCTS_LIST = API_URL + API_PREFIX + '/db/products/'
export const URL_TAGS = API_URL + API_PREFIX + '/tags/'

export const URL_PRODUCTS_RECOMMENDATION = API_URL + API_PREFIX + '/product/search/relation/'

export const URL_GET_GEOCODE = APP_API_URL + '/api/geocode'
export const URL_GET_SUGGESTIONS = APP_API_URL + '/api/suggestions'
