import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const PhoneIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M17.708 12.293a.999.999 0 0 0-1.414 0L14.7 13.887c-.739-.22-2.118-.72-2.992-1.594-.874-.874-1.374-2.253-1.594-2.992l1.594-1.594a.999.999 0 0 0 0-1.414l-4-4a.999.999 0 0 0-1.414 0L3.582 5.005c-.38.38-.594.902-.586 1.435.023 1.424.4 6.37 4.298 10.268 3.898 3.898 8.844 4.274 10.27 4.298h.027c.528 0 1.027-.208 1.405-.586l2.712-2.712a.999.999 0 0 0 0-1.414l-4-4.001Zm-.127 6.712c-1.248-.021-5.518-.356-8.873-3.712-3.366-3.366-3.692-7.651-3.712-8.874l2.005-2.005L9.587 7 8.294 8.293a1 1 0 0 0-.272.912c.024.115.611 2.842 2.271 4.502 1.66 1.66 4.387 2.247 4.502 2.27a.991.991 0 0 0 .912-.27l1.294-1.293L19.587 17l-2.006 2.005Z'/>
    </svg>
  )
}
