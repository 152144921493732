import { of as observableOf, EMPTY } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Axios from 'axios-observable'

import * as TApi from '../../types/TApi'

import * as Actions from '../actions'
import { EpicFunc, guardExhaustMap, ofType } from './epicHelpers'
import { URL_CITIES, URL_MARKETS } from '../../modules/network/urls'
import { convertMarketFromApi } from '../../utils/marketUtils'

const DEFAULT_CITY = '620ea660-e298-421e-a0d1-a42e57310639'

const getCitiesEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiCities>(a$, Actions.API_CITIES), (b) =>
    b.pipe(
      mergeMap(() =>
        Axios.get(URL_CITIES).pipe(
          mergeMap((resp: { data: TApi.ApiCitiesResp }) => {
            if (resp.data && resp.data.records) {
              const actions: Actions.Action[] = [
                Actions.action(Actions.CITIES, { cities: resp.data.records }),
              ]

              if (!store.value.market.city) {
                actions.push(
                  Actions.action(Actions.SELECT_CITY, {
                    city: resp.data.records.find((item) => item.id === DEFAULT_CITY) || resp.data.records[0],
                  }),
                )
              }

              return observableOf<Actions.Action>(...actions)
            }

            return EMPTY
          }),
          catchError(() => EMPTY),
        ),
      ),
    ),
  )

const selectCityEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.SelectCity>(a$, Actions.SELECT_CITY), (b) =>
    b.pipe(
      mergeMap((c) => {
        // const data = payload.location
        //   ? { latitude: payload.location.lat, longitude: payload.location.lon }
        //   : { city: payload.city.id }
        const actions: Actions.Action[] = [
          Actions.actionEmpty(Actions.RESET_MARKETS),
          Actions.action(Actions.API_MARKETS, {
            city: c.data.city.id,
          }),
          Actions.action(Actions.MARKET_PRODUCTS, {
            products: [],
            force: true,
          }),
        ]

        return observableOf<Actions.Action>(...actions)
      }),
    ),
  )

const getMarketsEpic: EpicFunc = (a$, store) =>
  guardExhaustMap(ofType<Actions.ApiMarkets>(a$, Actions.API_MARKETS), (b) =>
    b.pipe(
      mergeMap((c) =>
        Axios.get(URL_MARKETS, {
          params: c.data,
        }).pipe(
          mergeMap((resp: { data: TApi.ApiMarketsResp }) => {
            if (resp.data && Array.isArray(resp.data.records)) {
              const actions: Actions.Action[] = [
                Actions.action(Actions.MARKETS, { markets: resp.data.records.map(convertMarketFromApi) }),
              ]

              if (!store.value.market.market || store.value.market.market.id !== resp.data.records[0].id) {
                actions.push(Actions.action(Actions.SELECT_MARKET, {
                  market: convertMarketFromApi(resp.data.records[0]),
                }))
              }

              return observableOf<Actions.Action>(...actions)
            }

            return EMPTY
          }),
          catchError(() => {
            return EMPTY
          }),
        ),
      ),
    ),
  )

const selectMarketEpic: EpicFunc = (a$, _store) =>
  guardExhaustMap(ofType<Actions.SelectMarket>(a$, Actions.SELECT_MARKET), (b) =>
    b.pipe(
      mergeMap((c) => {
        const actions: Actions.Action[] = [
          Actions.action(Actions.API_MARKET_CATEGORIES, {
            market: c.data.market.id, with_subcategories: 'true',
          }),
          Actions.actionEmpty(Actions.API_PAYMENT_OPTIONS),
          Actions.actionEmpty(Actions.RESET_CART),
          Actions.actionEmpty(Actions.API_TAGS),
        ]

        return observableOf<Actions.Action>(...actions)
      }),
    ),
  )

export const marketEpics: EpicFunc[] = [
  getCitiesEpic,
  getMarketsEpic,
  selectCityEpic,
  selectMarketEpic,
]
