import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const CloseIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M16.945 15.66c.36.36.36.92 0 1.28-.18.18-.4.26-.64.26s-.46-.08-.64-.26l-3.66-3.66-3.66 3.66c-.18.18-.4.26-.64.26s-.46-.08-.64-.26a.894.894 0 0 1 0-1.28l3.66-3.66-3.66-3.66a.894.894 0 0 1 0-1.28c.36-.36.92-.36 1.28 0l3.66 3.66 3.66-3.66c.36-.36.92-.36 1.28 0s.36.92 0 1.28L13.285 12l3.66 3.66Z'/>
    </svg>
  )
}
