import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const BackgroundLogoIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 224 201' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} d='M171.8 111.156a64.42 64.42 0 0 1-13.342 26.684c-10.629 12.542-26.328 18.812-46.963 18.812-20.636 0-36.38-6.27-47.142-18.812a63.548 63.548 0 0 1-13.342-26.684H0a108.868 108.868 0 0 0 28.596 57.415c20.755 21.614 48.461 32.421 83.121 32.421s62.233-10.807 82.72-32.421a109.265 109.265 0 0 0 28.686-57.415H171.8ZM22 .033c55.601-1.539 94.94 51.7 85.545 97.412a131.856 131.856 0 0 1-25.383-7.582A67.194 67.194 0 0 1 42.878 48.93c-3.736-10.164-7.033-20.493-10.988-30.548a182.42 182.42 0 0 0-9.01-17.142C22.603.857 22.33.527 22 .033Z'/>
      <path fill={color} d='M116.831 114.608a73.56 73.56 0 0 1 27.855-59.612 71.428 71.428 0 0 1 56.316-15.768 97.553 97.553 0 0 0-13.406 21.263c-3.407 6.757-6.703 13.515-10.219 20.218a59.397 59.397 0 0 1-43.405 31.262c-5.549.989-11.153 1.703-17.141 2.637Z'/>
    </svg>
  )
}
