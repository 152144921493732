import React from 'react'

export const MexicoFlagIcon: React.FC = () => {
  return (
    <div style={{ overflow: 'hidden', borderRadius: 4, display: 'flex', alignItems: 'center' }}>
      <svg width={26} height={20} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='a'
        style={{
          maskType: 'alpha',
        }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={26}
        height={20}
      >
        <path fill='#fff' d='M0 0h26v20H0z' />
      </mask>
      <g mask='url(#a)'>
        <path fillRule='evenodd' clipRule='evenodd' d='M17.875 0H26v20h-8.125V0Z' fill='#D9071E' />
        <path fillRule='evenodd' clipRule='evenodd' d='M0 0h9.75v20H0V0Z' fill='#006923' />
        <path fillRule='evenodd' clipRule='evenodd' d='M8.125 0h9.75v20h-9.75V0Z' fill='#fff' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.608 6.952c-.087.379-.03.468.387.468.022 0 .056.063.094.132.04.075.085.156.12.169-.014.02-.173.315-.174.833 0 .107.048.283.095.452.05.184.099.36.08.429-.03.112-.169-.01-.276-.106-.061-.055-.112-.1-.127-.087-.076.066-.116-.456-.14-.771-.01-.14-.018-.24-.024-.228-.027-.06-.038-.135-.048-.208-.024-.16-.046-.314-.23-.314h-.471v.356s.273 0 .273.662c.004.099.132.97.44.974-.18.004-.18.041-.178.063v.004c0 .533.434.59.84.44.243-.09.502-.267.696-.484.273-.307.307-.705-.052-.922-.128-.078-.267-.108-.46-.12.038-.116.001-.22-.027-.302-.024-.068-.042-.12-.006-.15.407-.338.443-.65.147-.93a1.12 1.12 0 0 1-.114-.135c-.072-.096-.148-.197-.243-.225.044-.092.103-.202.178-.33l-.697-.429c-.126.216-.105.385-.086.532.01.08.019.155.003.227Zm.008 2.761h-.008Zm0 0h.027-.027Zm.75-.54-.014.014.01-.008a.036.036 0 0 1 .003-.006Zm-.368.261.008-.001a.149.149 0 0 0-.008.001h-.002.002Z'
          fill='#A8AC71'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.687 6.594s-.558.444-.454.918c.105.473 1.205 0 1.072-.459s-.618-.459-.618-.459Z'
          fill='#FCCA3D'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.654 10.336s.752-2.285-.443-3.467c-1.195-1.183-2.575-1.104-2.575-1.104s-.398.303 0 .534c.397.232.23.453.23.453s-.665-.693-1.107-.288c-.442.405.412.343.322.648-.09.305-.474 1.66.086 2.343.56.684-.53.535-.308.535.222 0 1.008.16 1.008 0 0-.159.273.62.506.62.234 0 .415-.274.415-.274s.327.273.527.273.896-.179.896-.179l-1.316-1.137s.08-.501-.107-.61c-.187-.108 1.496.962 1.628 1.307.132.346.238.346.238.346Z'
          fill='#8F4620'
        />
        <path
          d='M8.991 9.772s.122-.399.253-.426c.113-.024.351.19.351.19.423 2.409 1.445 3.35 3.14 3.35 1.717 0 2.757-.715 3.364-2.936 0 0 .322-.338.436-.3.124.04.28.487.28.487-.697 2.548-2.013 3.683-4.08 3.683-2.086 0-3.258-1.283-3.744-4.048Z'
          fill='#9FAB2F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.834 11.38s2.441.531 3.405.531c.963 0-.846.807-1.552.807-.308 0-.68-.192-1.116-.576-.078-.069-.002-.223-.24-.225-.332-.003-.744-.082-.704-.131.037-.046.52-.06.473-.11-.077-.084-.266-.296-.266-.296Z'
          fill='#2FC2DC'
        />
        <rect x={11.916} y={11.75} width={1.625} height={0.893} rx={0.167} fill='#F9AA51' />
        <path
          d='M9.893 10.113s0-.312.206-.478c.236-.19.459 0 .459 0 .865 1.265 2.16 1.682 3.97 1.247 0 0 .542.181.57.3.034.152-.384.511-.384.511-2.117.508-3.753-.018-4.821-1.58Z'
          fill='#259485'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.48 10.292s-.986.532-.657.532c.329 0 1.615.267 1.365 0-.25-.266-.708-.532-.708-.532ZM12.315 9.657s-.188-.555-.578-.555c-.39 0-.28.455-.467.455-.188 0 .175.32.336.32.161 0 .709-.22.709-.22Z'
          fill='#FCCA3D'
        />
      </g>
    </svg>
    </div>
  )
}
