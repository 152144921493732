import React from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

import './index.scss'

import { IMarket, IProduct } from '../../types/TClient'

import { LOCATION_CATALOG } from '../../utils/locationUtils'
import { checkMobile } from '../../utils/deviceUtils'
import { HeaderPanel } from '../Header'
import SubHeader from '../../components/SubHeader'
import { FooterPanel } from '../Footer'
import { ProductCard } from '../ProductCard'

interface IState {
  widthCard: number,
}

interface IOwnProps {
  title: string,
  products: IProduct[],
  loading: boolean,
  getProducts(offset?: number, limit?: number): void,
  limit: number,
  market?: IMarket,
}

type TProps = IOwnProps & RouteComponentProps<any>

class _ProductsPageCmp extends React.Component<TProps, IState> {
  private listRef = React.createRef<HTMLDivElement>()

  constructor(props: TProps) {
    super(props)

    this.state = {
      widthCard: 152,
    }
  }

  componentDidMount() {
    const {market, getProducts} = this.props

    if (market) {
      getProducts()
      if (checkMobile()) {
        setTimeout(() => {
          if (this.listRef.current) {
            this.setState({ widthCard: (this.listRef.current.getBoundingClientRect().width - 44) / 2 })
          }
        }, 400)
      } else {
        this.setState({ widthCard: 208 })
      }
    }
  }

  render() {
    const { products, title } = this.props
    return (
      <div className='promo-page-container'>
        <HeaderPanel />
        <div className='promo-page' ref={this.listRef} onScroll={this.handleScroll}>
          <SubHeader title={title} back={this.back} />
          <div className='promo-page__content'>
            <div className='promo-page__list-wrapper'>
              <div className='promo-page__list'>{products && products.map(this.renderProduct)}</div>
            </div>
          </div>
          <FooterPanel />
        </div>
      </div>
    )
  }

  renderProduct = (product: IProduct) => {
    return <ProductCard key={product.id} product={product} width={this.state.widthCard} />
  }

  back = () => {
    this.props.history.push(`${LOCATION_CATALOG}`)
  }

  handleScroll = () => {
    const { products, market, loading, limit } = this.props

    if (this.listRef.current && products && market) {
      const { scrollHeight, offsetHeight, scrollTop } = this.listRef.current

      if (!loading && scrollHeight - offsetHeight - scrollTop < 300 && !(products.length % limit)) {
        this.props.getProducts(products.length || 0, limit)
      }
    }
  }
}

export const ProductsPageCmp = withRouter(_ProductsPageCmp)
