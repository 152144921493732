import { applyMiddleware, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as Actions from './actions'
import { rootReducer, State } from './reducer'
import rootEpic from './epics'

const epicMiddleware = createEpicMiddleware<Actions.Action, Actions.Action, State>()
const middlewares: any = [epicMiddleware]
const isDev = process.env.NODE_ENV !== 'production'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['products', 'sellers', 'orders'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  if (isDev) {
    middlewares.push(
      createLogger({
        collapsed: true,
      }),
    )
  }

  const store = createStore(
    persistedReducer,
    isDev ? composeWithDevTools({
      actionSanitizer: (a: any) => a,
    })(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares),
  )
  const persistor = persistStore(store)

  epicMiddleware.run(rootEpic)

  return { store, persistor }
}
