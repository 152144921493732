import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'

import './index.scss'

import { ApiMarketProductsReq } from '../../types/TApi'
import { ICity, IMarket, IUser } from '../../types/TClient'

import langs from '../../localization/langs'
import { COLORS } from '../../style/colors'
import * as Actions from '../../store/actions'
import { State } from '../../store/reducer'
import { EModalType, IModalAuth, IModalMarket } from '../../store/reducers/modals'
import {
  CartIcon,
  ChevronDownIcon,
  LocationIcon,
  LogoIcon,
  MailIcon,
  PhoneIcon,
  SearchIcon,
  UniverseIcon,
  UserIcon,
} from '../Icons'
import { SearchProducts } from '../SearchProducts'
import { LOCATION_CART, LOCATION_CATALOG, LOCATION_PROFILE } from '../../utils/locationUtils'
import { checkTablet } from '../../utils/deviceUtils'
import { EMAIL_CONTACT, PHONE_CONTACT } from '../../config'

type TOwnProps = {
  hideActions?: boolean,
}

type TConnectedProps = {
  lang: string,
  productsInCart: number,
  profile: IUser | null,
  city?: ICity,
  market?: IMarket,
}

type TDispatchedProps = {
  getProducts: (data: ApiMarketProductsReq) => Actions.Action,
  modalAuth: (data: IModalAuth) => Actions.Action,
  marketModal: (data: IModalMarket) => Actions.Action,
  selectLang: (lang: string) => Actions.Action,
}

type TProps = TOwnProps & TConnectedProps & TDispatchedProps & RouteComponentProps

type TState = {
  search: boolean,
}

class HeaderPanelCmp extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      search: false,
    }
  }

  render() {
    const { hideActions } = this.props
    const { search } = this.state
    const isTablet = checkTablet()

    return (
      <div className='header-container'>
        {!isTablet && this.renderExtra()}
        <div className='header'>
          <div className='header__local'>
            <div className='header__logo' onClick={this.toCatalog}>
              <LogoIcon/>
            </div>
            {isTablet && this.renderLang()}
          </div>
          {
            !hideActions && (
              <div className='header-actions'>
                {!isTablet && this.renderSearch()}
                {
                  isTablet &&
                  <div className='header-actions__btn' onClick={this.toggleSearch}>
                    <SearchIcon color={COLORS.BLACK_THEME}/>
                  </div>
                }
                {this.renderCart()}
                <div className='header-actions__btn' onClick={this.toProfile}>
                  <UserIcon color={COLORS.BLACK_THEME}/>
                </div>
                {/*<div className='header-actions__btn' onClick={this.toCatalog}>*/}
                {/*  <MenuIcon color={COLORS.BLACK_THEME}/>*/}
                {/*</div>*/}
              </div>
            )
          }
        </div>
        {search && isTablet && this.renderSearch()}
      </div>
    )
  }

  renderExtra = () => {
    const { city } = this.props

    return (
      <div className='header__extra'>
        <div className='header__extra-local'>
          <div className='header__extra-location' onClick={this.marketChange}>
            <div className='header__extra__icon'>
              <LocationIcon color={COLORS.BLACK_THEME}/>
            </div>
            <div className='header__extra-location-text'>
              {city?.name}
            </div>
          </div>
          <div className='header__extra-language'>
            <div className='header__extra__icon'>
              <UniverseIcon color={COLORS.BLACK_THEME}/>
            </div>
            {this.renderLang()}
          </div>
        </div>
        <div className='header__extra-contact'>
          <div className='header__extra-contact__email'>
            <div className='header__extra__icon'>
              <MailIcon color={COLORS.BLACK_THEME}/>
            </div>
            <a href={`mailto:${EMAIL_CONTACT}`} className='header__extra-contact__email-text'>{EMAIL_CONTACT}</a>
          </div>
          <div className='header__extra-contact__phone'>
            <div className='header__extra__icon'>
              <PhoneIcon color={COLORS.BLACK_THEME}/>
            </div>
            <a href={`tel:${PHONE_CONTACT}`} className='header__extra-contact__phone-text'>{PHONE_CONTACT}</a>
          </div>
        </div>
      </div>
    )
  }

  renderLang = () => {
    const { lang } = this.props
    const langDesc = langs.find((item) => item.language === lang)

    return (
      <>
        <Dropdown
          className='header__extra-language-text'
          text={langDesc?.nativeName}
          icon={(
            <div className='header__extra-language__icon'>
              <ChevronDownIcon color={COLORS.GRAY_TEXT}/>
            </div>
          )}
        >
          <Dropdown.Menu className='header__extra-language-menu'>
            {langs.map((item) => (
              <Dropdown.Item key={item.language} onClick={() => this.selectLang(item.language)}>
                {item.nativeName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }

  renderCart = () => {
    const { productsInCart } = this.props

    return (
      <div className='header-actions__btn' onClick={this.toCart}>
        <CartIcon color={COLORS.BLACK_THEME}/>
        {productsInCart > 0 && <div className='header-actions__btn-badge'>{productsInCart}</div>}
      </div>
    )
  }

  renderSearch = () => {
    return (
      <div className='header__search-container'>
        <SearchProducts onClose={this.toggleSearch}/>
      </div>
    )
  }

  selectLang = (lang: string) => {
    this.props.selectLang(lang)
  }

  toggleSearch = () => {
    this.setState({ search: !this.state.search })
  }

  marketChange = () => {
    this.props.marketModal({
      type: EModalType.MODAL_MARKET,
      size: 'mini',
      style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
      props: {},
    })
  }

  toCart = () => {
    this.props.history.push(`${LOCATION_CART}`)
  }

  toProfile = () => {
    const { profile } = this.props

    if (!profile || !profile.id) {
      this.props.modalAuth({
        type: EModalType.MODAL_AUTH,
        size: 'mini',
        style: { width: '420px', maxWidth: '90%', borderRadius: '16px' },
        props: {},
      })
    } else {
      this.props.history.push(`${LOCATION_PROFILE}`)
    }
  }

  toCatalog = () => {
    this.props.history.push(`${LOCATION_CATALOG}`)
  }
}

const mapStateToProps = (s: State): TConnectedProps => {
  const { products = [] } = s.cart

  return {
    lang: s.lang,
    city: s.market.city,
    market: s.market.market,
    profile: s.customer.profile,
    productsInCart: products.length,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): TDispatchedProps => ({
  getProducts: (data: ApiMarketProductsReq) => dispatch(Actions.action(Actions.API_MARKET_PRODUCTS, data)),
  modalAuth: (data: IModalAuth) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  marketModal: (data: IModalMarket) => dispatch(Actions.action(Actions.MODAL_PUSH, data)),
  selectLang: (lang: string) => dispatch(Actions.action(Actions.SET_INTERFACE_LANG, { lang })),
})

export const HeaderPanel = connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderPanelCmp))
