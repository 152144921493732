import React from 'react'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import './App.scss'

import { ModalContainer } from './ModalContainer'
import { LOCATION_MAIN, history } from '../utils/locationUtils'
import { Home } from './pages/Home'
import { LangContainer } from './LangContainer'

function App() {
  return (
    <LangContainer>
      <div className='app'>
        <Router history={history}>
          <Switch>
            <Route path={LOCATION_MAIN} component={Home} />
          </Switch>
        </Router>
        <ModalContainer />
      </div>
    </LangContainer>
  )
}

export default App
