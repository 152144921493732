import * as TApi from '../types/TApi'
import * as TClient from '../types/TClient'

import { EModalType, IModal } from './reducers/modals'

export type AE<T> = { type: T }
export type A<T, D> = {
  type: T,
  data: D,
}

export const STOP_EPIC = 'STOP_EPIC'
export type StopEpic = AE<typeof STOP_EPIC>

export const APP_LOADED = 'APP_LOADED'
export type AppLoaded = AE<typeof APP_LOADED>

export const SET_INTERFACE_LANG = 'SET_INTERFACE_LANG'
export type SetInterfaceLang = A<typeof SET_INTERFACE_LANG, { lang: string }>

export const TOGGLE_TEST_MODE = 'TOGGLE_TEST_MODE'
export type ToggleTestMode = AE<typeof TOGGLE_TEST_MODE>

export const MODAL_PUSH = 'MODAL_PUSH'
export type ModalPush = A<typeof MODAL_PUSH, IModal>

export const MODAL_POP = 'MODAL_POP'
export type ModalPop = A<typeof MODAL_POP, { modal?: EModalType }>

export const MODAL_CLEAR = 'MODAL_CLEAR'
export type ModalClear = AE<typeof MODAL_CLEAR>

export const AUTH_BY_PHONE = 'AUTH_BY_PHONE'
export type AuthByPhone = A<typeof AUTH_BY_PHONE, TApi.ApiAuthByPhoneReq>

export const AUTH_BY_PHONE_SUCCESS = 'AUTH_BY_PHONE_SUCCESS'
export type AuthByPhoneSuccess = AE<typeof AUTH_BY_PHONE_SUCCESS>

export const AUTH_BY_PHONE_ERROR = 'AUTH_BY_PHONE_ERROR'
export type AuthByPhoneError = AE<typeof AUTH_BY_PHONE_ERROR>

export const CONFIRM_AUTH = 'CONFIRM_AUTH'
export type ConfirmAuth = A<typeof CONFIRM_AUTH, TApi.ApiConfirmAuthReq>

export const CONFIRM_AUTH_SUCCESS = 'CONFIRM_AUTH_SUCCESS'
export type ConfirmAuthSuccess = A<typeof CONFIRM_AUTH_SUCCESS, TClient.IConfirmAuth>

export const CONFIRM_AUTH_ERROR = 'CONFIRM_AUTH_ERROR'
export type ConfirmAuthError = AE<typeof CONFIRM_AUTH_ERROR>

export const RESEND_AUTH_CODE = 'RESEND_AUTH_CODE'
export type ResendAuthCode = A<typeof RESEND_AUTH_CODE, TApi.ApiAuthByPhoneReq>

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export type LoginSuccess = A<typeof LOGIN_SUCCESS, string>

export const LOGOUT = 'LOGOUT'
export type Logout = AE<typeof LOGOUT>

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export type LogoutSuccess = AE<typeof LOGOUT_SUCCESS>

export const API_PROFILE = 'API_PROFILE'
export type ApiProfile = AE<typeof API_PROFILE>

export const PROFILE = 'PROFILE'
export type Profile = A<typeof PROFILE, TClient.IUser>

export const API_UPDATE_PROFILE = 'API_UPDATE_PROFILE'
export type ApiUpdateProfile = A<typeof API_UPDATE_PROFILE, TApi.ApiUpdateProfileReq>

export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'
export type UpdateProfileError = AE<typeof UPDATE_PROFILE_ERROR>

export const API_ADDRESSES = 'API_ADDRESSES'
export type ApiAddresses = AE<typeof API_ADDRESSES>

export const ADDRESSES = 'ADDRESSES'
export type Addresses = A<typeof ADDRESSES, TClient.IAddress[]>

export const API_ADD_ADDRESS = 'API_ADD_ADDRESS'
export type ApiAddAddress = A<typeof API_ADD_ADDRESS, TApi.IAddAddressReq>

export const ADD_ADDRESS = 'ADD_ADDRESS'
export type AddAddress = A<typeof ADD_ADDRESS, TClient.IAddress>

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export type UpdateAddress = A<typeof UPDATE_ADDRESS, TClient.IAddress>

export const UPDATE_ADDRESS_COORDS = 'UPDATE_ADDRESS_COORDS'
export type UpdateAddressCoords = A<typeof UPDATE_ADDRESS_COORDS, TClient.IUpdateAddressCoords>

export const API_REMOVE_ADDRESS = 'API_REMOVE_ADDRESS'
export type ApiRemoveAddress = A<typeof API_REMOVE_ADDRESS, TApi.IRemoveAddressReq>

export const REMOVE_ADDRESS = 'REMOVE_ADDRESS'
export type RemoveAddress = A<typeof REMOVE_ADDRESS, TClient.IAddress>

export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS'
export type SetDefaultAddress = A<typeof SET_DEFAULT_ADDRESS, TClient.IAddress>

export const DEFAULT_ADDRESS = 'DEFAULT_ADDRESS'
export type DefaultAddress = A<typeof DEFAULT_ADDRESS, TClient.IAddress>

export const API_ADDRESS_SUGGESTIONS = 'API_ADDRESS_SUGGESTIONS'
export type ApiAddressSuggestions = A<typeof API_ADDRESS_SUGGESTIONS, { query: string }>

export const ADDRESS_SUGGESTIONS = 'ADDRESS_SUGGESTIONS'
export type AddressSuggestions = A<typeof ADDRESS_SUGGESTIONS, TClient.ISuggestionSuccess>

export const API_ADDRESS_GEOCODING = 'API_ADDRESS_GEOCODING'
export type ApiAddressGeocoding = A<typeof API_ADDRESS_GEOCODING, { query: string }>

export const ADDRESS_GEOCODING = 'ADDRESS_GEOCODING'
export type AddressGeocoding = A<typeof ADDRESS_GEOCODING, TClient.IAddressSuggestion | null>

export const ADDRESS_SUGGESTIONS_CLEAR = 'ADDRESS_SUGGESTIONS_CLEAR'
export type AddressSuggestionsClear = AE<typeof ADDRESS_SUGGESTIONS_CLEAR>

export const CARDS = 'CARDS'
export type Cards = A<typeof CARDS, { cards: TClient.ICard[], saveCard?: boolean, newCardId?: string }>

export const API_CITIES = 'API_CITIES'
export type ApiCities = AE<typeof API_CITIES>

export const CITIES = 'CITIES'
export type Cities = A<typeof CITIES, { cities: TClient.ICity[] }>

export const API_MARKETS = 'API_MARKETS'
export type ApiMarkets = A<typeof API_MARKETS, TApi.ApiMarketsReq>

export const MARKETS = 'MARKETS'
export type Markets = A<typeof MARKETS, { markets: TClient.IMarket[] }>

export const SELECT_CITY = 'SELECT_CITY'
export type SelectCity = A<typeof SELECT_CITY, { city: TClient.ICity }>

export const SELECT_MARKET = 'SELECT_MARKET'
export type SelectMarket = A<typeof SELECT_MARKET, { market: TClient.IMarket }>

export const RESET_MARKETS = 'RESET_MARKETS'
export type ResetMarkets = AE<typeof RESET_MARKETS>

export const API_MARKET_CATEGORIES = 'API_MARKET_CATEGORIES'
export type ApiMarketCategories = A<typeof API_MARKET_CATEGORIES, TApi.ApiMarketCategoriesReq>

export const MARKET_CATEGORIES = 'MARKET_CATEGORIES'
export type MarketCategories = A<typeof MARKET_CATEGORIES, { categories: TClient.IMarketCategory[] }>

export const API_MARKET_PRODUCTS = 'API_MARKET_PRODUCTS'
export type ApiMarketProducts = A<typeof API_MARKET_PRODUCTS, TApi.ApiMarketProductsReq>

export const MARKET_PRODUCTS = 'MARKET_PRODUCTS'
export type MarketProducts = A<typeof MARKET_PRODUCTS, {
  products: TClient.IProduct[],
  force?: boolean,
  search?: boolean,
  discount?: boolean,
  searchStr?: string,
}>

export const MARKET_PRODUCT = 'MARKET_PRODUCT'
export type MarketProduct = A<typeof MARKET_PRODUCT, { product: { id: string } & Partial<TClient.IProduct> }>

export const API_PAYMENT_OPTIONS = 'API_PAYMENT_OPTIONS'
export type ApiPaymentOptions = AE<typeof API_PAYMENT_OPTIONS>

export const PAYMENT_OPTIONS = 'PAYMENT_OPTIONS'
export type PaymentOptions = A<typeof PAYMENT_OPTIONS, TClient.IPaymentOption[]>

export const ADD_TO_CART = 'ADD_TO_CART'
export type AddToCart = A<typeof ADD_TO_CART, TClient.IProductCart>

export const UPDATE_CART = 'UPDATE_CART'
export type UpdateCart = A<typeof UPDATE_CART, TClient.IProductCart>

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export type RemoveFromCart = A<typeof REMOVE_FROM_CART, string>

export const REMOVE_PRODUCTS_FROM_CART = 'REMOVE_PRODUCTS_FROM_CART'
export type RemoveProductsFromCart = A<typeof REMOVE_PRODUCTS_FROM_CART, string[]>

export const RESET_CART = 'RESET_CART'
export type ResetCart = AE<typeof RESET_CART>

export const SET_COMMENT = 'SET_COMMENT'
export type SetComment = A<typeof SET_COMMENT, string>

export const DROP_COMMENT = 'DROP_COMMENT'
export type DropComment = AE<typeof DROP_COMMENT>

export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE'
export type SetPaymentPayment = A<typeof SET_PAYMENT_TYPE, { paymentType: TClient.EPaymentType, cardId?: string }>

export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE'
export type SetDeliveryType = A<typeof SET_DELIVERY_TYPE, boolean>

export const SET_DELIVERY_SLOT = 'SET_DELIVERY_SLOT'
export type SetDeliverySlot = A<typeof SET_DELIVERY_SLOT, TClient.ISlot | undefined>

export const SELECT_DELIVERY_INTERVAL_TYPE = 'SELECT_DELIVERY_INTERVAL_TYPE'
export type SelectDeliveryIntervalType = A<typeof SELECT_DELIVERY_INTERVAL_TYPE, TClient.EDeliveryInterval | undefined>

export const TOGGLE_IS_TODAY = 'TOGGLE_IS_TODAY'
export type ToggleIsToday = A<typeof TOGGLE_IS_TODAY, boolean>

export const CHANGE_IS_TODAY_PERMISSION = 'CHANGE_IS_TODAY_PERMISSION'
export type ChangeIsTodayPermission = A<typeof CHANGE_IS_TODAY_PERMISSION, boolean>

export const DROP_DELIVERY_COST = 'DROP_DELIVERY_COST'
export type DropDeliveryCost = AE<typeof DROP_DELIVERY_COST>

export const DELIVERY_COST_ERROR = 'DELIVERY_COST_ERROR'
export type DeliveryCostError = AE<typeof DELIVERY_COST_ERROR>

export const RESET_DELIVERY_COST_ERROR = 'RESET_DELIVERY_COST_ERROR'
export type ResetDeliveryCostError = AE<typeof RESET_DELIVERY_COST_ERROR>

export const API_DELIVERY_INFO = 'API_DELIVERY_INFO'
export type ApiDeliveryInfo = A<typeof API_DELIVERY_INFO, TApi.IDeliveryInfoReq>

export const DELIVERY_INFO = 'DELIVERY_INFO'
export type DeliveryInfo = A<typeof DELIVERY_INFO, TClient.IDeliveryInfo>

export const DELIVERY_INFO_ERROR = 'DELIVERY_INFO_ERROR'
export type DeliveryInfoError = AE<typeof DELIVERY_INFO_ERROR>

export const API_CHECK_CURRENT_ADDRESS = 'API_CHECK_CURRENT_ADDRESS'
export type ApiCheckCurrentAddress = AE<typeof API_CHECK_CURRENT_ADDRESS>

export const CHECK_CURRENT_ADDRESS = 'CHECK_CURRENT_ADDRESS'
export type CheckCurrentAddress = A<typeof CHECK_CURRENT_ADDRESS, TClient.DeliveryInfoStatus>

export const RESET_CHECK_CURRENT_ADDRESS = 'RESET_CHECK_CURRENT_ADDRESS'
export type ResetCheckCurrentAddress = AE<typeof RESET_CHECK_CURRENT_ADDRESS>

export const API_PRODUCTS_OUT_OF_STOCK = 'API_PRODUCTS_OUT_OF_STOCK'
export type ApiProductsOutOfStock = A<typeof API_PRODUCTS_OUT_OF_STOCK, TApi.ApiProductOutOfStockReq>

export const PRODUCTS_OUT_OF_STOCK = 'PRODUCTS_OUT_OF_STOCK'
export type ProductsOutOfStock = A<typeof PRODUCTS_OUT_OF_STOCK, TClient.IProductOutOfStock[]>

export const API_ORDERS_LIST = 'API_ORDERS_LIST'
export type ApiOrdersList = A<typeof API_ORDERS_LIST, TApi.ApiOrdersReq | undefined>

export const ORDERS_LIST = 'ORDERS_LIST'
export type OrdersList = A<
  typeof ORDERS_LIST,
  {
    orders: TClient.IOrder | TClient.IOrder[],
    status?: number | number[],
  }
>

export const ORDERS_LIST_ERROR = 'ORDERS_LIST_ERROR'
export type OrdersListError = AE<typeof ORDERS_LIST_ERROR>

export const ORDERS = 'ORDERS'
export type Orders = A<typeof ORDERS, TClient.IOrder[]>

export const RESET_ORDERS = 'RESET_ORDERS'
export type ResetOrders = AE<typeof RESET_ORDERS>

export const API_CREATE_TRANSACTION = 'API_CREATE_TRANSACTION'
export type ApiCreateTransaction = A<typeof API_CREATE_TRANSACTION, TApi.ICreateTransactionReq>

export const API_PAY_BY_CARD = 'API_PAY_BY_CARD'
export type ApiPayByCard = A<typeof API_PAY_BY_CARD, TApi.IPayByCardReq>

export const PAY_BY_CARD = 'PAY_BY_CARD'
export type PayByCard = A<typeof PAY_BY_CARD, { orderId: string, success: boolean }>

export const PAY_BY_CARD_CLOSE_MODAL = 'PAY_BY_CARD_CLOSE_MODAL'
export type PayByCardCloseModal = AE<typeof PAY_BY_CARD_CLOSE_MODAL>

export const API_REMOVE_CARD = 'API_REMOVE_CARD'
export type ApiRemoveCard = A<typeof API_REMOVE_CARD, { id: string }>

export const API_ORDER_REVIEW = 'API_ORDER_REVIEW'
export type ApiOrderReview = A<typeof API_ORDER_REVIEW, TApi.IReviewOrderReq>

export const API_CREATE_ORDER = 'API_CREATE_ORDER'
export type ApiCreateOrder = A<typeof API_CREATE_ORDER, TApi.ICreateOrderReq>

export const CREATE_ORDER = 'CREATE_ORDER'
export type CreateOrder = A<typeof CREATE_ORDER, TClient.IOrder | null>

export const API_REPEAT_ORDER = 'API_REPEAT_ORDER'
export type ApiRepeatOrder = A<typeof API_REPEAT_ORDER, TApi.IRepeatOrder>

export const REPEAT_ORDER = 'REPEAT_ORDER'
export type RepeatOrder = A<typeof REPEAT_ORDER, boolean>

export const API_CANCEL_ORDER = 'API_CANCEL_ORDER'
export type ApiCancelOrder = A<typeof API_CANCEL_ORDER, TApi.ICancelOrderReq>

export const CANCEL_ORDER = 'CANCEL_ORDER'
export type CancelOrder = AE<typeof CANCEL_ORDER>

export const UPDATE_ORDER = 'UPDATE_ORDER'
export type UpdateOrder = A<typeof UPDATE_ORDER, TClient.TUpdateOrder>

export const API_TAGS = 'API_TAGS'
export type ApiTags = AE<typeof API_TAGS>

export const TAGS = 'TAGS'
export type Tags = A<typeof TAGS, TClient.ITag[]>

export const API_PRODUCTS_RECOMMENDATION = 'API_PRODUCTS_RECOMMENDATION'
export type ApiProductsRecommendation = A<typeof API_PRODUCTS_RECOMMENDATION, TApi.IProductRecommendationReq>

export const PRODUCTS_RECOMMENDATION = 'PRODUCTS_RECOMMENDATION'
export type ProductsRecommendation = A<typeof PRODUCTS_RECOMMENDATION, TClient.IProductGroup[]>

// export const API_SELLERS_LIST = 'API_SELLERS_LIST'
// export type ApiSellersList = A<typeof API_SELLERS_LIST, TApi.ApiSellersListReq>
//
// export const SELLERS_LIST = 'SELLERS_LIST'
// export type SellersList = A<typeof SELLERS_LIST, { sellers: TClient.ISeller[], isSystem?: boolean, force?: boolean }>
//
// export const RESET_SELLERS_LIST = 'RESET_SELLERS_LIST'
// export type ResetSellersList = AE<typeof RESET_SELLERS_LIST>
//
// export const SELLERS_CATEGORIES = 'SELLERS_CATEGORIES'
// export type SellersCategories = A<typeof SELLERS_CATEGORIES, { categories: TClient.ISellerCategory[] }>
//
// export const API_SELLERS_SEARCH = 'API_SELLERS_SEARCH'
// export type ApiSellersSearch = A<typeof API_SELLERS_SEARCH, TApi.ApiSellersSearchReq>
//
// export const SELLERS_SEARCH = 'SELLERS_SEARCH'
// export type SellersSearch = A<typeof SELLERS_SEARCH, { sellers: TClient.ISellerSearch[] }>
//
// export const API_SELLER_PRODUCTS = 'API_SELLER_PRODUCTS'
// export type ApiSellerProducts = A<typeof API_SELLER_PRODUCTS, TApi.ApiSellerProductsReq>
//
// export const SELLER_PRODUCTS = 'SELLER_PRODUCTS'
// export type SellerProducts = A<typeof SELLER_PRODUCTS, { products: TClient.ISellerProduct[], force?: boolean }>

export type Action =
  | StopEpic
  | AppLoaded
  | ModalPush
  | ModalPop
  | ModalClear
  | ToggleTestMode

  | Logout
  | LogoutSuccess
  | AuthByPhone
  | AuthByPhoneSuccess
  | AuthByPhoneError
  | ConfirmAuth
  | ConfirmAuthSuccess
  | ConfirmAuthError
  | ResendAuthCode
  | LoginSuccess
  | ApiProfile
  | Profile
  | ApiAddresses
  | Addresses
  | ApiAddAddress
  | AddAddress
  | UpdateAddress
  | UpdateAddressCoords
  | ApiRemoveAddress
  | RemoveAddress
  | SetDefaultAddress
  | DefaultAddress
  | ApiAddressSuggestions
  | AddressSuggestions
  | AddressSuggestionsClear
  | ApiAddressGeocoding
  | AddressGeocoding
  | Cards
  | ApiUpdateProfile
  | UpdateProfileError

  | ApiCities
  | Cities
  | SelectCity
  | ApiMarkets
  | Markets
  | SelectMarket
  | ResetMarkets

  | ApiMarketCategories
  | MarketCategories
  | ApiMarketProducts
  | MarketProducts
  | MarketProduct

  | ApiPaymentOptions
  | PaymentOptions

  | AddToCart
  | UpdateCart
  | RemoveFromCart
  | RemoveProductsFromCart
  | ResetCart
  | SetComment
  | DropComment
  | SetPaymentPayment
  | SetDeliveryType
  | DropDeliveryCost
  | DeliveryCostError
  | ResetDeliveryCostError
  | ApiDeliveryInfo
  | DeliveryInfo
  | DeliveryInfoError
  | ApiCheckCurrentAddress
  | CheckCurrentAddress
  | ResetCheckCurrentAddress
  | SetDeliverySlot
  | SelectDeliveryIntervalType
  | ToggleIsToday
  | ChangeIsTodayPermission
  | ApiProductsOutOfStock
  | ProductsOutOfStock

  | ApiOrdersList
  | OrdersList
  | OrdersListError
  | Orders
  | ResetOrders
  | ApiCreateOrder
  | CreateOrder
  | ApiRepeatOrder
  | RepeatOrder
  | ApiCancelOrder
  | CancelOrder
  | UpdateOrder
  | ApiCreateTransaction
  | ApiPayByCard
  | PayByCard
  | PayByCardCloseModal
  | ApiRemoveCard
  | ApiOrderReview
  | ApiTags
  | Tags
  | ApiProductsRecommendation
  | ProductsRecommendation

  | SetInterfaceLang

  // | ApiSellersList
  // | SellersList
  // | ResetSellersList
  // | SellersCategories
  // | ApiSellersSearch
  // | SellersSearch
  // | ApiSellerProducts
  // | SellerProducts

export const actionEmpty = <T>(type: T) => ({ type })
export const action = <T, D>(type: T, data: D) => ({ type, data })
