import React from 'react'

type TProps = {
  width?: number | string,
  height?: number | string,
  color?: string,
}

export const CardIcon: React.FC<TProps> = ({ width = '100%', height = '100%', color }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M1.571 8.005H22.03V7.04c0-.81-.658-1.468-1.468-1.468H3.04c-.81 0-1.468.658-1.468 1.468v.966ZM22.03 9.577H1.57v6.974c0 .81.658 1.467 1.468 1.467H20.56c.81 0 1.468-.658 1.468-1.467V9.577ZM3.039 4H20.56A3.042 3.042 0 0 1 23.6 7.039v9.512a3.042 3.042 0 0 1-3.039 3.039H3.04A3.042 3.042 0 0 1 0 16.55V7.04A3.042 3.042 0 0 1 3.039 4Z'/>
      <path fill={color} fillRule='evenodd' clipRule='evenodd' d='M4.29 12.511h4.502a.786.786 0 1 1 0 1.572H4.29a.786.786 0 1 1 0-1.572Z'/>
    </svg>
  )
}
