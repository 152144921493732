import { uniq } from 'lodash'

import {
  EGMapGeocodeType,
  IAddressApi,
  IGMapGeocode,
  IGMapsSuggestion,
  IProfileApi,
} from '../types/TApi'
import { IAddress, IAddressSuggestion, IUser } from '../types/TClient'

import { intlMessage } from '../App/LangContainer'
import messages from '../localization/messages'

export const convertProfileFromApi = (profile: IProfileApi): IUser => {
  return {
    id: profile.client_id,
    firstName: profile.first_name,
    lastName: profile.last_name,
    email: profile.email,
    avatarUrl: profile.avatar_url,
    callMe: profile.call_me,
    callAboutChangeProduct: profile.call_about_change_product,
    callAboutDeleteProduct: profile.call_about_delete_product,
    callAboutChangeProductWeight: profile.call_about_change_product_weight,
    callChangeProduct: profile.call_change_product,
    callDeleteProductByWeight: profile.call_delete_product_by_weight,
  }
}

export const convertAddressFromApi = (address: IAddressApi): IAddress => {
  return {
    id: address.id,
    city: address.city,
    street: address.street,
    building: address.building,
    apartment: address.apartment,
    intercom: address.door_phone,
    floor: address.floor,
    entrance: address.entrance,
    lat: address.lat,
    lon: address.lon,
  }
}

export const getAddressString = (address: IAddress, city?: string, noCode?: boolean): string => {
  const code = !noCode ? `${address.intercom ? `, ${intlMessage(messages.Code).toLowerCase()} ${address.intercom}` : ''}` : ''

  return (
    `${address.street ? `${address.street}, ` : ''}` +
    `${address.building}` +
    `${address.apartment ? `, ${intlMessage(messages.Flat).toLowerCase()} ${address.apartment}` : ''}` +
    `${address.entrance ? `, ${intlMessage(messages.Entrance).toLowerCase()} ${address.entrance}` : ''}` +
    `${address.floor ? `, ${intlMessage(messages.Floor).toLowerCase()} ${address.floor}` : ''}` +
    code +
    `${city ? ` - ${city}` : ''}`
  )
}

export const join = (props: Array<string | null>, separator = ' ') =>
  props
    .filter((i) => !!i)
    .map((str, i, arr) => (i === arr.length - 1 ? str + ' ' : str + separator))
    .join('')

export const getStreet = (item: IAddressSuggestion) => {
  const { street_with_type, house, block, block_type, settlement_with_type, city_with_type } = item.data
  const houseBlock = join([block_type, block])
  const first = street_with_type || city_with_type || settlement_with_type

  return join([first, house, houseBlock], ', ')
}

export const getFullAddress = (item: IAddressSuggestion) => {
  const { street_with_type, house_type, house, block, block_type, city_with_type, settlement_with_type } = item.data
  const houseBlock = join([block_type, block])
  const houseWithType = join([house_type, house])

  return join([city_with_type, settlement_with_type, street_with_type, houseWithType, houseBlock], ', ')
}

export const getAddressByString = (addresses: IAddress[], addressString?: string) => {
  if (!addressString) {
    return
  }

  return addresses.find((address) => getAddressString(address, address.city) === addressString)
}

export const compareAddresses = (address1: IAddress, address2: IAddress, byId?: boolean) => {
  const { id: id1, ...rest1 } = address1
  const { id: id2, ...rest2 } = address2
  const checkAddressString = getAddressString(rest1, rest1.city) === getAddressString(rest2, rest2.city)

  if (byId) {
    return id1 === id2 || checkAddressString
  }

  return checkAddressString
}

export const gMapToAddressSuggestion = ({
  description,
  place_id,
}: IGMapsSuggestion): IAddressSuggestion => {
  return {
    value: description,
    unrestricted_value: description,
    id: place_id,
    data: {
      city_with_type: '',
      street_with_type: '',
      house: '',
      city: '',
      street: '',
      sublocality: '',
      city_type: '',
      street_type: '',
      block: '',
      block_type: '',
      house_type: '',
      settlement_with_type: '',
      region_with_type: '',
      geo_lat: '',
      geo_lon: '',
      full_city_name: '',
    },
  }
}

export const gMapGeocodeToAddressSuggestion = (data: IGMapGeocode): IAddressSuggestion => {
  const region = data.address_components.find((item) => item.types.find((val) => val === EGMapGeocodeType.AREA_LEVEL_1))
  const city = data.address_components.find((item) => item.types.find((val) => val === EGMapGeocodeType.AREA_LEVEL_2))
  const street = data.address_components.find((item) => item.types.find((val) => val === EGMapGeocodeType.ROUTE))
  const building = data.address_components.find((item) => item.types.find((val) => val === EGMapGeocodeType.STREET_NUMBER))
  const sub = data.address_components.find((item) => item.types.find((val) => val === EGMapGeocodeType.SUB_LOCALITY))

  const buildingStr = building ? building.long_name : ''
  const streetStr = street ? street.long_name : ''
  const subStr = sub ? sub.long_name : ''
  const cityStr = city ? city.long_name : (region ? region.long_name : '')
  const regionStr = region ? region.long_name : ''

  return {
    value: data.formatted_address,
    unrestricted_value: data.formatted_address,
    id: data.place_id,
    data: {
      city_with_type: cityStr,
      street_with_type: streetStr,
      house: buildingStr,
      city: cityStr,
      street: streetStr,
      sublocality: subStr,
      city_type: '',
      street_type: '',
      block: '',
      block_type: '',
      house_type: '',
      settlement_with_type: '',
      region_with_type: regionStr,
      geo_lat: data.geometry.location.lat.toString(),
      geo_lon: data.geometry.location.lng.toString(),
      full_city_name: cityStr,
    },
  }
}

export const checkValidAddresses = (addresses: IAddressSuggestion[]) =>
  addresses.length === 1 && !addresses[0].data.house ? [] : addresses

export const formatAddressItemTitle = (item: IAddressSuggestion, noHouse?: boolean) => {
  const house = noHouse
    ? join([item.data.house_type, item.data.house, item.data.block_type, item.data.block]).trim()
    : undefined
  const city = item.data.full_city_name || item.data.city_with_type

  const address = uniq([
    item.data.region_with_type,
    city,
    item.data.settlement_with_type,
    item.data.street_with_type + `${item.data.sublocality ? ` - ${item.data.sublocality}`: ''}`,
    house,
  ])

  return address.filter((el) => !!el).join(', ')
}

export const formatCity = (item: IAddressSuggestion): string => {
  const { city_with_type, settlement_with_type, full_city_name } = item.data
  const citySpace = (city_with_type || full_city_name) && settlement_with_type ? ', ' : ''
  const city = full_city_name || city_with_type

  return `${city ? `${city}` : ''}` + citySpace + `${settlement_with_type ? `${settlement_with_type}` : ''}`
}

export const checkTest = (name: string) => !~name.toLowerCase().indexOf('тестовый')
